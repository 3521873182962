import { Parallax, ParallaxLayer } from "@react-spring/parallax"
import { useRef } from "react"

const RGPD = () => {
    const parallax = useRef()

    return (
        <div style={{overflowY: 'scroll', height: '80%'}}>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>1. Objectifs</h2>
                <span>
                    Itancia et l’ensemble de ses filiales s’engagent à respecter la réglementation en vigueur applicable au traitement de données à caractère personnel et, en particulier, le Règlement (UE) 2016/679 du Parlement Européen et du Conseil du 27 avril 2016 applicable à compter du 25 mai 2018 (aussi appelé « le règlement général sur la protection des données, RGPD »). 
                </span>
                <span>
                    Ce Règlement instaure un nouveau cadre juridique imposant aux entreprises la mise en œuvre de mesures afin de s’assurer que le traitement de données personnelles est effectué conformément aux dispositions du RGPD.             </span>
                <span>
                    Ainsi, dans le cadre des relations avec nos partenaires (clients, fournisseurs, prestataires de services et sous-traitants), au regard des données que nous collectons et traitons, notre rôle s’analyse en tant que : 
                </span>
                <span>
                    « Responsable de traitement » dans le cadre du traitement des données personnelles des contacts aux fins de gestion de la relation avec nos partenaires
                </span>
                <span>
                    « Sous-traitant » dans le cadre du traitement des données personnelles pour le compte du responsable de traitement pour fournir des services nécessaires à la réalisation du contrat (hébergement, expédition) 
                </span>
                <span>
                    Itancia traite des données personnelles de manière loyale, transparente, conformément aux fins pour lesquelles elles ont été collectées, et en toute sécurité. 
                </span>
                <span>
                    La présente politique fixe la manière dont nous traitons les informations que nous recueillons au sujet de nos partenaires. 
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>2. Collecte des données personnelles</h2>
                <span>
                    Itancia recueille des informations qui incluent : 
                </span>
                <span>
                    Les données d’identification : civilité, nom, prénom, adresse de messagerie, adresse postale professionnelle, numéro de téléphone, fonction exercée, copie de carte d’identité, coordonnées du contact référent, contacts et préférences marketing
                </span>
                <span>
                    Les données d’ordre économique et financier : RIB, numéro de carte bancaire, numéro de chèque, virement bancaire, information de carte de crédit / débit, factures, remises, solde et impayés
                </span>
                <span>
                    Les données relatives à la transaction : numéro, détail de l’achat ou de la vente, du bien ou du service souscrit ou vendu
                </span>
                <span>
                Les données relatives au suivi de la relation commerciale : correspondance avec le client, prospect ou fournisseur
                </span>
                <span>
                Les données relatives à Internet : Cookies, adresse IP, identification de connexion
                </span>
                <span>
                Les données d’utilisation des services : données d’activités, informations sur les préférences de produit/service, historique des achats, pages Web visitées
                </span>
                <span>
                Les données liées aux appareils utilisées : état opérationnel du périphérique, configuration, données d’utilisation et de suivi de performance, information des réseaux utilisés pour se connecter ou utiliser les produits/services (systèmes d’exploitation, applications, logiciels, clé de produit, authentification réseau, paramètres régionaux et linguistiques)
                </span>
                <span>
                Aucune donnée sensible au titre du RGPD (qui incluent l’origine raciale ou ethnique, les opinions politiques, religieuses, philosophiques, ou similaires, l’appartenance à un syndicat, la santé physique ou mentale, les données biométriques génétiques, l’identité ou l’orientation sexuelle et les antécédents judiciaires) ne sera collecté par Itancia. 
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>3. La gestion des Cookies et traceurs</h2>
                <h3>
                    Qu’est-ce que c’est ?
                </h3>
                <span>
                    Les cookies sont de petits fichiers texte qui sont automatiquement déposés sur votre ordinateur lorsque vous naviguez sur le web. Ces fichiers stockent des informations relatives à l’utilisateur telle que l’activité et les préférences et dont l’objectif est de permettre au serveur du site Internet d’identifier facilement l’utilisateur d’un site à un autre.
                </span>
                <h3>
                    Quelles données sont collectées ?
                </h3>
                <span>
                    Via l’utilisation de cookies, les sites Internet d’Itancia et de ses filiales collectent des données personnelles lors des visites sur les sites : adresse IP, données de navigation, durées de transfert des données, préférences des utilisateurs, mots de passe, fichiers journaux, balises Web.            
                </span>
                <h3>
                    Où sont-ils stockés ?
                </h3>
                <span>
                    Les cookies sont stockés sur l’appareil de l’utilisateur.             
                </span>
                <h3>
                    Pourquoi sont-ils utilisés ?
                </h3>
                <span>
                    909 utilise des cookies et autres technologies pour :            
                </span>
                <span>
                    Stocker les informations personnelles du profil de l’utilisateur             
                </span>
                <span>
                    Faciliter le téléchargement de documents à partir de sites             
                </span>
                <span>
                    Nécessaire à la fourniture des produits/services            
                </span>
                <span>
                    Configurer le navigateur de l’utilisateur pour bloquer, rejeter, supprimer ou désactiver les cookies et autres technologies de suivi            
                </span>
                <span>
                    Identifier les problèmes et l’utilisation des sites Internet            
                </span>
                <span>
                    Effectuer une analyse de trafic standard sur les sites            
                </span>
                <span>
                    Suivre l’utilisation du site à des fins statistiques de fréquentation anonyme            
                </span>
                <span>
                    Suivre l’historique des visites de l’utilisateur             
                </span>
                <span>
                    À des fins commerciales           
                </span>
                <span>
                    Toutefois, si l’utilisateur rejette ou supprime des cookies ou toute autre technologie de suivi, il ne pourra peut-être pas accéder à certaines pages ou utiliser des fonctions importantes, y compris le téléchargement de documents et l’établissement d’un profil utilisateur.           
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>4. Utilisation des données personnelles</h2>
                <span>
                Toutes les informations recueillies auprès du client / fournisseur pourront être utilisées à des fins de gestion des : contrats, commandes, factures, livraison, expédition, services, SAV, réclamations, enquêtes, statistiques, newsletter, gestion des litiges, demandes de droits d’accès, rectification, opposition, authentification et accès au compte.                
                </span>
                <span>
                Les données personnelles des clients pourront être utilisées à des fins de prospection commerciales pour des produits et services analogues.                
                </span>
                <span>
                Les données personnelles des prospects pourront être utilisées pour l’envoi de courrier électronique, à des fins de réalisation d’opérations de sollicitations en rapport avec la profession de la personne démarchée                
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>5. Bases légales de la collecte et du traitement des données personnelles</h2>
                <span>
                La collecte et le traitement de ces données personnelles sont justifiées par : 
                </span>
                <span>
                La conclusion et l’exécution du contrat conclu avec Itancia, ainsi que la gestion de la relation avec nos partenaires
                </span>
                <span>
                Le respect de nos obligations légales, notamment en terme de facturation
                </span>
                <span>
                L’obtention préalable du consentement si nécessaire 
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>6. Destinataires des données personnelles</h2>
                <span>
                Les données collectées seront transmises aux différents services pour leurs besoins respectifs : production, expédition, services comptabilité, commercial, SAV, QSE, informatique. 
                </span>
                <span>
                Par ailleurs, Itancia peut être amenée à transmettre des données personnelles à ses partenaires et/ou sous-traitants, notamment dans le cadre des missions suivantes :
                </span>
                <span>
                Le traitement et la livraison des commandes
                </span>
                <span>
                Les réparations externes 
                </span>
                <span>
                La réparation ou le SAV auprès des constructeurs 
                </span>
                <span>
                Les services de maintenance des locaux 
                </span>
                <span>
                    Au personnel habilité des services chargés du contrôle (commissaire aux comptes, services chargés des procédures internes du contrôle, officiers ministériels) 
                    Sous réserve d’un accord préalable de la personne concernée, Itancia peut être amenée à transmettre des données personnelles à ses partenaires commerciaux.
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>7. Transfert des données hors UE</h2>
                <span>
                Aucune donnée ne sera transférée en dehors de la zone UE.
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>8. Durées de conservation</h2>
                <span>
                Itancia ne conserve les données personnelles que pendant une durée strictement nécessaire et proportionnelle à la finalité pour laquelle elles ont été collectées :
                </span>
                <span>
                Aussi longtemps qu’elle entretient une relation continue son partenaire
                </span>
                <span>
                Aussi longtemps que cela sera nécessaire pour fournir ses services (ex. garanties) 
                </span>
                <span>
                Aussi longtemps que cela sera nécessaire pour se conformer aux obligations légales et contractuelles (ex. Contrat commercial : 5ans / Factures et bons de commande : 10ans) 
                </span>
                <span>
                Le consentement des Cookies est valable 13 mois. 
                </span>
                <span>
                Ces données peuvent être conservées à des fins de prospection commerciale au maximum pendant 3 ans à compter de la fin de cette relation commerciale. 
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>9. Confidentialité des données</h2>
                <span>
                Itancia est le seul propriétaire des informations recueillies. Les informations personnelles ne seront pas vendues, échangées, transférées, ou données à une autre société, hormis les éventuelles tierces parties de confiance avec qui nous collaborons dans le cadre de la réalisation de nos missions, à condition que ces parties conviennent de garder ces informations confidentielles.
                </span>
                <span>
                Les renseignements personnels collectés sont conservés dans un environnement sécurisé. Les personnes travaillant pour Itancia sont tenues de respecter la confidentialité des informations.
                </span>
                <span>
                Itancia s’engage à maintenir un haut degré de confidentialité concernant toutes les données personnelles.
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>10. Mesures de sécurité</h2>
                <span>
                Itancia, en tant que responsable du traitement de vos données personnelles, s’engage à prendre toutes les mesures nécessaires pour assurer la sécurité et la confidentialité des données personnelles transmises, et ce dans le respect des dispositions légales en vigueur
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>11. Les droits RGPD</h2>
                <span>
                Conformément au Règlement UE N°2016/679 du 27 avril 2016, les partenaires bénéficient des droits suivants :
                </span>
                <span>
                Droit d’accès : toute personne a la possibilité de demander à un organisme s’il détient des données la concernant et à ce qu’on lui en communique une copie pour en vérifier le contenu
                </span>
                <span>
                Droit de rectification : toute personne a la possibilité de corriger des données inexactes la concernant ou de compléter des données en lien avec la finalité du traitement mis en œuvre
                </span>
                <span>
                Droit à l’effacement (« droit à l’oubli ») : toute personne a le droit de demander à un organisme l’effacement de données à caractère personnel la concernant
                </span>
                <span>
                Droit à la limitation du traitement : dans certains cas, une personne a le droit de demander à un organisme de geler temporairement l’utilisation de certaines de ses données
                </span>
                <span>
                Droit à la portabilité des données : ce droit confère aux personnes concernées le droit de recevoir les données à caractère personnel qu’elles ont fournies dans un format structuré, couramment utilisé et lisible par machine, et de les transmettre à un autre responsable de traitement. 
                </span>
                <span>
                Droit d’opposition : Toute personne a le droit de s’opposer à tout moment pour des raisons tenant à sa situation particulière à ce qu’un organisme utilise certaines de ses données.
                </span>
                <span>
                    Pour exercer ce(s) droit(s), il suffit de contacter ITANCIA par courrier ou voie électronique : 
                </span>
                <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', marginTop: '15px', marginBottom: '15px'}}>
                    <span>
                    909 
                    </span>
                    <span>
                    Département Qualité – DPO
                    </span>
                    <span>
                    Les Landes. 49510 La Jubaudière. France
                    </span>
                    <span>
                    Email : <a href="mailto:dpo@909services.com">dpo@909services.com</a>
                    </span>
                </div>
                <span>
                La demande doit être accompagnée d’un justificatif d’identité en cours de validité : 
                </span>
                <span>
                Itancia s’engage à adresser une réponse dans un délai maximum d’UN MOIS à compter de la réception de la demande. À défaut, il est possible de saisir la CNIL : 
                </span>
                <span>
                    <a href="https://www.cnil.fr ">https://www.cnil.fr </a>
                </span>
            </div>
        </div>
    )
}

export default RGPD