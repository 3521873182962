import { useState } from "react"
import { useLocation } from "react-router-dom";
import FranceSimpleMap from "../components/FranceSimpleMap";
import AgencyModal from "../components/AgencyModal";
import { useEffect } from "react";

const Agencies = () => {
    const [agencyForModal, setAgencyForModal] = useState(false)
    const [agencyModalVisible, setAgencyModalVisible] = useState(false)
    const location = useLocation();

    useEffect(()=>{
        if(location.state){
            // console.log(location.state.agency)
            setAgencyForModal(location.state.agency)
            setAgencyModalVisible(true)
        }
    }, [])

    window.onbeforeunload = function() {
        localStorage.clear();
    }

    return (
        <div className="agencyMap">
            <FranceSimpleMap
                    setAgencyModalVisible={setAgencyModalVisible}
                    setAgencyForModal={setAgencyForModal}
                    isInAgencies={true}
                />
            <AgencyModal
                agencyModalVisible={agencyModalVisible}
                setAgencyModalVisible={setAgencyModalVisible}
                agencyForModal={agencyForModal}
            />
        </div>
    )
}

export default Agencies