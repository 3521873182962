import { Parallax, ParallaxLayer } from "@react-spring/parallax"
import { useInView } from 'react-intersection-observer';
import React, { useEffect, useRef } from "react"
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { store } from "../store"
import 'pure-react-carousel/dist/react-carousel.es.css';
import '../css/home.css';

import Background01 from '../assets/images/salon_whited.jpeg'
import LogoWhite from '../assets/images/Logo909-blanc.png'
import homeSavoir from '../assets/images/home_savoir.svg'
import homeConfiance from '../assets/images/home_confiance.svg'
import homeSimplicity from '../assets/images/home_simplicity.svg'
import homeInter from '../assets/images/reparation-a-domicile.svg'
import homeRemote from '../assets/images/diagnostic-a-distance.svg'
import homeWorkshop from '../assets/images/reparation-en-atelier.svg'
import { Link } from "react-router-dom"
import 'react-tooltip/dist/react-tooltip.css'
import FranceSimpleMap from "../components/FranceSimpleMap"
import InfoModal from "../components/InfoModal";
import RgpdModal from "../components/RgpdModal";
import { useState } from "react";
import { useSelector } from "react-redux";

const Home = () => {
    const { agencies } = useSelector((store) => store.agencies);
    const [savedLivesCounter, setSavedLivesCounter] = useState(0)
    // const [targetAgency, setTargetAgency] = useState('')
    var [currentSlide, setCurrentSlide] = useState(0)

    const parallax = useRef()
    const franceMap = useRef()
    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 0,
      })

    useEffect(() => {
        // if(inView){
        //     if(savedLivesCounter < 700000){
        //         setSavedLivesCounter(savedLivesCounter+10000)
        //     }
        // }else{
        //     setSavedLivesCounter(0)
        // }
        const interval = setInterval(() => {
            if(currentSlide < 4){
                setCurrentSlide(currentSlide++)
            }else{
                setCurrentSlide(0)
            }
          }, 5000);
        
          return () => clearInterval(interval);
    }) 

    return (
        <div className="home">
            {/* A remplacer par button simple (!loginModal)*/}
            {/* <LoginModal title={"Intervenir sur mon appareil"}></LoginModal> */}
            <InfoModal/>
            <RgpdModal/>
            <Parallax 
                pages={11} 
                ref={parallax}
                >
                <ParallaxLayer 
                    offset={0} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(1)}
                    style={{
                        backgroundImage:`url(${Background01})`,
                        backgroundSize: 'cover',
                        cursor: 'pointer'
                        }}>
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', height:'100%', alignItems: 'center', marginTop: '35%'}}>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                            <div className="page1TitleContainer">
                                <div style={{rotate: '-12deg', fontWeight: 'bold', fontSize: '3.4em'}}>
                                    <span style={{color: '#3f2564', fontStyle: 'italic'}}>Faites du </span><span style={{textIndent: '8px', color: '#7dcb97', fontStyle: 'italic'}}> neuf</span>
                                </div>
                                <div style={{rotate: '-12deg', fontWeight: 'bold', fontSize: '3.4em'}}>
                                    <span style={{color: '#7dcb97', fontStyle: 'italic'}}>sans neuf </span><span style={{textIndent: '8px', color: '#3f2564', fontStyle: 'italic'}}>, réparez !</span>
                                </div>
                            </div>
                            <div className="page1TitleContainer">
                                <div style={{rotate: '-12deg', fontWeight: 'bold', fontSize: '1.8em'}}>
                                    <span style={{color: '#3f2564', fontStyle: 'italic'}}>Adoptez le réflexe 909</span>
                                </div>
                                <div style={{rotate: '-12deg', fontWeight: 'bold', fontSize: '1.8em'}}>
                                    <span style={{color: '#3f2564', fontStyle: 'italic'}}>et retrouvez votre appareil fonctionnel</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer 
                    offset={1} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(2)}
                    style={{
                        backgroundColor: '#3f2564',
                        cursor: 'pointer'
                    }}
                    >
                    <div className="page2Container">
                        <div className="page2TitleContainer">
                            <span style={{fontWeight: 'bold', fontSize: '2.1em', color: '#fff', marginRight: '16px'}}>La réparation par</span><img style={{height: '75px'}} src={LogoWhite} alt="logo909white"/>
                        </div>
                        <div className="page2Text" style={{height: "auto"}}>
                            909 est votre spécialiste sav du dépannage et de la réparation pour vos appareils
                            électroniques et électroménagers, sous-garantie ou hors-garantie. La gamme des produits
                            concernés est celle de vos appareils utilisés au quotidien à la maison comme : télévision,
                            écrans tv, réfrigérateur, congélateur, lave-vaisselle, cave à vin, four, micro-ondes,
                            plaques de cuisson, plaque induction, plaque vitrocéramique, lave-linge, sèche-linge.<br/>
                            <span> Bénéficiez de notre service d'intervention à domicile, en atelier ou de diagnostic à
                            distance et profitez du bonus réparation pour faire réparer votre produit.
                                <Link to="/bonus" className="repairLink" style={{padding: "0 10px", marginLeft: "10px"}}
                                >En savoir plus
                                </Link>
                            </span>
                            <span>Nos stations techniques sont situées à Morangis 91, Chaponnay 69, Toulouse 31, Bihorel 76,
                            Chambray les Tours 37, Mundolsheim 67. Elles couvrent les agglomérations et les régions de
                            Paris, Lyon, Toulouse, Rouen, Tours et Strasbourg.
                                <Link to="/" className="repairLink" style={{padding: "0 10px", marginLeft: "10px"}}
                                      onClick={(e) => {
                                          e.stopPropagation()
                                          parallax.current.scrollTo(2)
                                      }}
                                >En savoir plus
                                </Link>
                            </span>
                        </div>
                        <div className="page2InfosContainer">
                            <div className="infoColumn">
                                <img style={{height: '75px'}} src={homeSavoir} alt="homeSavoir"/>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <span style={{fontWeight: 'bold'}}>Savoir-faire</span>
                                    <span style={{fontSize: '0.7em'}}>
                                        30 ans d’expérience dans l’intervention à domicile ou en atelier et dans le reconditionnement de produits
                                    </span>
                                </div>
                            </div>
                            <div className="infoColumn">
                                <img style={{height: '75px'}} src={homeConfiance} alt="homeConfiance"/>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <span style={{fontWeight: 'bold'}}>Confiance</span>
                                    <span style={{fontSize: '0.7em'}}>
                                        Nous travaillons au nom de Samsung, Hisense, Panasonic pour réparer vos appareils : TV, lave-linge, four…
                                    </span>
                                </div>
                            </div>
                            <div className="infoColumn">
                                <img style={{height: '75px'}} src={homeSimplicity} alt="homeSimplicity"/>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <span style={{fontWeight: 'bold'}}>Simplicité</span>
                                    <span style={{fontSize: '0.7em'}}>
                                        Ne vous posez pas de questions, quelque soit votre appareil nous le réparons chez vous ou à distance
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer 
                    ref={franceMap}
                    offset={2} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(3)}
                    style={{
                        backgroundColor: '#fff',
                        cursor: 'pointer'
                    }}>
                    <div style={{display: 'flex', flexDirection:'column', width: '100%', height: '100%', alignItems: 'center'}}>
                        <div className="page3TitleContainer">
                            <span style={{color: '#3f2564'}}>Notre réseau national </span><span style={{textIndent: '8px', color: '#7dcb97'}}> d'agences</span>
                        </div>
                        <FranceSimpleMap/>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer 
                    offset={3} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(4)}
                    style={{
                        backgroundColor: '#3f2564',
                        backgroundSize: 'cover',
                        cursor: 'pointer'
                        }}>
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', height:'100%', alignItems: 'center'}}>
                        <div className="page4TitleContainer">
                            <span style={{color: '#fff'}}>Des offres </span><span style={{textIndent: '8px', color: '#7dcb97'}}> pour tous</span>
                        </div>
                        <div className="page4InfosContainer">
                            <div className="infoColumnWhiteBG">
                                <div className="infosColumnWhiteBGTitle">
                                    <img style={{height: '75px'}} src={homeInter} alt="homeSavoir"/>
                                    <div className="infosColumnWhiteBGTitletext">
                                        <span style={{fontWeight: 'bold'}}>Intervention à domicile</span>
                                    </div>
                                </div>
                                <span style={{fontSize: '0.7em'}}>
                                    Par appareil & hors pièces détachées
                                </span>
                                <ul style={{fontSize: '0.6em', marginLeft: '0px', paddingLeft: '20px'}}>
                                    <li>Déplacement et intervention à domicile* d’un expert 909</li>
                                    <li>Prestation garantie 3 mois</li>
                                </ul>
                                <span style={{fontSize: '0.5em', marginTop: '5px'}}>*Dans la limite des zones d’intervention proposées par 909 pour des prestations ateliers ou à domicile. Pour plus de détails, cliquez 
                                    <Link 
                                    style={{paddingLeft: '10px'}}
                                        onClick={(e) => {
                                        e.stopPropagation()
                                        parallax.current.scrollTo(2)}}
                                    >ici</Link>
                                </span>
                            </div>
                            <div className="infoColumnWhiteBG">
                            <div className="infosColumnWhiteBGTitle">
                                <img style={{height: '75px'}} src={homeRemote} alt="homeConfiance"/>
                                    <div className="infosColumnWhiteBGTitletext">
                                        <span style={{fontWeight: 'bold'}}>Expertise à distance</span>
                                        <span style={{fontWeight: 'bold',  color: '#7dcb97'}}>35 €</span>
                                    </div>
                                </div>
                                <span style={{fontSize: '0.7em'}}>
                                    Par appareil & hors pièces détachées
                                </span>
                                <ul style={{fontSize: '0.6em', marginLeft: '0px', paddingLeft: '20px'}}>
                                    <li>Appel avec un expert 909</li>
                                    <li>Si possible, on vous solutionne immédiatement !</li>
                                    <li>Sinon, 909 vous établi un devis pour une réparation*</li>
                                </ul>
                                <span style={{fontSize: '0.5em', marginTop: '5px'}}>*Dans la limite des zones d’intervention proposées par 909 pour des prestations ateliers ou à domicile. Pour plus de détails, cliquez 
                                    <Link 
                                    style={{paddingLeft: '10px'}}
                                        onClick={(e) => {
                                        e.stopPropagation()
                                        parallax.current.scrollTo(2)}}
                                    >ici</Link>
                                </span>
                            </div>
                            <div className="infoColumnWhiteBG">
                                <div className="infosColumnWhiteBGTitle">
                                    <img style={{height: '75px'}} src={homeWorkshop} alt="homeSimplicity"/>
                                    <div className="infosColumnWhiteBGTitletext">
                                        <span style={{fontWeight: 'bold'}}>Réparation en atelier</span>
                                        <span style={{fontWeight: 'bold', color: '#7dcb97'}}>sur devis</span>
                                    </div>
                                </div>
                                <span style={{fontSize: '0.7em'}}>
                                    Par appareil & hors pièces détachées
                                </span>
                                <ul style={{fontSize: '0.6em', marginLeft: '0px', paddingLeft: '20px'}}>
                                    <li>Diagnostic et réparation en atelier</li>
                                    <li>Dépôt par vos soins dans nos ateliers</li>
                                    <li>Réparation garantie 3 mois</li>
                                </ul>
                                <span style={{fontSize: '0.5em', marginTop: '5px'}}>*Dans la limite des zones d’intervention proposées par 909 pour des prestations ateliers ou à domicile. Pour plus de détails, cliquez 
                                    <Link 
                                    style={{paddingLeft: '10px'}}
                                        onClick={(e) => {
                                        e.stopPropagation()
                                        parallax.current.scrollTo(2)}}
                                    >ici</Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </ParallaxLayer>
                {/* <ParallaxLayer 
                    offset={4} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(5)}
                    style={{
                        backgroundColor: '#3f2564',
                        cursor: 'pointer'
                    }}
                    >
                    <div style={{display: 'flex', flexDirection: 'column', width: '80%', marginLeft: '10%', height:'90%', alignItems: 'center'}}>
                        <div style={{height: '25%', width: '80%', marginLeft: '10%'}}>
                            <span style={{fontWeight: 'bold', fontSize: '2.1em', color: '#fff', marginRight: '6px'}}>Vous souhaitez tenter</span><span style={{fontWeight: 'bold', fontSize: '2.1em', color: '#7dcb97'}}> l'aventure en solo ?</span>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <img className="doItMySelfLogo" src={require('../../src/assets/images/picto-je-le-fais-moi-meme.svg').default} alt=""/>
                            <div className="doItMySelfText">
                                <span style={{color: '#fff', marginRight: '6px'}}>Contactez-nous et faites votre demande de</span>
                                <span style={{color: '#7dcb97', marginRight: '6px'}}> pièces détachées</span>     
                                <span style={{color: '#fff'}}>via notre formulaire</span>                     
                            </div>
                        </div>
                        <Link 
                            to="/contact"
                            className="contactLink"
                            style={{paddingLeft: '10px'}}
                        >Contactez-nous</Link>
                    </div>
                </ParallaxLayer> */}
                <ParallaxLayer 
                    offset={4} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(5)}
                    style={{
                        backgroundColor: '#fff',
                        cursor: 'pointer'
                    }}
                    >
                    <div style={{display: 'flex', flexDirection: 'column', width: '80%', marginLeft: '10%', height:'90%', alignItems: 'center'}}>
                        <div style={{height: '10%', width: '95%', marginLeft: '20%'}}>
                            <span className="page5Title">Comment faire du</span><span className="page5Title" style={{color: '#7dcb97'}}> neuf sans neuf ?</span>
                        </div>
                        <div style={{
                            display: 'flex', 
                            flexDirection: 'row',
                            width: '80%',
                            marginLeft: '10%'
                            }}>                            
                            <img className="speedLogo" style={{marginLeft: 28, position: 'absolute', zIndex: 2}} src={require('../../src/assets/images/home_rapide.svg').default} alt=""/>
                            <img className="speedLogo" style={{marginTop: 25}} src={require('../../src/assets/images/cartouche-vertdeau-909.svg').default} alt=""/>
                            <div className="doItMySelfText" style={{flexDirection: 'column', width: '70%'}}>
                                <div className="page5MiniTitle">Rapide et facile !</div>  
                                <div className="page5MiniText">Prenez simplement rendez-vous en quelques clics</div>                     
                            </div>

                        </div>
                        <div style={{
                            display: 'flex', 
                            flexDirection: 'row',
                            // marginTop: '2%',
                            width: '80%',
                            marginLeft: '10%'
                            }}>
                            <div className="doItMySelfText" style={{flexDirection: 'column', width: '70%', marginLeft: '5%', marginTop: '3%'}}>
                                <div className="page5MiniTitle">Chez vous ou à distance</div>  
                                <div className="page5MiniText">Selon le type d’appareil, nous réparons vos appareils à domicile ou à distance. Aussi, un appel peut suffire à solutionner votre panne !</div>                     
                            </div>                            
                            <img className="speedLogo" style={{marginLeft: '50%', position: 'absolute', zIndex: 2}} src={require('../../src/assets/images/home_distance.svg').default} alt=""/>
                            <img className="speedLogo" style={{marginLeft: '50%', position: 'absolute', zIndex: 1, marginTop: '5%'}} src={require('../../src/assets/images/cartouche-vertdeau-909.svg').default} alt=""/>
                        </div>
                        <div style={{
                            display: 'flex', 
                            flexDirection: 'row',
                            marginTop: '3%',
                            width: '80%',
                            marginLeft: '10%'
                            }}>                            
                            <img className="speedLogo" style={{marginLeft: 28, position: 'absolute', zIndex: 2}} src={require('../../src/assets/images/home_reparation.svg').default} alt=""/>
                            <img className="speedLogo" style={{marginTop: 25}} src={require('../../src/assets/images/cartouche-vertdeau-909.svg').default} alt=""/>
                            <div className="doItMySelfText" style={{flexDirection: 'column', width: '70%', marginTop: '3%'}}>
                                <div className="page5MiniTitle">Une réparation à la carte !</div>  
                                <div className="page5MiniText">La réparation et vous ça fait deux ? Notre expert intervient directement à votre domicile pour réparer votre produit.</div>                     
                            </div>
                        </div>
                        <div style={{
                            display: 'flex', 
                            flexDirection: 'row',
                            marginTop: '2%',
                            width: '80%',
                            marginLeft: '10%'
                            }}>
                            <div className="doItMySelfText" style={{flexDirection: 'column', width: '70%', marginLeft: '10%', marginTop: '5%'}}>
                                <div className="page5MiniTitle">Un produit réparé = une vie sauvée</div>  
                                <div className="page5MiniText">En effet, nous vous accompagnons jusqu’à ce que vous retrouviez un appareil fonctionnel.</div>                     
                            </div>                            
                            <img className="speedLogo" style={{marginLeft: '50%', position: 'absolute', zIndex: 2}} src={require('../../src/assets/images/home_pdt-adopt.svg').default} alt=""/>
                            <img className="speedLogo" style={{marginLeft: '50%', position: 'absolute', zIndex: 1, marginTop: '5%'}} src={require('../../src/assets/images/cartouche-vertdeau-909.svg').default} alt=""/>
                        </div>
                    </div>
                </ParallaxLayer>
                {/* <ParallaxLayer 
                    offset={6} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(7)}
                    style={{
                        backgroundColor: '#3f2564',
                        cursor: 'pointer'
                    }}
                    >
                    <div  ref={ref} className="page6">
                        <div className="page6Title">
                            <span style={{fontWeight: 'bold', fontSize: '2.1em', color: '#fff', marginRight: '6px'}}>Le réflexe qui sauve la vie des produits</span>
                        </div>
                        <div className="page6Container">
                            <div className="page6SubTitle">
                                <img className="doItMySelfLogo" src={require('../../src/assets/images/picto-viessauvees.png')} alt=""/>
                                <span style={{fontWeight: 'bold', fontSize: '2.4em', color: '#7dcb97'}}>700 000</span>
                                <span style={{fontWeight: 'bold', fontSize: '1.4em', color: '#7dcb97'}}>vies sauvées</span>
                            </div>
                            <div className="page6SubText">
                                <div style={{color: '#fff', marginRight: '6px', fontWeight: 'bold', fontSize: '1.6em'}}>+ de 700 000 produits ont déjà retrouvé une seconde vie avec 909 !</div>    
                                <div style={{color: '#fff', fontSize: '0.9em'}}>En réparant vos appareils électroniques et électroménagers, vous contribuez à sauver notre planète ! Un appareil sauvé, un de moins jeté ! Chaque geste compte.</div>                     
                            </div>
                        </div>
                    </div>
                </ParallaxLayer> */}
                <ParallaxLayer 
                    offset={5} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(6)}
                    style={{
                        backgroundColor: '#fff',
                        cursor: 'pointer'
                    }}
                    >
                    <div className="page6">
                        <div className="page7Title" style={{width: '90%'}}>
                            <span style={{fontWeight: 'bold', fontSize: '2.1em', color: '#3f2564', marginRight: '6px'}}>Vous avez forcément vécu</span>
                            <span style={{fontWeight: 'bold', fontSize: '2.1em', color: '#7dcb97', marginRight: '6px'}}>ces situations...</span>
                        </div>
                        <CarouselProvider
                            naturalSlideWidth={100}
                            naturalSlideHeight={125}
                            totalSlides={4}
                            currentSlide={currentSlide}
                            className="carouselProvider"
                        >
                            <Slider style={{height: '100%'}}>
                                <Slide index={0}>
                                    <div className="slideContainer">
                                        <div style={{width: '50%'}}>
                                            <img style={{width: '100%'}} src={require('../assets/images/fille-canape.jpeg')} alt=""/>
                                        </div>
                                        <div className="slideSubText">
                                            Votre TV ne s’allume plus. Comment allez-vous faire pour décompresser le soir en rentrant du boulot ? 
                                            En adoptant le réflexe 909, continuez de regarder votre série préférée sur Netflix.
                                        </div>
                                    </div>
                                </Slide>
                                <Slide index={1}>
                                    <div className="slideContainer">
                                        <div style={{width: '50%'}}>
                                            <img style={{width: '100%'}} src={require('../assets/images/pere-four.jpeg')} alt=""/>
                                        </div>
                                        <div className="slideSubText">
                                            Votre four ne fonctionne plus. En télétravail, comment allez-vous faire pour chauffer vos petits plats rapidement ? 
                                            Adoptez le réflexe 909 et continuez de manger vos pizzas croustillantes entre midi et deux.
                                        </div>
                                    </div>
                                </Slide>
                                <Slide index={2}>
                                    <div className="slideContainer">
                                        <div style={{width: '50%'}}>
                                            <img style={{width: '100%'}} src={require('../assets/images/pere-lave-linge.jpeg')} alt=""/>
                                        </div>
                                        <div className="slideSubText">
                                            Votre machine à laver tombe en panne. Comment allez-vous faire avec un enfant en bas âge ? 
                                            Avec le réflexe 909, continuez de laver les vêtements de toute la famille et prolonge la vie de votre appareil !
                                        </div>
                                    </div>
                                </Slide>
                                <Slide index={3}>
                                    <div className="slideContainer">
                                        <div style={{width: '50%'}}>
                                            <img style={{width: '100%'}} src={require('../assets/images/fille-frigo.jpeg')} alt=""/>
                                        </div>
                                        <div className="slideSubText">
                                            Votre frigo vous lâche alors que vous venez de faire le plein de courses. 
                                            Avec le réflexe 909, plus besoin de  jeter, vos aliments resteront au frais.
                                        </div>
                                    </div>
                                </Slide>
                            </Slider>
                                <div style={{width: '100%', display: 'flex', justifyContent: 'space-around'}}>
                                    <ButtonBack 
                                        style={{border: 'none', backgroundColor: 'rgba(0,0,0,0)', fontSize: 28, color: '#7dcb97', fontWeight: 'bold'}}
                                        onClick={(e)=>{
                                            e.stopPropagation()
                                        }}      
                                    >{'<'}</ButtonBack>
                                    <ButtonNext 
                                        style={{border: 'none', backgroundColor: 'rgba(0,0,0,0)', fontSize: 28, color: '#7dcb97', fontWeight: 'bold'}}
                                        onClick={(e)=>{
                                            e.stopPropagation()
                                        }}  
                                    >{'>'}</ButtonNext>
                                </div>
                        </CarouselProvider>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer 
                    offset={6} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(7)}
                    style={{
                        backgroundColor: '#3f2564',
                        cursor: 'pointer'
                    }}
                    >
                    <div style={{display: 'flex', flexDirection: 'column', width: '80%', marginLeft: '10%', height:'90%', alignItems: 'center'}}>
                            <div className="partnerDiv1">
                                <span style={{fontWeight: 'bold', fontSize: '2.1em', color: '#fff', marginRight: '6px'}}>
                                    909, le service de réparation utilisé
                                    et agréé par les plus grandes marques
                                </span>
                            </div>
                            <div className="partnerDiv2">
                                    <span style={{color: '#fff', marginRight: '6px'}}>
                                        Nous travaillons au nom de Samsung, Panasonic ou encore Hisense. 
                                        Ils font appel à nous pour réparer à votre domicile vos appareils encore 
                                        sous garantie : TV, lave-linge, réfrigérateur, sèche-linge, micro-onde, 
                                        four, plaques de cuisson… Nos partenaires nous font confiance. Pourquoi pas vous ? 
                                    </span>                    
                                </div>
                            <div className="partnerDiv3">
                                <img style={{height: '65px'}} src={require('../assets/images/logo_samsung.png')} alt=""/>
                                <img style={{height: '65px'}} src={require('../assets/images/Vestel-logo-1.png')} alt=""/>
                                {/* <img style={{height: '48px'}} src={require('../assets/images/Logo_Hisense_France.png')} alt=""/> */}
                            </div>
                        </div>
                </ParallaxLayer>
                <ParallaxLayer 
                    offset={7} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(8)}
                    style={{
                        backgroundColor: 'white',
                        cursor: 'pointer'
                    }}
                    >
                    <div style={{display: 'flex', flexDirection: 'column', width: '80%', marginLeft: '10%', height:'100%', alignItems: 'center'}}>
                        <div className="page9Title">
                            <span className="page9TitlePart1">Notre équipe</span>
                            <span className="page9TitlePart2">d'experts</span>
                        </div>
                        <div className="page9Container">
                            <div style={{width: '30%', marginLeft: '3%', marginTop: '5%', display: 'flex', flexDirection: 'row'}}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <img style={{height: '220px', borderRadius: '110px'}} src={require('../assets/images/expert_2.jpg')} alt=""/>
                                    <span style={{color: '#3f2564', fontWeight: 'bold', fontSize: '1.1em', marginTop: '5%'}}>Damien</span>
                                    <span style={{color: '#7dcb97', fontWeight: 'bold', fontSize: '1.1em'}}>Expert en atelier</span>
                                    <span style={{color: '#3f2564', fontSize: '1em', marginTop: '5%'}}>Notre nouvelle recrue ! Un grand sportif. Sa devise : « ne jamais baisser les bras »</span>
                                </div>
                                <img style={{height: '65px'}} src={require('../assets/images/Vestel-logo-1.png')} alt=""/>
                                <img style={{height: '65px'}} src={require('../assets/images/Logo_Hisense_France.png')} alt=""/>
                            </div>
                            <div style={{width: '30%', marginLeft: '5%', marginTop: '5%', display: 'flex', flexDirection: 'row'}}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <img style={{height: '220px', borderRadius: '110px'}} src={require('../assets/images/expert_01.jpg')} alt=""/>
                                    <span style={{color: '#3f2564', fontWeight: 'bold', fontSize: '1.1em', marginTop: '5%'}}>Sophie</span>
                                    <span style={{color: '#7dcb97', fontWeight: 'bold', fontSize: '1.1em'}}>Experte en agence</span>
                                    <span style={{color: '#3f2564', fontSize: '1em', marginTop: '5%'}}>Toujours le sourire pour répondre à vos demandes.</span>
                                </div>
                                <img style={{height: '65px'}} src={require('../assets/images/Vestel-logo-1.png')} alt=""/>
                                <img style={{height: '65px'}} src={require('../assets/images/Logo_Hisense_France.png')} alt=""/>
                            </div>
                            <div style={{width: '30%', marginLeft: '5%', marginTop: '5%', display: 'flex', flexDirection: 'row'}}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <img style={{height: '220px', borderRadius: '110px'}} src={require('../assets/images/expert_3.jpg')} alt=""/>
                                    <span style={{color: '#3f2564', fontWeight: 'bold', fontSize: '1.1em', marginTop: '5%'}}>Rody</span>
                                    <span style={{color: '#7dcb97', fontWeight: 'bold', fontSize: '1.1em'}}>Expert à domicile</span>
                                    <span style={{color: '#3f2564', fontSize: '1em', marginTop: '5%'}}>11 ans d’expérience, il raffole des bons petits plats ! Sa devise : «intervention réussie, client ravi »</span>
                                </div>
                                <img style={{height: '65px'}} src={require('../assets/images/Vestel-logo-1.png')} alt=""/>
                                <img style={{height: '65px'}} src={require('../assets/images/Logo_Hisense_France.png')} alt=""/>
                            </div>
                        </div>
                        <Link 
                            to="/careers"
                            className="contactLink"
                            style={{paddingLeft: '10px', marginTop: '3%'}}
                        >Rejoignez-nous</Link>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer 
                    offset={8} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(0)}
                    style={{
                        backgroundColor: 'white',
                        cursor: 'pointer'
                    }}
                    >
                    <div style={{display: 'flex', flexDirection: 'column', width: '80%', marginLeft: '10%', height:'100%', alignItems: 'center'}}>
                        <div className="page9Title" style={{marginLeft: '0%'}}>
                            <span className="page9TitlePart1">Nos</span>
                            <span className="page9TitlePart2">agences</span>
                        </div>
                        <div className="page10Container">
                            {agencies.length > 0 &&
                                agencies.map((agency)=>{
                                    return(
                                        <Link 
                                            to={{
                                                pathname: "/agencies",
                                            }}
                                            state={{agency:agency}}
                                            className="agencyLink"
                                            style={{display: 'inline-block', marginLeft: '50px', marginTop: '30px', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)'}}
                                        >
                                           <span style={{width: '180px', height: '64px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', fontSize: '1.2em'}}>909 {agency.name}</span>
                                        </Link>
                                    )
                                })

                            }
                        </div>
                    </div>
                </ParallaxLayer>
            </Parallax>
        </div>
    )
}

const customStyles = {
}

export default Home