import React, { useState } from 'react';
import Modal from 'react-modal';
import '../css/infoModal.css';
import LyonImg from '../assets/images/equipe-lyon-1024x682.jpg'
import ParisImg from '../assets/images/909-equipe-paris-1024x683.jpg'
import ToursImg from '../assets/images/equipe-909-tours-1024x768.jpg'
import StrasbourgImg from '../assets/images/equipe-909-strasbourg-1024x765.jpg'
import RouenImg from '../assets/images/equipe-rouennaise-1024x576.jpg'
import ToulouseImg from '../assets/images/equipe-toulouse-1024x682.jpg'

import CloseCross from '../assets/images/close_cross.png'
import AgencyInfos from './AgencyInfos';
import { useEffect } from 'react';

Modal.setAppElement('#root');

const AgencyModal = (props) => {
    const [teamPhoto, setTeamPhoto] = useState('')
    const teamsPictures = [
        {city: 'Lyon', picture: LyonImg},
        {city: 'Paris', picture: ParisImg},
        {city: 'Tours', picture: ToursImg},
        {city: 'Strasbourg', picture: StrasbourgImg},
        {city: 'Rouen', picture: RouenImg},
        {city: 'Toulouse', picture: ToulouseImg}
    ]

    useEffect(()=>{
        if(props.agencyForModal){
            teamsPictures.map(teamPicture=>{
                if(teamPicture.city == props.agencyForModal.name){
                    // console.log(teamPicture.picture);
                    setTeamPhoto(teamPicture.picture)
                }
            })
        }
    })


    const closeModal = () => {
        props.setAgencyModalVisible(false);
    }

    // console.log(props.agencyForModal);

    return (
        <Modal
            isOpen={props.agencyModalVisible}
            onRequestClose={closeModal}
            style={customStyles}
        >
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <img style={{width:30, marginBottom:'25px', cursor:'pointer', float:'right'}}
                    src={CloseCross}
                    alt="909"
                    onClick={closeModal}
                />
            </div>
            <div style={{
                display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'space-around', alignItems: 'center'}}>
                <span style={{fontWeight: 'bold', fontSize: '1.3em', color: '#3f2564'}}>L'agence de {props.agencyForModal.name}</span>
                <img 
                    src={teamPhoto}
                    style={{width: '70%', margin: '5%', }}
                />
                <AgencyInfos
                    agency={props.agencyForModal}
                />
            </div>
        </Modal>
    )
}

const customStyles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width:'80%',
    height:'780px',
    color: '#444444',
    borderRadius: '12px'
  },
  overlay: {
    position: 'fixed',
    zIndex: 50,
    background: 'rgba(115, 115, 115, 0.8)'
  },
  article: {
    fontSize: '1.1em', 
    display: 'flex', 
    justifyContent: 'space-around', 
    flexDirection: 'column',
    marginTop:'2vh', 
    height:'90%'
  }
};

export default AgencyModal