import { Parallax, ParallaxLayer } from "@react-spring/parallax"
import { useRef } from "react"

import homeInter from '../assets/images/reparation-a-domicile.svg'
import homeRemote from '../assets/images/diagnostic-a-distance.svg'
import homeWorkshop from '../assets/images/reparation-en-atelier.svg'
import { Link } from "react-router-dom"
import FranceSimpleMap from "../components/FranceSimpleMap"

const Products = () => {
    const parallax = useRef()
    const franceMap = useRef()

    return (
        <div className="products">
            <Parallax 
                pages={7} 
                ref={parallax}
                >
                <ParallaxLayer 
                    offset={0} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(1)}
                    style={{backgroundColor:"#fff"}}>
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', height:'100%', alignItems: 'center', marginTop: '15%'}}>
                        <div className="productsPage1Title" style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                            <div className="page1TitleContainer" style={{width: '90%', marginLeft: '5%'}}>
                                <div style={{rotate: '-12deg', fontWeight: 'bold', fontSize: '2.8em'}}>
                                    <span style={{color: '#3f2564', fontStyle: 'italic'}}>Nos experts réparent tous </span><span style={{textIndent: '8px', color: '#7dcb97', fontStyle: 'italic'}}> vos appareils</span>
                                </div>
                                <div style={{rotate: '-12deg', fontWeight: 'bold', fontSize: '2.8em'}}>
                                    <span style={{color: '#7dcb97', fontStyle: 'italic'}}>électroniques et électroménagers : </span><span style={{textIndent: '8px', color: '#3f2564', fontStyle: 'italic'}}> TV,</span>
                                </div>
                                <div style={{rotate: '-12deg', fontWeight: 'bold', fontSize: '2.8em'}}>
                                    <span style={{color: '#3f2564', fontStyle: 'italic'}}>machine à laver, frigo, four…</span>
                                </div>
                            </div>
                        </div>
                        <div className="productsPage1">
                            <img style={{height: '220px', width: '240px', borderRadius: '110px', marginLeft: '10%', marginTop: '5%'}} src={require('../assets/images/technicien.png')} alt=""/>
                            <div style={{
                                display: 'flex', 
                                flexDirection: 'column',
                                width: '80%',
                                marginLeft: '10%'
                                }}>                            
                                <img className="speedLogo" style={{marginLeft: 28, position: 'absolute', zIndex: 2}} src={require('../assets/images/3_elements.png')} alt=""/>
                                <div className='productsPage1Span'>
                                    <div className="page5MiniTitle" style={{color: '#7dcb97'}}>Solutions de réparation</div> 
                                    <div className="page5MiniTitle">pour vos produits électroniques et électroménagers</div>  
                                    <div className="page5MiniText">En cas de panne, un expert 909 intervient à votre domicile*. Vous pouvez également déposer vos petits appareils (micro-onde, TV..) dans nos agences pour une réparation en atelier. Et si vous souhaitez identifier la panne de votre appareil (machine à laver, frigo, four, lave-vaisselle…) et avoir un devis, vous pouvez alors choisir l’option du diagnostic à distance.</div>                     
                                </div>
                            </div>                      
                        </div>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={1} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(2)}
                    style={{
                        backgroundColor: '#3f2564',
                        backgroundSize: 'cover',
                        cursor: 'pointer'
                        }}>
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', height:'100%', alignItems: 'center'}}>
                        <div className="page4TitleContainer">
                            <span style={{color: '#fff'}}>Des offres </span><span style={{textIndent: '8px', color: '#7dcb97'}}> pour tous</span>
                        </div>
                        <div className="page4InfosContainer">
                            <div className="infoColumnWhiteBG">
                                <div className="infosColumnWhiteBGTitle">
                                    <img style={{height: '75px'}} src={homeInter} alt="homeSavoir"/>
                                    <div className="infosColumnWhiteBGTitletext">
                                        <span style={{fontWeight: 'bold'}}>Intervention à domicile</span>
                                    </div>
                                </div>
                                <span style={{fontSize: '0.7em'}}>
                                    Par appareil & hors pièces détachées
                                </span>
                                <ul style={{fontSize: '0.6em', marginLeft: '0px', paddingLeft: '20px'}}>
                                    <li>Déplacement et intervention à domicile* d’un expert 909</li>
                                    <li>Prestation garantie 3 mois</li>
                                </ul>
                                <span style={{fontSize: '0.5em', marginTop: '5px'}}>*Dans la limite des zones d’intervention proposées par 909 pour des prestations ateliers ou à domicile. Pour plus de détails, cliquez 
                                    <Link 
                                    style={{paddingLeft: '10px'}}
                                        onClick={(e) => {
                                        e.stopPropagation()
                                        parallax.current.scrollTo(2)}}
                                    >ici</Link>
                                </span>
                            </div>
                            <div className="infoColumnWhiteBG">
                            <div className="infosColumnWhiteBGTitle">
                                <img style={{height: '75px'}} src={homeRemote} alt="homeConfiance"/>
                                    <div className="infosColumnWhiteBGTitletext">
                                        <span style={{fontWeight: 'bold'}}>Expertise à distance</span>
                                        <span style={{fontWeight: 'bold',  color: '#7dcb97'}}>35 €</span>
                                    </div>
                                </div>
                                <span style={{fontSize: '0.7em'}}>
                                    Par appareil & hors pièces détachées
                                </span>
                                <ul style={{fontSize: '0.6em', marginLeft: '0px', paddingLeft: '20px'}}>
                                    <li>Appel avec un expert 909</li>
                                    <li>Si possible, on vous solutionne immédiatement !</li>
                                    <li>Sinon, 909 vous établi un devis pour une réparation*</li>
                                </ul>
                                <span style={{fontSize: '0.5em', marginTop: '5px'}}>*Dans la limite des zones d’intervention proposées par 909 pour des prestations ateliers ou à domicile. Pour plus de détails, cliquez 
                                    <Link 
                                    style={{paddingLeft: '10px'}}
                                        onClick={(e) => {
                                        e.stopPropagation()
                                        parallax.current.scrollTo(2)}}
                                    >ici</Link>
                                </span>
                            </div>
                            <div className="infoColumnWhiteBG">
                                <div className="infosColumnWhiteBGTitle">
                                    <img style={{height: '75px'}} src={homeWorkshop} alt="homeSimplicity"/>
                                    <div className="infosColumnWhiteBGTitletext">
                                        <span style={{fontWeight: 'bold'}}>Réparation en atelier</span>
                                        <span style={{fontWeight: 'bold', color: '#7dcb97'}}>sur devis</span>
                                    </div>
                                </div>
                                <span style={{fontSize: '0.7em'}}>
                                    Par appareil & hors pièces détachées
                                </span>
                                <ul style={{fontSize: '0.6em', marginLeft: '0px', paddingLeft: '20px'}}>
                                    <li>Diagnostic et réparation en atelier</li>
                                    <li>Dépôt par vos soins dans nos ateliers</li>
                                    <li>Réparation garantie 3 mois</li>
                                </ul>
                                <span style={{fontSize: '0.5em', marginTop: '5px'}}>*Dans la limite des zones d’intervention proposées par 909 pour des prestations ateliers ou à domicile. Pour plus de détails, cliquez 
                                    <Link 
                                    style={{paddingLeft: '10px'}}
                                        onClick={(e) => {
                                        e.stopPropagation()
                                        parallax.current.scrollTo(2)}}
                                    >ici</Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer 
                    offset={2} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(3)}
                    style={{backgroundColor:"#fff"}}>
                        <div className="interventionDualDiv">
                            <div style={{width: '50%',display: 'flex', alignItems: 'center'}}>
                                <img style={{marginLeft: 28, position: 'relative', zIndex: 2, width: '100%'}} src={require('../assets/images/minitatures-categories-gros-electromenager.jpg')} alt=""/>
                            </div>
                            <div style={{width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center' , alignItems: 'center'}}>
                                <span style={{fontSize: '2.1em', fontWeight: 'bold', color: '#3f2564'}}>Gros électroménager</span>
                                <div style={{width: '80%'}}>
                                    <span style={{color: '#3f2564'}}>Nos experts prennent en charge et </span><span style={{textIndent: '8px', color: '#7dcb97'}}> réparent à votre domicile votre gros électroménager :</span>
                                </div>
                                <div style={{width: '80%', display: 'flex', flexDirection: 'row', marginTop: '25px', justifyContent: 'space-around'}}>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>                                    
                                        <img style={{position: 'relative', zIndex: 2, width: '75px'}} src={require('../assets/images/lave-vaisselle-repare.svg').default} alt=""/>
                                        <span style={{fontSize: '0.8em',fontWeight: 'bold', color: '#3f2564', marginTop: '5px'}}>Sèche-linge</span>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>                                    
                                        <img style={{position: 'relative', zIndex: 2, width: '75px'}} src={require('../assets/images/four-repare.svg').default} alt=""/>
                                        <span style={{fontSize: '0.8em',fontWeight: 'bold', color: '#3f2564', marginTop: '5px'}}>Lave-vaisselle</span>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>                                    
                                        <img style={{position: 'relative', zIndex: 2, width: '75px'}} src={require('../assets/images/refrigerateur-repare.svg').default} alt=""/>
                                        <span style={{fontSize: '0.8em',fontWeight: 'bold', color: '#3f2564', marginTop: '5px'}}>Réfrigérateur & Frigo</span>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>                                    
                                        <img style={{position: 'relative', zIndex: 2, width: '75px'}} src={require('../assets/images/machine-a-laver-repare.svg').default} alt=""/>
                                        <span style={{fontSize: '0.8em',fontWeight: 'bold', color: '#3f2564', marginTop: '5px'}}>Lave-linge</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                </ParallaxLayer>
                <ParallaxLayer 
                    offset={3} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(4)}
                    style={{backgroundColor:"#fff"}}>
                        <div className="interventionDualDiv">
                            <div style={{width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center' , alignItems: 'center'}}>
                                <span style={{fontSize: '2.1em', fontWeight: 'bold', color: '#3f2564'}}>L'électronique</span>
                                <div style={{width: '80%'}}>
                                    <span style={{color: '#3f2564'}}>Nos experts réparent </span><span style={{textIndent: '8px', color: '#7dcb97'}}> tous vos produits électroniques à votre domicile et en atelier :</span>
                                </div>
                                <div style={{width: '80%', display: 'flex', flexDirection: 'row', marginTop: '25px', justifyContent: 'space-around'}}>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>                                    
                                        <img style={{position: 'relative', zIndex: 2, width: '75px'}} src={require('../assets/images/tv-repare.svg').default} alt=""/>
                                        <span style={{fontSize: '0.8em',fontWeight: 'bold', color: '#3f2564', marginTop: '5px'}}>TV</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: '50%',display: 'flex', alignItems: 'center'}}>
                                <img style={{marginLeft: 28, position: 'relative', zIndex: 2, width: '100%'}} src={require('../assets/images/minitatures-categories-electronique.jpg')} alt=""/>
                            </div>
                        </div>
                </ParallaxLayer>
                <ParallaxLayer 
                    offset={4} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(5)}
                    style={{backgroundColor:"#fff"}}>
                        <div className="interventionDualDiv">
                            <div style={{width: '50%',display: 'flex', alignItems: 'center'}}>
                                <img style={{marginLeft: 28, position: 'relative', zIndex: 2, width: '100%'}} src={require('../assets/images/minitatures-categories-petit-electromenager.jpg')} alt=""/>
                            </div>
                            <div style={{width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center' , alignItems: 'center'}}>
                                <span style={{fontSize: '2.1em', fontWeight: 'bold', color: '#3f2564'}}>Petit électroménager</span>
                                <div style={{width: '80%'}}>
                                    <span style={{color: '#3f2564'}}>Nos experts réparent dans nos agences </span><span style={{textIndent: '8px', color: '#7dcb97'}}> votre petit électroménager :</span>
                                </div>
                                <div style={{width: '80%', display: 'flex', flexDirection: 'row', marginTop: '25px', justifyContent: 'space-around'}}>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>                                    
                                        <img style={{position: 'relative', zIndex: 2, width: '75px'}} src={require('../assets/images/micro-onde-repare.svg').default} alt=""/>
                                        <span style={{fontSize: '0.8em',fontWeight: 'bold', color: '#3f2564', marginTop: '5px'}}>Micro-onde</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                </ParallaxLayer>
                <ParallaxLayer 
                    offset={5} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(6)}
                    style={{
                        backgroundColor: 'white',
                        cursor: 'pointer'
                    }}
                    >
                    <div style={{display: 'flex', flexDirection: 'column', width: '80%', marginLeft: '10%', height:'100%', alignItems: 'center'}}>
                        <div className="page9Title">
                            <span className="page9TitlePart1">Notre équipe</span>
                            <span className="page9TitlePart2">d'experts</span>
                        </div>
                        <div className="page9Container">
                            <div style={{width: '30%', marginLeft: '3%', marginTop: '5%', display: 'flex', flexDirection: 'row'}}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <img style={{height: '220px', borderRadius: '110px'}} src={require('../assets/images/expert_2.jpg')} alt=""/>
                                    <span style={{color: '#3f2564', fontWeight: 'bold', fontSize: '1.1em', marginTop: '5%'}}>Damien</span>
                                    <span style={{color: '#7dcb97', fontWeight: 'bold', fontSize: '1.1em'}}>Expert en atelier</span>
                                    <span style={{color: '#3f2564', fontSize: '1em', marginTop: '5%'}}>Notre nouvelle recrue ! Un grand sportif. Sa devise : « ne jamais baisser les bras »</span>
                                </div>
                                <img style={{height: '65px'}} src={require('../assets/images/Vestel-logo-1.png')} alt=""/>
                                <img style={{height: '65px'}} src={require('../assets/images/Logo_Hisense_France.png')} alt=""/>
                            </div>
                            <div style={{width: '30%', marginLeft: '5%', marginTop: '5%', display: 'flex', flexDirection: 'row'}}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <img style={{height: '220px', borderRadius: '110px'}} src={require('../assets/images/expert_01.jpg')} alt=""/>
                                    <span style={{color: '#3f2564', fontWeight: 'bold', fontSize: '1.1em', marginTop: '5%'}}>Sophie</span>
                                    <span style={{color: '#7dcb97', fontWeight: 'bold', fontSize: '1.1em'}}>Experte en agence</span>
                                    <span style={{color: '#3f2564', fontSize: '1em', marginTop: '5%'}}>Toujours le sourire pour répondre à vos demandes.</span>
                                </div>
                                <img style={{height: '65px'}} src={require('../assets/images/Vestel-logo-1.png')} alt=""/>
                                <img style={{height: '65px'}} src={require('../assets/images/Logo_Hisense_France.png')} alt=""/>
                            </div>
                            <div style={{width: '30%', marginLeft: '5%', marginTop: '5%', display: 'flex', flexDirection: 'row'}}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <img style={{height: '220px', borderRadius: '110px'}} src={require('../assets/images/expert_3.jpg')} alt=""/>
                                    <span style={{color: '#3f2564', fontWeight: 'bold', fontSize: '1.1em', marginTop: '5%'}}>Rody</span>
                                    <span style={{color: '#7dcb97', fontWeight: 'bold', fontSize: '1.1em'}}>Expert à domicile</span>
                                    <span style={{color: '#3f2564', fontSize: '1em', marginTop: '5%'}}>11 ans d’expérience, il raffole des bons petits plats ! Sa devise : «intervention réussie, client ravi »</span>
                                </div>
                                <img style={{height: '65px'}} src={require('../assets/images/Vestel-logo-1.png')} alt=""/>
                                <img style={{height: '65px'}} src={require('../assets/images/Logo_Hisense_France.png')} alt=""/>
                            </div>
                        </div>
                        <Link 
                            to="/careers"
                            className="contactLink"
                            style={{paddingLeft: '10px', marginTop: '3%'}}
                        >Rejoignez-nous</Link>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    ref={franceMap}
                    offset={6} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(0)}
                    style={{
                        backgroundColor: '#fff',
                        cursor: 'pointer'
                    }}>
                    <div style={{display: 'flex', flexDirection:'column', width: '100%', height: '100%', alignItems: 'center'}}>
                        
                        <FranceSimpleMap/>
                        <div className="page3TitleContainer" style={{marginTop: '5px'}}>
                            <span style={{color: '#3f2564'}}>Notre réseau national </span><span style={{textIndent: '8px', color: '#7dcb97'}}> d'agences</span>
                        </div>
                        <div className="productsMap" style={{height: '80%', display: 'flex', flexDirection:'column', alignItems: 'center', width: '80%', position: 'fixed', zIndex: '50'}}>
                            <span style={{color: '#3f2564', fontWeight: 'bold', fontSize: '1.4em'}}>En atelier ou à distance</span>
                            <div className="productsMapSubtitle"><span>Prenez RDV avec un expert 909 et établissez un </span><span style={{textIndent: '8px', color: '#7dcb97'}}>diagnostic pour 35€</span></div>
                            <span style={{color: '#3f2564', fontWeight: 'bold', fontSize: '1.1em', marginTop: '15px'}}>Besoin d’aller plus loin ?</span>
                            <span style={{color: '#3f2564', fontWeight: 'bold', fontSize: '1.1em'}}>909 vous accompagne jusqu’au bout et vous propose d’intervenir CHEZ VOUS sur RDV à partir de 95€ (1)</span>
                            <span style={{fontSize: '0.7em', marginTop: '5px'}}>(1) Selon catégorie d’appareil, zone d’intervention. Bonus réparation inclus. Tarif TTC hors pièces détachées, fourniture et intervention excédant une heure de main d’œuvre</span>
                        </div>
                    </div>
                </ParallaxLayer>
            </Parallax>
        </div>
    )
}

export default Products