import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { getArticles } from '../features/articlesSlice';
import TrashIcon from '../assets/images/trash_icon_white.png'
import { getJobs } from '../features/jobsSlice';

Modal.setAppElement('#root');

const JobEditorModal = (props) => {
    const dispatch = useDispatch()
    const { user } = useSelector((store) => store.user)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState('')
    const [text, setText] = useState('')
    const [contact, setContact] = useState('')
    const [type, setType] = useState('')
    const [error, setError] = useState(null)

    // console.log(photoName);

    useEffect(() => {
        if(props.title){
            setTitle(props.title)
        }
        if(props.contact){
            setContact(props.contact)
        }
        if(props.text){
            setText(props.text)
        }
        if(props.type){
            setType(props.type)
        }
    },[props])

    const handleSubmit = (e) => {
        if(type == 'create'){
            // console.log('create article');
            createJob(e)
        }
        if(type == 'update'){
            // console.log('update article');
            updateJob(e)
        }
        dispatch(getArticles())
    }

    const createJob = async (e) => {
        e.preventDefault()
        const job = {title, text, contact}
        await fetch(global.constantes.proxy.base + '/job',{
            method: 'POST',
            body: JSON.stringify(job),
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${user.token}`
            }
        })
        .then( async response => {
            await response.json()
            .then(async json => {
                if(json.success){
                    setError(null)
                    setContact('')
                    setTitle('')
                    setText('')
                    closeModal()
                    dispatch(getJobs())
                    // console.log('new article added', json);
                }else{
                    setError(json.error)
                }
            })
        })
    }

    const updateJob = async (e) => {
        e.preventDefault()
        let id = props.id
        const job = {id, title, text, contact}
        await fetch(global.constantes.proxy.base + '/job/' + id ,{
            method: 'PUT',
            body: JSON.stringify(job),
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${user.token}`
            }
        })
        .then( async response => {
            await response.json()
            .then(async json => {
                if(json.success){
                    setError(null)
                    setContact('')
                    setTitle('')
                    setText('')
                    // closeModal()
                    dispatch(getJobs())
                }else{
                    setError(json.error)
                }
            })
        })
    }

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    return (
        <div>
            {type == 'update' ?
                <button 
                    className="article-details-update" 
                    onClick = {openModal}
                /> :
                <div 
                    className="newArticle"
                    onClick={openModal}
                >
                    Créer une nouvelle offre d'emploi
                </div>
            }
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
        >
            <form className="create">
                { type == 'update' ? <h3>Modifier un emploi</h3> :<h3>Ajouter un emploi</h3> }
                <label >Titre: </label>
                <input 
                    type=""
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                />
                { title == '' &&
                    <span className='warning center' style={{marginTop: '1vh', fontSize: '1em'}}>Merci de remplir le titre</span>
                }
                <label >Texte: </label>
                <textarea  
                    type="textarea"
                    onInput={(e) => setText(e.target.value)}
                    value={text}
                    style={{width: '100%', height: '175px', overflowY: 'scroll'}}
                />
                { text == '' &&
                    <span className='warning center' style={{marginTop: '1vh', fontSize: '1em'}}>Merci de remplir le texte</span>
                }
                <label >Contact: </label>
                <textarea 
                    type="textarea"
                    onInput={(e) => setContact(e.target.value)}
                    value={contact}
                    style={{width: '100%', height: '50px', overflowY: 'scroll'}}
                />
                { contact == '' &&
                    <span className='warning center' style={{marginTop: '1vh', fontSize: '1em'}}>Merci de remplir le contact</span>
                }
                <div style={{float: 'right', marginTop: '30px'}}>
                    <button onClick={handleSubmit}>Valider</button>
                    <button onClick={closeModal} style={{marginLeft: '10px'}}>Annuler</button>
                </div>
                {error && <div className="error">{error}</div>}
            </form>
            
        </Modal>
        </div>
    )
}

const customStyles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width:'80vw',
    height:'60vh',
    color: '#444444'
  },
  overlay: {
    position: 'fixed',
    zIndex: 50,
    background: 'rgba(115, 115, 115, 0.8)'
  },
  article: {
    fontSize: '1.1em', 
    display: 'flex', 
    justifyContent: 'space-around', 
    flexDirection: 'column',
    marginTop:'2vh', 
    height:'90%'
  }
};

export default JobEditorModal