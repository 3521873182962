const prod = {
    proxy: {
        base: "/node/api/v1"
    }
};

const dev = {
    proxy: {
        base: "/api/v1"
    }
};

module.exports = global.constantes = process.env.NODE_ENV === 'development' ? dev : prod;