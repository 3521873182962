import React, { useState } from 'react';
import Modal from 'react-modal';

import CloseCross from '../assets/images/close_cross.png'
import { useSelector } from 'react-redux';


Modal.setAppElement('#root');

const UserModal = (props) => {

  const { user } = useSelector((store) => store.user)
  const [modalIsOpen, setIsOpen] = useState(false);
  const [ customer, setCustomer ] = useState()

  const openModal = async () => {
    await fetch(global.constantes.proxy.base + '/users/' + props.id,{
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${user.token}`
      }
    })
    .then( async response =>{
      await response.json()
      .then(json => {
          if(json){
            // console.log(json);
            setCustomer(json[0])
            setIsOpen(true);
          }
        })
    })
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  const setPhoneNumber = (phonenumber) => {
    let phoneNumber = []
    // let numbers = phonenumber.substring(3, phonenumber.length).split('')
    let numbers = phonenumber.split('')
    // numbers.unshift('0')
    numbers.map((number,index) => {
      if(index % 2 != 0){
        phoneNumber.push(numbers[index-1] + number)
      }
    })
    return phoneNumber.join(' ')
  }

  return (
    <div 
      style={{height:'10vh', marginLeft:'5vw', marginTop:'3vh'}}>
      <button 
        style={{...customStyles.button, 
            ...props.selected ? 
            {backgroundColor: '#b5f3ca'} : 
            {backgroundColor: 'rgba(255,255,255,0.7)'}}}
        id='repairButton' 
        onClick= {()=>{openModal()}}>
              <div style={{display: 'flex', flexDirection: 'column', width: '40%'}}>
                <div>
                    {props.firstName} {props.lastName}
                </div>
              </div>
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className='fileModal'>
          <img 
            style={{width:30, marginBottom:'25px', cursor:'pointer', right:'5px', position:'absolute'}}
            src={CloseCross}
            alt="909"
            onClick={closeModal}/>
          {customer && 
            <div style={{marginTop:'8vh', width:'90%', height: '90%', maxHeight:'80%', overflowX:'hidden'}}>
              {!props.userModal &&
                <>
                  <div style={{display:'flex', flexDirection:'row', justifyContent:'center', marginBottom:'6vh', fontSize: '1.1em'}}>
                    <span style={{fontWeight:'bold'}}>Client : </span>
                    <span style={{marginLeft:'5px'}}>{customer.first_name} {customer.last_name}</span>
                  </div>
                  <div style={{display:'flex', flexDirection:'row', left:'2vw'}}>
                    <span style={{fontWeight:'bold'}}>Adresse : </span>
                    <span style={{marginLeft:'5px'}}>{customer.adress} {customer.zipcode} {customer.city_name} </span>
                  </div>
                  <div style={{display:'flex', flexDirection:'row', left:'2vw'}}>
                    <span style={{fontWeight:'bold'}}>Email : </span>
                    <span style={{marginLeft:'5px'}}>{customer.email}</span>
                  </div>
                  <div style={{display:'flex', flexDirection:'row', left:'2vw'}}>
                    <span style={{fontWeight:'bold'}}>Téléphone : </span>
                    <span style={{marginLeft:'5px'}}>{customer.phone_number ? setPhoneNumber(customer.phone_number) : ''}</span>
                  </div>
                </>
              }
            </div>
          }
        </div>
        <div style={{fontSize: '1.1em', display: 'flex', justifyContent: 'space-around', marginTop:'2vh'}}>{props.advice}</div>
      </Modal>
    </div>
  )
}

const customStyles = {
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position:'relative',
        fontSize:'1.2em', 
        borderRadius:'6px', 
        borderStyle:'none', 
        backgroundColor:'rgba(255, 255, 255, 0.5)',
        width:'55vw',
        height:'100%', 
        cursor:'pointer'
    },
    userButton: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position:'relative',
        fontSize:'1.1em', 
        borderRadius:'6px', 
        borderStyle:'none', 
        width:'100%',
        height:'100%', 
        cursor:'pointer'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width:'70vw',
        height:'40vh',
    },
    overlay: {
        position: 'fixed',
        zIndex: 50,
        background: 'rgba(150, 150, 150, 0.5)'
    },
    buttonDateRDV: {
      right:'10px', 
      // position:'absolute', 
      display:'flex', 
      justifyContent:'center', 
      alignItems:'center',
      padding: '5px',
      borderRadius: '4px',
      marginLeft: '15px'
    },
    userButtonDateRDV: {
      right:'10px', 
      display:'flex', 
      justifyContent:'center', 
      alignItems:'center',
      padding: '5px',
      borderRadius: '4px',
      marginTop: '25px'
    }
};

export default UserModal