import { Parallax, ParallaxLayer } from "@react-spring/parallax"
import { useRef } from "react"

const Legal = () => {
    const parallax = useRef()

    return (
        <div style={{overflowY: 'scroll', height: '80%'}}>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>1. Informations légales</h2>
                <span>
                    Raison sociale : 909
                </span>
                <span>
                    Adresse du siège social : 12, Avenue de la Chapelle – La Jubaudière 49510 Beaupréau-en-Mauges
                </span>
                <span>
                    Numéro de téléphone : 01 49 78 98 00
                </span>
                <span>
                    Adresse de courrier électronique : webmaster@909services.com
                </span>
                <span>
                    Forme juridique : SAS
                </span>
                <span>
                    Montant du capital social : 34 982,70 €
                </span>
                <span>
                    Directeur de la publication : Claire BAUDET
                </span>
                <span>
                    Numéro d’inscription au RCS : Angers B 920 080 256
                </span>
                <span>
                    Numéro de TVA intracommunautaire : FR50920080256
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>2. Coordonnées de l'hébergeur du site</h2>
                <span>
                    OVH 
                </span>
                <span>
                    SAS au capital de 10 069 020 €
                </span>
                <span>
                    RCS Lille Métropole 424 761 419 00045
                </span>
                <span>
                    Code APE 2620Z
                </span>
                <span>
                    N° TVA : FR 22 424 761 419
                </span>
                <span>
                    Siège social : 2 rue Kellermann – 59100 Roubaix – France
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>3. La gestion des Cookies et traceurs</h2>
                <h3>
                    Qu’est-ce que c’est ?
                </h3>
                <span>
                    Les cookies sont de petits fichiers texte qui sont automatiquement déposés sur votre ordinateur lorsque vous naviguez sur le web. Ces fichiers stockent des informations relatives à l’utilisateur telle que l’activité et les préférences et dont l’objectif est de permettre au serveur du site Internet d’identifier facilement l’utilisateur d’un site à un autre.
                </span>
                <h3>
                    Quelles données sont collectées ?
                </h3>
                <span>
                    Via l’utilisation de cookies, les sites Internet d’Itancia et de ses filiales collectent des données personnelles lors des visites sur les sites : adresse IP, données de navigation, durées de transfert des données, préférences des utilisateurs, mots de passe, fichiers journaux, balises Web.            
                </span>
                <h3>
                    Où sont-ils stockés ?
                </h3>
                <span>
                    Les cookies sont stockés sur l’appareil de l’utilisateur.             
                </span>
                <h3>
                    Pourquoi sont-ils utilisés ?
                </h3>
                <span>
                    909 utilise des cookies et autres technologies pour :            
                </span>
                <span>
                    Stocker les informations personnelles du profil de l’utilisateur             
                </span>
                <span>
                    Faciliter le téléchargement de documents à partir de sites             
                </span>
                <span>
                    Nécessaire à la fourniture des produits/services            
                </span>
                <span>
                    Configurer le navigateur de l’utilisateur pour bloquer, rejeter, supprimer ou désactiver les cookies et autres technologies de suivi            
                </span>
                <span>
                    Identifier les problèmes et l’utilisation des sites Internet            
                </span>
                <span>
                    Effectuer une analyse de trafic standard sur les sites            
                </span>
                <span>
                    Suivre l’utilisation du site à des fins statistiques de fréquentation anonyme            
                </span>
                <span>
                    Suivre l’historique des visites de l’utilisateur             
                </span>
                <span>
                    À des fins commerciales           
                </span>
                <span>
                    Toutefois, si l’utilisateur rejette ou supprime des cookies ou toute autre technologie de suivi, il ne pourra peut-être pas accéder à certaines pages ou utiliser des fonctions importantes, y compris le téléchargement de documents et l’établissement d’un profil utilisateur.           
                </span>
            </div>
        </div>
    )
}

export default Legal