// import 'leaflet/dist/leaflet.css';
import { useSelector } from "react-redux"
import AgencyInfos from "../../components/AgencyInfos"
import AcceptCGV from "../../components/AcceptCGV"
import { useEffect, useState } from "react"

const RepairFileIntervention = (props) => {
    const { token } = useSelector((store) => store.user.user)
    const { user } = useSelector((store) => store.user.user)
    const { repairFile } = useSelector((store) => store.repairFile)
    const { intervention_type } = repairFile.selectedOffer
    const [ repairFileLog, setRepairFileLog ] = useState(false)
    const [ repairFileSaveTrouble, setRepairFileSaveTrouble ] = useState(true)

    useEffect(()=>{
        async function saveRepairFileLog(){
            await fetch(global.constantes.proxy.base + '/repairfilelog',{
                method: 'POST',
                body: JSON.stringify(repairFile),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${token}`
                }
            })
            .then( async response =>{
                await response.json()
                .then(json => {
                    if(json){
                        console.log(json);
                        setRepairFileLog(json)
                    }
                })
            })
        }
        if(!repairFileLog){
            saveRepairFileLog()
        }

    }, [repairFileLog])

    const asyncLocalStorage = {
        async setItem(key, value) {
            await null;
            return localStorage.setItem(key, value);
        },
        async getItem(key) {
            await null;
            return localStorage.getItem(key);
        }
    };

    const saveRepairFile = async () => {
        if(repairFile){
            await fetch(global.constantes.proxy.base + '/repairfile',{
                method: 'POST',
                body: JSON.stringify(repairFile),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${token}`
                }
            })
            .then( async response =>{
                await response.json()
                .then(json => {
                    if(json.success){
                        asyncLocalStorage.setItem('repairFileId', JSON.stringify(json.id))
                        .then(function () {
                            return asyncLocalStorage.getItem('repairFileId')
                        })
                        .then(function (value) {
                            if(value){
                                props.handleNextClick()
                            }else{
                                setRepairFileSaveTrouble(false)
                            }
                        })
                    }else{
                        setRepairFileSaveTrouble(false)
                    }
                })
            })
        }
    }

    return (
        <div className="repairFileStep">
            <div className="repairFileStepHeader">
                <p style={{marginLeft: '4vw'}}>{props.header}</p>
            </div>
                {intervention_type && intervention_type == 'Atelier' &&
                <div style={{width:'80%', marginLeft:'10%', marginTop:'5vh'}}>
                    <AgencyInfos agency={repairFile.nearestAgency} />
                </div>
                }
            <div className="recapText">
                {intervention_type == 'Domicile' &&
                    <div className='overviewDiv' style={{width:'80%', marginLeft:'10%',flexDirection:'column', padding:'5px'}}>
                        <p>Madame, Monsieur {user.last_name}</p>
                        <p>Vous avez choisi diagnostique à distance avec un expert
                            909 pour : </p>
                        <span>Appareil : {repairFile.device.name}</span>
                        <span>De marque : {repairFile.brand}</span>
                        {repairFile.serialNumber &&
                            <span>N° de série : {repairFile.serialNumber}</span>
                        }
                        <span>N’étant plus sous garantie</span>
                        <p>Pour le problème déclaré suivant : {repairFile.issue.issue}</p>
                        {repairFile.comment &&
                            <span>Informations complémentaires : {repairFile.comment}</span>
                        }
                        {repairFile.selectedTimeSlotRef == 'morning' ? 
                            <span>Date du RDV téléphonique : {repairFile.phoneAppointment}, le matin</span> :
                            <span>Date du RDV téléphonique : {repairFile.phoneAppointment}, l'après-midi'</span>
                        }
                        <p>N° de téléphone : {user.phone_number}</p>
                        <span>Adresse pour le diagnostic à domicile:</span>
                        <span>{user.adress}</span>
                                <span>{user.zipcode}</span>
                                <span>{user.city_name}</span>
                        <span>Rappel : En cas de réparation avec pièce(s), un devis sera établi et vous sera envoyé par
                        email à l’adresse suivante :</span>
                        <p>{user.email}</p>
                        <p>pour approbation et règlement</p>
                        <AcceptCGV missingValues={props.missingValues}/>
                        <p>En tenant compte des informations renseignées, cette intervention 909 vous sera
                        facturée : {repairFile.price}€</p>
                    </div>
                }
                {(intervention_type == 'Atelier' || intervention_type == 'remote') &&
                    <div className='overviewDiv' style={{width:'80%', marginLeft:'10%',flexDirection:'column', padding:'5px'}}>
                        <p>Madame, Monsieur {user.last_name}</p>
                        <p>Vous avez choisi {intervention_type == 'Atelier' ?
                                <span>une prise en charge en atelier, dans l'agence de {repairFile.nearestAgency.name} pour : </span> :
                                <span>un diagnostic à distance</span>
                            }
                        </p>
                        <span>Appareil : {repairFile.device.name}</span>
                        <span>De marque : {repairFile.brand}</span>
                        {repairFile.serialNumber &&
                            <span>N° de série : {repairFile.serialNumber}</span>
                        }
                        <span>N’étant plus sous garantie</span>
                        <p>Pour le problème déclaré suivant : {repairFile.issue.issue}</p>
                        {repairFile.comment &&
                            <span>Informations complémentaires : {repairFile.comment}</span>
                        }
                        <p>N° de téléphone : {user.phone_number}</p>
                        <span>Rappel : En cas de réparation avec pièce(s), un devis sera établi et vous sera envoyé par
                        email à l’adresse suivante :</span>
                        <p>{user.email}</p>
                        <p>pour approbation et règlement</p>
                        <AcceptCGV missingValues={props.missingValues}/>
                        <p>En tenant compte des informations renseignées, cette intervention 909 vous sera
                        facturée : {repairFile.price}€</p>
                    </div>
                }
            </div>
            <div className='nextStepBtnContainer'>
                {!repairFileSaveTrouble &&
                    <span className='warning'>L'enregistrement de votre dossier s'est mal passé. Merci de réessayer, en cas de persistence du problème merci de contacter 909 par téléphone.</span>
                }
                <button className="prevStepBtn modalButton" onClick={props.handlePrevClick}>Précédent</button>
                <button className="nextStepBtn modalButton" onClick={saveRepairFile}>Suivant</button>
            </div>
        </div>
    )
}

export default RepairFileIntervention