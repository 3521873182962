import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRepairFile } from '../../features/repairFileSlice';
import AdviceModal from '../../components/AdviceModal';
import { store } from '../../store';

import QuestionMarkIcon from '../../assets/images/question_mark_icon.png'
import UploadAndDisplayImage from "../../hooks/uploadAndDisplayImage";
import Textarea from '@mui/joy/Textarea';

import Calendar from "react-calendar"
import 'react-calendar/dist/Calendar.css';
import { FormControl, FormHelperText } from "@mui/joy";

const RepairFileDatas = (props) => {
    const { user } = useSelector((store) => store.user)
    const otherAdvice = { 
        id: 999, 
        device_id: 999, 
        issue: 'J\'ai un autre problème', 
        solution: 'Merci de préciser votre panne dans le commentaire'
    }
    const { repairFile } = useSelector((store) => store.repairFile)
    const [ advices, setAdvices ] = useState([])
    const [ serialNumber, setSerialNumber ] = useState('')
    const [ comment, setComment ] = useState('')
    const [ issue, setIssue ] = useState('')
    const [ showSNAdvice, setShowSNAdvice ] = useState(false)
    const [ adverb, setAdverb ] = useState()
    const [ adviceModalHeader, setAdviceModalHeader] = useState('')
    const { device } = repairFile
    const dispatch = useDispatch()
    const [ phoneAppointment, setPhoneAppointment] = useState('')
    const { intervention_type } = repairFile.selectedOffer
    const [ selectedTimeSlotRef, setSelectedTimeSlotRef ] = useState()
    const [ holidays, setHolidays ] = useState([])
    const [ year ,setYear ] = useState(new Date().toLocaleString('fr-FR').split(' ')[0].split('/')[2])
    const planning = [
        {id:1, hour: 9},
        {id:2, hour: 9.5},
        {id:3, hour: 10},
        {id:4, hour: 10.5},
        {id:5, hour: 11}, 
        {id:6, hour: 11.5},
        {id:7, hour: 12},
        {id:8, hour: 14},
        {id:9, hour: 14.5},
        {id:10, hour: 15},
        {id:11, hour: 15.5},
        {id:12, hour: 16},
        {id:13, hour: 16.5},
        {id:14, hour: 17}
    ]
    const [ dayPlanning, setDayPlanning ] = useState(planning)

    useEffect( ()=> {
        async function fetchData() {
            await fetch(global.constantes.proxy.base + '/advices/' + device.id)
            .then( async response =>{
                await response.json()
                .then(json => {
                    if(json){
                        let advicesJson = []
                        json.forEach(advice => {
                            advicesJson.push(advice)
                        })
                        advicesJson.push(otherAdvice)
                        setAdvices(advicesJson)
                        dispatch(setRepairFile({advices: advicesJson}))
                    }
                })
            })
        }
        // renouvellement du useEffect conditionné à year dans tableau parametre
        // year remis à jour quand clique sur calendrier (voir tileDisabled)
        async function fetchHolidays() {
            const url = `https://calendrier.api.gouv.fr/jours-feries/metropole/${year}.json`;
            await fetch( url )
            .then(async response =>{
                await response.json()
                .then( async json => {
                    Object.keys(json).map((key, index) => {
                        let holiday = key
                        holiday = holiday.split('-')
                        let holiDate = `${holiday[2]}/${holiday[1]}/${holiday[0]}`
                        setHolidays( holidays => [...holidays, holiDate])
                    })
                })
            })
        }
        const { serialNumber, comment, issue, advices, selectedTimeSlotRef, phoneAppointment, dayPlanning, selectedOffer } = store.getState().repairFile.repairFile;
        if(serialNumber){
            setSerialNumber(serialNumber)
        }
        if(comment){
            setComment(comment)
        }
        if(advices){
            if(issue){
                setIssue(issue)
                const newList = []
                advices.map((item) => { 
                    if (item.id === issue.id) {
                        const updatedItem = {...item, selected: true}
                        newList.push(updatedItem)
                    }
                    else{
                        const updatedItem = {...item, selected: false}
                        newList.push(updatedItem)
                    }
                });
                setAdvices(newList)
            }else{
                setAdvices(advices)
            }
        }else{
            fetchData()
        }
        if(selectedOffer.intervention_type == 'Atelier'){
            dispatch(setRepairFile({phoneAppointment: ''}))
            dispatch(setRepairFile({selectedTimeSlotRef: ''}))
        }
        if(phoneAppointment){
            let dateFromString = phoneAppointment.split('/')
            setPhoneAppointment(new Date(dateFromString[2],dateFromString[1]-1,dateFromString[0]))
        }
        if(!dayPlanning){
            setDayPlanning(planning)
        }else{
            setDayPlanning(dayPlanning)
        }
        if(selectedTimeSlotRef){
            setSelectedTimeSlotRef(selectedTimeSlotRef)
            const newList = []
            dayPlanning.map((item) => { 
                if (item.id === selectedTimeSlotRef.id) {
                    const updatedItem = {...item, selected: true}
                    newList.push(updatedItem)
                }
                else{
                    const updatedItem = {...item, selected: false}
                    newList.push(updatedItem)
                }
            });
            setDayPlanning(newList)
        }
        chooseAdverb()
        fetchHolidays()
        // eslint-disable-next-line
    }, [year])

    const handleIssueClicked = (item) => {
        if(item.id == 999){
            setAdviceModalHeader('Afin de mieux vous répondre...')
        }else{
            setAdviceModalHeader('Avant de continuer, avez-vous vérifié... ?')
        }
        setIssue({id:item.id, device_id:item.device_id, issue:item.issue, solution:item.solution})
        dispatch(setRepairFile({issue: issue}))
        setAdvices(setSelectedIcon(advices, item.id))
    }

    const handleDayPlanningClicked = (item) => {
        setSelectedTimeSlotRef(item)
        dispatch(setRepairFile({selectedTimeSlotRef: item}))
        setDayPlanning(setSelectedIcon(dayPlanning, item.id))
    }

    const setSelectedIcon = (list, id) => {
        const newList = list.map((item) => { 
            if (item.id === id) {
                const updatedItem = {...item, selected: true}
                return updatedItem;
            }
            else{
                const updatedItem = {...item, selected: false}
                return updatedItem;
            }
        });
        return newList
    }

    const handleSerialNumber = serialNumber => {
        if(serialNumber){
            setSerialNumber(serialNumber)
            dispatch(setRepairFile({serialNumber: serialNumber}))
        }else{
            setSerialNumber('')
            dispatch(setRepairFile({serialNumber: ''}))
        }
    }

    const toggleSNAdvice = () => {
        let toggle = !showSNAdvice
        setShowSNAdvice(toggle)
    }

    const chooseAdverb = () => {
        const under = [1, 2, 8]//sous
        const behind = [3, 6, 7, 9, 11, 12, 13]//derrière
        const inNote = [4, 5, 10]//dans la notice
        if(under.includes(device.id)){
            setAdverb('sous votre')
        }
        if(behind.includes(device.id)){
            setAdverb('derrière votre')
        }
        if(inNote.includes(device.id)){
            setAdverb('dans la notice de votre')
        }
    }

    const handleComment = comment => {
        if(comment){
            setComment(comment)
            dispatch(setRepairFile({comment: comment}))
        }else{
            setComment('')
            dispatch(setRepairFile({comment: ''}))
        }
    }

    const handleAppointment = async (e) => {
        let date = e.toLocaleDateString('fr-FR')
        setPhoneAppointment(e)
        dispatch(setRepairFile({phoneAppointment: date}))
        await fetch(global.constantes.proxy.base + '/remoteDiagPlanning',{
            method: 'POST',
            body: JSON.stringify({date: date}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${user.token}`
            }
        })
        .then( async response =>{
            await response.json()
            .then(async json => {
                if(json.length > 0){
                    let slots = []
                    json.forEach(slot => {
                        slots.push(Number(slot.time_slot))
                    })
                    if(slots.length > 0){
                        var result = planning.filter( x => !slots.includes(x.hour));
                        if(selectedTimeSlotRef){
                            setDayPlanning(setSelectedIcon(result, selectedTimeSlotRef.id))
                        }else{
                            setDayPlanning(result)
                        }
                        dispatch(setRepairFile({dayPlanning: result}))
                    }
                }else{
                    if(selectedTimeSlotRef){
                        setDayPlanning(setSelectedIcon(planning, selectedTimeSlotRef.id))
                    }else{
                        setDayPlanning(planning)
                    }
                    dispatch(setRepairFile({dayPlanning: planning}))
                }
            })
        })
    }

    const tileDisabled = ({ date }) => {
        setYear(date.toLocaleString('fr-FR').split(' ')[0].split('/')[2])
        if(date < new Date() || date.getDay() == 0 || date.getDay() == 6 || holidays.includes(date.toLocaleString('fr-FR').split(' ')[0])){
            return true
        }
        let currentDate = new Date();
        // Vérifie si la date est aujourd'hui et qu'il est déjà passé 17 heures
        if (
            date.getDate() === currentDate.getDate() + 1 &&
            currentDate.getHours() >= 17 &&
            currentDate.getDay() >= 1 && // Lundi
            currentDate.getDay() <= 4    // Jeudi
        ) {
            return true; // Désactive la tuile pour le lendemain après 18 heures du lundi au jeudi
        }
        // Vérifie si la date est aujourd'hui et qu'il est déjà passé 18 heures
        // et que la date actuelle est un vendredi (5)
        if ( date.getDate() === currentDate.getDate() + 3 &&
            currentDate.getHours() >= 17 &&
            currentDate.getDay() === 5    // Vendredi
        ) {
            return true; // Désactive la tuile pour le lundi suivant après 18 heures le vendredi
        }
        if ( date.getDate() === currentDate.getDate() + 2 &&
            currentDate.getHours() >= 17 &&
            currentDate.getDay() === 6) {
                return true; // Désactive la tuile pour le lundi suivant après 18 heures le vendredi
        }
        if ( date.getDate() === currentDate.getDate() + 1 &&
            currentDate.getHours() >= 17 &&
            currentDate.getDay() === 7) {
            return true; // Désactive la tuile pour le lundi suivant après 18 heures le vendredi
        }
     }

    const setTimeSlot = timeSlot => {
        // is float    
        if(!Number.isInteger(timeSlot)) {
            return {start: timeSlot.toString().split('.')[0] + ' h 30', end: (timeSlot + 0.5) + ' h'}
        }else{
            return {start: timeSlot + ' h', end: (timeSlot + 0.5).toString().split('.')[0] + ' h 30'}
        }
    }

    return (
        <div className="repairFileStep">
            <div className="repairFileStepHeader">
                <p style={{marginLeft: '4vw'}}>{props.header}</p>
            </div>
            <div className='adviceContainer'>
            {intervention_type && (intervention_type == 'Domicile' || intervention_type == 'remote') &&
                <div className="overviewDiv">
                    <label style={{fontSize:'1.2em', color:'#3f2564'}}>
                        Vous avez choisi { intervention_type == 'Domicile' ?
                            <span>le diagnostic à Domicile</span> :
                            <span>le diagnostic à distance</span>
                        } :
                    </label>
                    <span className="datasText">
                    &emsp; Pour réaliser cette prestation, un technicien 909 va vous contacter
                        { intervention_type == 'Domicile' ?
                            <span> pour confirmer le
                            diagnostic et convenir avec vous d’un RDV pour l’intervention à votre domicile. </span> :
                            <span> pour confirmer le
                            diagnostic et convenir avec vous d’un RDV pour l’intervention à votre domicile. <br/>Pour cet appel, choisissez ci-dessous le créneau qui vous convient le mieux.</span>
                        }
                        <br/><br/><p style={{textDecoration:'underline'}}>A savoir : </p>&emsp;Si lors de cet appel vous être devant votre appareil ce n’est que mieux, sinon veillez à compléter de façon
                        complète et précise les informations suivantes
                    </span>
                    {intervention_type && intervention_type == 'remote' &&
                        <div className="calendarPlanningContainer">
                            <div className="calendarContainer">
                                <Calendar
                                // faire un handle RDVDate qui setState & dispatche dans Redux
                                    onChange={handleAppointment}
                                    value={phoneAppointment}
                                    tileDisabled={tileDisabled}
                                    dateFormat="d MMM yyyy"
                                />
                            </div>
                            <div className="dayPlanningContainer">
                            { phoneAppointment && dayPlanning && dayPlanning.map((item, index)=>(
                                dayPlanning[index].hour != 17 && dayPlanning[index].hour != 12 &&
                                    <div 
                                        key={index} 
                                        className='dayPlanningDiv' 
                                        style={ 
                                            item.selected ? 
                                            {backgroundColor: '#7c5baa', color: '#fff'} : 
                                            {backgroundColor: '#fff'}} 
                                        onClick={()=>{handleDayPlanningClicked(item)}}
                                    >
                                        <span>{setTimeSlot(dayPlanning[index].hour).start} - {setTimeSlot(dayPlanning[index].hour).end}</span> 
                                    </div>
                                ))
                            }
                            </div>
                        </div>
                    }
                </div>
            }
            <div style={{width:'80%', marginLeft:'10%', marginBottom:'2vh'}}>
            {props.missingValues.length > 0 && props.missingValues.includes('phoneAppointment') && !phoneAppointment &&
                    <span className='warning center' style={{marginTop: '1vh', fontSize: '1em'}}>Merci de choisir une date</span>
                }
            {props.missingValues.length > 0 && props.missingValues.includes('selectedTimeSlotRef') && !selectedTimeSlotRef &&
                    <span className='warning center' style={{marginTop: '1vh', fontSize: '1em'}}>Merci de choisir un créneau horaire</span>
                }
            </div>
            <div className="adviceBtnsContainer">
            {advices && advices.map((item, index)=>(
                        <div 
                            key={index} 
                            className='adviceDiv' 
                            style={ 
                                item.selected ? 
                                {backgroundColor: '#b5f3ca'} : 
                                {backgroundColor: 'rgba(255,255,255,0)'}} 
                            onClick={()=>{handleIssueClicked(item)}}
                        >
                            <AdviceModal 
                                header={adviceModalHeader}
                                title={item.issue} 
                                advice={item.solution}/>
                        </div>
                    ))
                }
            </div>
            </div>
            {props.missingValues.length > 0 && props.missingValues.includes('issue') && !issue &&
                    <span className='warning center' style={{marginTop: '2vh', fontSize: '1em'}}>Merci de choisir une panne</span>
                }
            <div className="serialNumber">
                <label>Numéro de série</label>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <input
                        type="text" 
                        value={serialNumber}
                        placeholder='ex : 111111112223dddsd224' 
                        onChange={(e) => {handleSerialNumber(e.target.value)}}
                    />
                    <img 
                        className="questionMarkBtn"
                        src={QuestionMarkIcon}
                        alt="YT"
                        onClick={()=>{toggleSNAdvice()}}
                    />
                </div>
                {showSNAdvice &&
                    <span style={{fontSize: '0.8em', fontStyle:'italic'}}>Vous pouvez trouver ce numéro {adverb} {device.name}</span>
                }
            </div>
            {props.missingValues.length > 0 && props.missingValues.includes('serialNumber') && !serialNumber &&
                <span className='warning center' style={{marginTop: '2vh', fontSize: '1em'}}>Merci de saisir le numéro de série de l'appareil</span>
            } 
            <UploadAndDisplayImage/>
            <FormControl style={{width:'50%', marginLeft:'25%', marginTop:'3%'}}>
                <label style={{marginBottom:'1vh'}}>Commentaire</label>
                <Textarea 
                    placeholder="Passez-moi un coup de fil avant d'arriver..." 
                    value={comment}
                    onChange={(e) => {handleComment(e.target.value)}}
                    minRows={2} />
                <FormHelperText>Si vous avez quelques précisions à nous apporter...</FormHelperText>
            </FormControl>
            <div className='nextStepBtnContainer'>
                <button className="prevStepBtn modalButton" onClick={props.handlePrevClick}>Précédent</button>
                <button className="nextStepBtn modalButton" onClick={props.handleNextClick}>Suivant</button>
            </div>
        </div>
    )
}

export default RepairFileDatas