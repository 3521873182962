import { Parallax, ParallaxLayer } from "@react-spring/parallax"
import { useRef } from "react"
import FranceSimpleMap from "../components/FranceSimpleMap"

import LyonImg from '../assets/images/equipe-lyon-1024x682.jpg'
import ParisImg from '../assets/images/909-equipe-paris-1024x683.jpg'
import ToursImg from '../assets/images/equipe-909-tours-1024x768.jpg'
import StrasbourgImg from '../assets/images/equipe-909-strasbourg-1024x765.jpg'
import RouenImg from '../assets/images/equipe-rouennaise-1024x576.jpg'
import ToulouseImg from '../assets/images/equipe-toulouse-1024x682.jpg'

const About = () => {
    const parallax = useRef()

    return (
        <div className="about">
            <Parallax 
                pages={6} 
                ref={parallax}
                >
                <ParallaxLayer 
                    offset={0} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(1)}
                    style={{backgroundColor:"#3f2564"}}>
                    <div style={{width: '100%', height: '100%'}}>
                        <div className="aboutPage1">
                            <div style={{flexDirection: 'column', width: '50%', marginLeft: '5%', marginTop: '3%'}}>
                                <div className="page5MiniTitle" style={{fontSize: '1.8em',color: '#fff'}}>Qui est 909 ?</div>  
                                <div className="page5MiniText" style={{color: '#fff', marginTop: '3%'}}>
                                Né du rapprochement de plusieurs sociétés spécialisées dans le domaine de la réparation et
                                cumulant plus de 30 années d’expérience, 909 est le réparateur de tous vos appareils
                                électroniques et électroménagers.
                                909, partenaire privilégié des fabricants met son savoir-faire au service de chaque français à
                                travers ses offres d’assistance et de réparation partout en France.
                                909, ce sont des dizaines de milliers de produits réparés chaque année, plus de 10 000 m2
                                d’espace logistique, un chiffre d’affaires de plus de 8 M€ et une centaine de collaborateurs
                                prêts à vous accompagner à distance, à votre domicile ou dans l’un de nos 7 ateliers en France.
                                </div>   
                                <div className="page5MiniText" style={{color: '#fff', marginTop: '3%'}}>
                                909 est le nouveau réflexe économique, écologique et efficace déjà adopté par des milliers de
                                français au quotidien.
                                </div>                  
                            </div>
                            <div style={{width: '10%'}}>
                                <img style={{width: '350px', zIndex: 2}} src={require('../../src/assets/images/909-qui-sommes-nous.png')} alt=""/>
                            </div>
                        </div>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer 
                    offset={1} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(2)}
                    style={{backgroundColor: "#fff"}}
                    >
                    <div style={{display: 'flex', flexDirection:'column', width: '100%', height: '100%', alignItems: 'center'}}>
                        <div className="page3TitleContainer">
                            <span style={{color: '#3f2564'}}>Notre réseau national </span><span style={{textIndent: '8px', color: '#7dcb97'}}> d'agences</span>
                        </div>
                        <FranceSimpleMap/>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer 
                    offset={2} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(3)}
                    style={{backgroundColor: "#3f2564"}}>
                        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
                            <div style={{height: '8%', width: '100%', display: 'flex',justifyContent: 'center'}}>
                                <span className="page5Title" style={{color: '#fff', marginTop: '15px'}}>Nos atouts</span>
                            </div>
                            <span style={{width: '100%', display: 'flex',justifyContent: 'center', color: '#3f2564'}}>Nous sommes là pour régler vos soucis du quotidien avec vos appareils électroniques et électroménagers.</span>
                            <div className="teams">
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '270px', height: '30%', margin: '2%'}}>
                                    <img className="speedLogo" style={{position: 'absolute', zIndex: 2}} src={require('../../src/assets/images/eco-responsable.svg').default} alt=""/>
                                    <img className="speedLogo" style={{position: 'absolute', zIndex: 1, marginTop: '2%'}} src={require('../../src/assets/images/cartouche-vertdeau-909.svg').default} alt=""/>
                                    <span style={{marginTop: '130px', fontSize: '0.9em', color: '#fff'}}>Une entreprise éco-responsable</span>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '270px', height: '30%', margin: '2%'}}>
                                    <img className="speedLogo" style={{position: 'absolute', zIndex: 2}} src={require('../../src/assets/images/experience.svg').default} alt=""/>
                                    <img className="speedLogo" style={{position: 'absolute', zIndex: 1, marginTop: '2%'}} src={require('../../src/assets/images/cartouche-vertdeau-909.svg').default} alt=""/>
                                    <span style={{marginTop: '130px', fontSize: '0.9em', color: '#fff'}}>30 ans d’expérience dans la réparation de produits électroniques et électroménagers</span>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '270px', height: '30%', margin: '2%'}}>
                                    <img className="speedLogo" style={{position: 'absolute', zIndex: 2}} src={require('../../src/assets/images/expertise-multimarques.svg').default} alt=""/>
                                    <img className="speedLogo" style={{position: 'absolute', zIndex: 1, marginTop: '2%'}} src={require('../../src/assets/images/cartouche-vertdeau-909.svg').default} alt=""/>
                                    <span style={{marginTop: '130px', fontSize: '0.9em', color: '#fff'}}>Une expertise multimarques</span>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '270px', height: '30%', margin: '2%'}}>
                                    <img className="speedLogo" style={{position: 'absolute', zIndex: 2}} src={require('../../src/assets/images/collaborations.svg').default} alt=""/>
                                    <img className="speedLogo" style={{position: 'absolute', zIndex: 1, marginTop: '2%'}} src={require('../../src/assets/images/cartouche-vertdeau-909.svg').default} alt=""/>
                                    <span style={{marginTop: '130px', fontSize: '0.9em', color: '#fff'}}>10 ans de collaboration avec les plus grands fabricants : Samsung, Panasonic, Hisense…</span>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '270px', height: '30%', margin: '2%'}}>
                                    <img className="speedLogo" style={{position: 'absolute', zIndex: 2}} src={require('../../src/assets/images/techniciens-exp.svg').default} alt=""/>
                                    <img className="speedLogo" style={{position: 'absolute', zIndex: 1, marginTop: '2%'}} src={require('../../src/assets/images/cartouche-vertdeau-909.svg').default} alt=""/>
                                    <span style={{marginTop: '130px', fontSize: '0.9em', color: '#fff'}}>Nos réparateurs à domicile ont 13 ans d’expérience en moyenne</span>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '270px', height: '30%', margin: '2%'}}>
                                    <img className="speedLogo" style={{position: 'absolute', zIndex: 2}} src={require('../../src/assets/images/produits-repares.svg').default} alt=""/>
                                    <img className="speedLogo" style={{position: 'absolute', zIndex: 1, marginTop: '2%'}} src={require('../../src/assets/images/cartouche-vertdeau-909.svg').default} alt=""/>
                                    <span style={{marginTop: '130px', fontSize: '0.9em', color: '#fff'}}>90% des produits sont réparés dès la première intervention</span>
                                </div>
                            </div>
                        </div>
                </ParallaxLayer>
                <ParallaxLayer 
                    offset={3} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(4)}
                    style={{backgroundColor: "#fff"}}>
                        <div style={{display: 'flex', flexDirection: 'column', width: '80%', marginLeft: '10%', height:'100%', alignItems: 'center', fontSize: '0.9em'}}>
                            <div style={{height: '8%', width: '95%', marginLeft: '20%'}}>
                                <span className="page5Title">Comment faire du</span><span className="page5Title" style={{color: '#7dcb97'}}> neuf sans neuf ?</span>
                            </div>
                            <div style={{
                                display: 'flex', 
                                flexDirection: 'row',
                                width: '80%',
                                marginLeft: '10%'
                                }}>                            
                                <img className="speedLogo" style={{marginLeft: 28, position: 'absolute', zIndex: 2}} src={require('../../src/assets/images/valeur-proximite.svg').default} alt=""/>
                                <img className="speedLogo" style={{marginTop: 25}} src={require('../../src/assets/images/cartouche-vertdeau-909.svg').default} alt=""/>
                                <div className="doItMySelfText" style={{flexDirection: 'column', width: '70%'}}>
                                    <div className="page5MiniTitle">Proximité</div>  
                                    <div className="page5MiniText">
                                    Nous sommes toujours là pour vous, flexibles et prêts à intervenir à votre domicile ou à distance. Nous proposons des solutions de réparation ajustées et personnalisées, que vous soyez novice ou bricoleur.
                                    </div>                     
                                </div>

                            </div>
                            <div style={{
                                display: 'flex', 
                                flexDirection: 'row',
                                marginTop: '3%',
                                width: '80%',
                                marginLeft: '10%'
                                }}>
                                <div className="doItMySelfText" style={{flexDirection: 'column', width: '70%', marginLeft: '5%', marginTop: '3%'}}>
                                    <div className="page5MiniTitle">Expertise</div>  
                                    <div className="page5MiniText">
                                    Avec plus de 30 ans d’expérience, nous vous offrons un service sur mesure grâce à nos réparateurs à domicile, formés aux dernières technologies. Avec 13 ans d’expertise en moyenne, nos techniciens s’engagent à rendre votre appareil fonctionnel.
                                    </div>                     
                                </div>                            
                                <img className="speedLogo" style={{marginLeft: '50%', position: 'absolute', zIndex: 2}} src={require('../../src/assets/images/valeur-expertise.svg').default} alt=""/>
                                <img className="speedLogo" style={{marginLeft: '50%', position: 'absolute', zIndex: 1, marginTop: '5%'}} src={require('../../src/assets/images/cartouche-vertdeau-909.svg').default} alt=""/>
                            </div>
                            <div style={{
                                display: 'flex', 
                                flexDirection: 'row',
                                marginTop: '6%',
                                width: '80%',
                                marginLeft: '10%'
                                }}>                            
                                <img className="speedLogo" style={{marginLeft: 28, position: 'absolute', zIndex: 2}} src={require('../../src/assets/images/valeur-ecologie.svg').default} alt=""/>
                                <img className="speedLogo" style={{marginTop: 25}} src={require('../../src/assets/images/cartouche-vertdeau-909.svg').default} alt=""/>
                                <div className="doItMySelfText" style={{flexDirection: 'column', width: '70%', marginTop: '3%'}}>
                                    <div className="page5MiniTitle">Ecologie</div>  
                                    <div className="page5MiniText">
                                        Nous prenons à coeur de prolonger la durée de vie des produits et de lutter contre le gaspillage via des solutions écoresponsables et plus économiques. Afin d’avoir une approche écologique, nous proposons différentes prestations de réparation. Un moyen d’avoir du neuf sans neuf !
                                    </div>                
                                </div>
                            </div>
                        </div>
                </ParallaxLayer>
                <ParallaxLayer 
                    offset={4} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(5)}
                    style={{backgroundColor: "#3f2564"}}>
                        <div style={{display: 'flex', flexDirection: 'column', width: '80%', marginLeft: '10%', height:'90%', alignItems: 'center'}}>
                            <div className="partnerDiv1">
                                <span style={{fontWeight: 'bold', fontSize: '2.1em', color: '#fff', marginRight: '6px'}}>
                                    909, le service de réparation utilisé
                                    et agréé par les plus grandes marques
                                </span>
                            </div>
                            <div className="partnerDiv2">
                                    <span style={{color: '#fff', marginRight: '6px'}}>
                                        Nous travaillons au nom de Samsung, Panasonic ou encore Hisense. 
                                        Ils font appel à nous pour réparer à votre domicile vos appareils encore 
                                        sous garantie : TV, lave-linge, réfrigérateur, sèche-linge, micro-onde, 
                                        four, plaques de cuisson… Nos partenaires nous font confiance. Pourquoi pas vous ? 
                                    </span>                    
                                </div>
                            <div className="partnerDiv3">
                                <img style={{height: '65px'}} src={require('../assets/images/logo_samsung.png')} alt=""/>
                                <img style={{height: '65px'}} src={require('../assets/images/Vestel-logo-1.png')} alt=""/>
                                {/* <img style={{height: '48px'}} src={require('../assets/images/Logo_Hisense_France.png')} alt=""/> */}
                            </div>
                        </div>
                </ParallaxLayer>
                <ParallaxLayer 
                    offset={5} 
                    className="center" 
                    onClick={() => parallax.current.scrollTo(0)}
                    style={{
                        backgroundColor: 'white',
                        cursor: 'pointer'
                    }}
                    >
                    <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
                        <div style={{height: '8%', width: '100%', display: 'flex',justifyContent: 'center'}}>
                            <span className="page5Title">Notre équipe</span><span className="page5Title" style={{color: '#7dcb97'}}> au complet</span>
                        </div>
                        <span className="aboutPage6Subtitle">Nous sommes là pour régler vos soucis du quotidien avec vos appareils électroniques et électroménagers.</span>
                        <div className="teams">
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '270px', margin: '2%'}}>
                                <img 
                                    src={ParisImg}
                                    style={{width: '100%'}}
                                />
                                <span style={{marginTop: '10px', fontSize: '0.9em'}}>Agence 909 – Paris</span>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '270px', margin: '2%'}}>
                                <img 
                                    src={ToulouseImg}
                                    style={{width: '100%'}}
                                />
                                <span style={{marginTop: '10px', fontSize: '0.9em'}}>Agence 909 – Toulouse & Bordeaux</span>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '270px', margin: '2%'}}>
                                <img 
                                    src={LyonImg}
                                    style={{width: '100%'}}
                                />
                                <span style={{marginTop: '10px', fontSize: '0.9em'}}>Agence 909 – Lyon</span>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '270px', margin: '2%'}}>
                                <img 
                                    src={ToursImg}
                                    style={{width: '100%'}}
                                />
                                <span style={{marginTop: '10px', fontSize: '0.9em'}}>Agence 909 – Tours</span>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '270px', margin: '2%'}}>
                                <img 
                                    src={RouenImg}
                                    style={{width: '100%'}}
                                />
                                <span style={{marginTop: '10px', fontSize: '0.9em'}}>Agence 909 – Rouen</span>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '270px', margin: '2%'}}>
                                <img 
                                    src={StrasbourgImg}
                                    style={{width: '100%'}}
                                />
                                <span style={{marginTop: '10px', fontSize: '0.9em'}}>Agence 909 – Strasbourg</span>
                            </div>
                        </div>
                    </div>
                </ParallaxLayer>
            </Parallax>
        </div>
    )
}

export default About