import { useDispatch, useSelector } from "react-redux"
import FileLogModal from "../../components/FileLogModal"
import { useState } from "react"
import { useEffect } from "react"
import { setRepairFileSelected } from "../../features/repairFileSlice"
import MagnifyingGlassIcon from '../../assets/images/magnifying-glass-white.png'
import CrossIcon from '../../assets/images/cross-icon.png'

const AdminRecovery = () => {
    const { repairFilesLog } = useSelector((store) => store.repairFileLog)
    const { repairFileSelected } = useSelector((store) => store.repairFile)
    const [ files, setFiles] = useState()
    const [ listOrder, setListOrder ] = useState(false)
    const [ lastName, setLastName ] = useState('')
    const [ listFilter, setListFilter ] = useState(true)
    const dispatch = useDispatch()

    useEffect(() => {
        setFiles(repairFilesLog)
        if(files){
            console.log(files);
            if(repairFileSelected){
                const newList = files.map((item) => { 
                    if (item.id === repairFileSelected) {
                        const updatedItem = {...item, selected: true}
                        return updatedItem;
                    }
                    else{
                        const updatedItem = {...item, selected: false}
                        return updatedItem;
                    }
                });
                setFiles(newList);
            }
        }
        // eslint-disable-next-line
    }, [repairFilesLog])

    const handleFileClicked = (file) => {
        dispatch(setRepairFileSelected(file.id))
        setSelectedFile(file.id)
    }

    const setSelectedFile = (id) => {
        const newList = files.map((item) => { 
            if (item.id === id) {
                const updatedItem = {...item, selected: true}
                return updatedItem;
            }
            else{
                const updatedItem = {...item, selected: false}
                return updatedItem;
            }
        });
        setFiles(newList);
    }

    const setPhoneAppointment = (appointment) => {
        const date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        const date1 = new Date(`${month}/${day}/${year}`)
        const rdvDate = appointment.split('/')
        const date2 = new Date(`${rdvDate[1]}/${rdvDate[0]}/${rdvDate[2]}`);
        const isPast = date2 < date1
        if(isPast){
            return ['','']
        }else{
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
            if(diffDays > 3){
                return [appointment, '#72f692']
            }
            if(diffDays <= 3 && diffDays > 0){
                return [appointment, '#f6ab72']
            }
            if(diffDays == 0){
                return [appointment, '#f67272']
            }
        }
    }

    const handleSortList = () => {
        setListOrder(!listOrder)
        if(listOrder){
            setFiles((files) => {
                const filesToSort = [...files];
                filesToSort.sort((a, b) => Number(a.id) - Number(b.id));
                return filesToSort;
            })
        }else{
            setFiles((files) => {
                const filesToSort = [...files];
                filesToSort.sort((a, b) => Number(b.id) - Number(a.id));
                return filesToSort;
            })
        }
    }

    const filterOnLastname = () => {
        if(lastName != ''){
            setListFilter(!listFilter)
            if(listFilter){
                setFiles(repairFilesLog.filter(item => item.last_name.toLowerCase() == lastName.toLowerCase()))
            }else{
                setFiles(repairFilesLog)
                setLastName('')
            }
        }
    }

    return(
        <>
            <div style={{width: '100%',height: '10%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <button 
                    className="modalButton"
                    style={{marginLeft: '15%', marginTop: '2%'}}
                    onClick={handleSortList}
                >Trier par ordre {listOrder ? 'croissant' : 'décroissant'}</button>
                <input 
                    type="text" 
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Filtrer par nom de famille"
                    style={{width: '185px', height: '40px',marginLeft: '5%', marginTop: '3%'}}
                />
                <button
                    style={{
                        width:'46px', 
                        height:'40px', 
                        // position:'absolute',
                        right:'0', 
                        marginTop:'2%', 
                        marginRight:'5px', 
                        cursor:'pointer',
                        backgroundColor:'rgba(15,85,255,0.6)',
                        borderStyle:'none', borderRadius:'6px'
                    }}
                    onClick={(() => filterOnLastname())}
                >
                <img src={listFilter ? MagnifyingGlassIcon : CrossIcon} alt="MagnifyingGlassIcon" style={{width:'90%', height:'90%', padding:'2px'}}/>
                </button>         
            </div>
            {files && !listFilter && files.length == 0 &&
                <span style={{display: 'flex', width: '100%', justifyContent: 'center'}}>Pas de résultat pour <b style={{marginLeft: '8px'}}>{lastName}</b></span>
            }
            <div className="adminContainer">
                {files && files.map((file, index)=>(
                    <div 
                        key={index} 
                        onClick={()=>{handleFileClicked(file)}}>
                            <FileLogModal
                                selected={file.selected}
                                id={file.id}
                                erpFileId={file.erp_file_id}
                                firstName={file.first_name} 
                                lastName={file.last_name}
                                phoneAppointment={file.phone_appointment ? setPhoneAppointment(file.phone_appointment) : ''}
                                timeSlot={file.time_slot}
                                createdAt={file.created_at}
                            />
                    </div>
                    ))
                }
            </div>
        </>
    )
}

export default AdminRecovery