import { useSelector } from "react-redux"
import JobModal from "../components/JobModal";
import { useState } from "react";
import UploadFile from "../hooks/uploadFile";
import ReCAPTCHA from "react-google-recaptcha";
import checkImg from '../assets/images/checkImg.png'
import { useEffect } from "react";

// Google reCAPTCHA
// siteKey = 6Lc1ERcpAAAAABoxEL59-UkdlYnFc8Dny9banEdf
// secretKey = 6Lc1ERcpAAAAADKHF-aKEtosBkmzyVkEY8-Q-v7p

const Careers = () => {
    const { jobs } = useSelector((store) => store.jobs)
    const [text, setText] = useState('')
    const [ isSubmitted, setIsSubmitted ] = useState(false)
    const [ fileName, setFileName ] = useState(false)
    const [ deleteFile, setDeleteFile ] = useState(false)
    const [ isFileDeleted, setIsFileDeleted ] = useState(false)
    const [ captchaValue, setCaptchaValue ] = useState(null)
    const [ mailSent, setMailSent ] = useState(false)

    useEffect(()=>{
        if(isFileDeleted){
            setMailSent(true)
        }
    },[isFileDeleted])

    const handleSubmit = async () => {
        setIsSubmitted(true)
        // si fileName et si texte -> mail avec comme attachment le fileName vers emploi@909services.com
        if(text && fileName && captchaValue != null){
            console.log(fileName);
            let mailDatas = {msg: text, cv: fileName}
            await fetch(global.constantes.proxy.base + '/jobApplierMail',{
                method: 'POST',
                body: JSON.stringify(mailDatas),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then( async response =>{
                await response.json()
                .then( json => {
                    console.log(json);
                    if(json.success){
                        setDeleteFile(true)
                    }
                })
            })
        }
    }

    const onChangeCaptcha = (value) => {
        console.log("Captcha value:", value);
        setCaptchaValue(value)
      }

    return (
        <div style={{display: 'flex', flexDirection: 'column', width: '80%', height: '100%', marginLeft: '12%'}}>
            <label style={{display: 'flex',fontWeight: 'bold', fontSize: '1.1em', color: '#3f2564', width: '100%', justifyContent: 'center',marginTop: '5px', marginLeft: '10%'}}>Postulez à l'une de nos offres: </label>
            <div className="jobs">
                        {jobs && jobs.map((job, index) => (
                                <JobModal
                                    key={index}
                                    job={job}
                                />
                            ))}
                    </div>
                    <label style={{display: 'flex', fontWeight: 'bold', fontSize: '1.1em', color: '#3f2564', width: '100%', justifyContent: 'center', marginLeft: '10%'}}>ou postulez en candidature spontanée: </label>
                    <div className="candidatureSpont">
                        { !mailSent &&
                            <>
                                <label >Votre message: </label>
                                <textarea  
                                    type="textarea"
                                    onInput={(e) => setText(e.target.value)}
                                    value={text}
                                    style={{width: '80%', height: '115px', overflowY: 'scroll'}}
                                />
                                { isSubmitted && text == '' &&
                                    <span className='warning center' style={{marginTop: '1vh', fontSize: '1em'}}>Merci de remplir le message</span>
                                }
                                <UploadFile 
                                    fileName={fileName}
                                    setFileName={setFileName}
                                    deleteFile={deleteFile}
                                    setIsFileDeleted={setIsFileDeleted}
                                />
                                { isSubmitted && !fileName &&
                                    <span className='warning center' style={{marginTop: '1vh', fontSize: '1em'}}>Merci de joindre votre CV</span>
                                }
                                <div className="captchaSendBtnDiv">
                                    <ReCAPTCHA
                                        sitekey="6Lc1ERcpAAAAABoxEL59-UkdlYnFc8Dny9banEdf"
                                        onChange={onChangeCaptcha}
                                    />
                                    { isSubmitted && captchaValue == null &&
                                        <span className='warning center' style={{marginTop: '1vh', fontSize: '1em'}}>Merci de cocher le CAPTCHA</span>
                                    }
                                    <div style={{display: 'flex', justifyContent: 'flex-end', width: '50%', alignItems: 'center'}}>
                                        <button 
                                            className="modalButton" 
                                            style={{height: '60%'}} 
                                            onClick={()=>{handleSubmit()}}>
                                                {!isSubmitted &&
                                                    <span>Envoyer</span>
                                                }
                                                {isSubmitted &&
                                                    <span>Envoi en cours...</span>
                                                }
                                        </button>
                                    </div>
                                </div>
                            </>
                        } 
                        {   mailSent  &&
                            <div style={{display: 'flex', flexDirection: 'column', width: '80%', height: '60%', marginLeft: '10%', alignItems: 'center', justifyContent: 'center', fontSize: '1.2em'}}>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <img 
                                        style={{width: '48px', height: 'auto'}}
                                        src={checkImg}
                                        alt="909"
                                    />
                                    <span>Votre candidature a été envoyée.</span>
                                </div> 
                                <span>Merci pour l'intérêt que vous portez à notre entreprise.</span> 
                                <span>Nous vous répondrons rapidement !</span> 
                            </div>
                        }
                    </div>
        </div>                                                                                                             
    )
}

export default Careers