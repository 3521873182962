import { Parallax, ParallaxLayer } from "@react-spring/parallax"
import { useRef } from "react"

const CGV = () => {
    const parallax = useRef()

    return (
        <div style={{overflowY: 'scroll', height: '80%'}}>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>ARTICLE 1 - GÉNÉRALITÉS - DISPOSITIONS GÉNÉRALES</h2>
                <h4>Article 1.1 Société</h4>
                <span>
                909, Société par actions simplifiée au capital de 100 euros, immatriculée au registre du commerce et des sociétés de Angers sous le numéro 920 080 256, dont le siège social est situé lieu-dit « Les Landes » La Jubaudière 49510 BEAUPREAU EN MAUGES
                </span>
                <h4>Article 1.2 Objet de la prestation de réparation</h4>
                <span>
                Les Conditions Générales de Ventes de prestation de Réparation (CGVR) décrites ci-après détaillent les droits et obligations de la société 909, et de son Client dans le cadre de sa seule activité de réparation des appareils électroniques et électroménager grand public.
                </span>
                <span>
                Les présentes Conditions Générales de Vente et de Réparation sont consultables et téléchargeables sur notre site Web www.909services.com. Dans l’hypothèse où une traduction en langue étrangère (ex : langue anglaise) serait mise à disposition du Client par 909, les termes de la version française prévaudront.
                </span>
                <span>
                Les clauses contraires de conditions particulières prévalent sur les présentes CGVR dès lors qu’elles sont validées par 909 et le Client. En passant commande, le Client de 909 (ci-après le « Client ») est réputé avoir pris connaissance des présentes
                </span>
                <span>
                Toute prestation accomplie par la société 909 implique donc l’adhésion sans réserve de l’acheteur aux présentes CGVR, et l’exclusion expresse de toute autre condition du Client ou de tout document émanant de lui qui ne serait pas signé par 909. Elles constituent avec d’éventuelles conditions particulières signées par les parties les seuls documents contractuels entre les parties.
                </span>
                <span>
                Le fait que 909 ne se prévale pas à un moment donné d’une quelconque des Conditions générales ne peut être interprété comme valant renonciation à se prévaloir ultérieurement desdites conditions générales.
                </span>
                <span>
                Si l’une des conditions des présentes CGVR se révélait non valide, les Parties s’engagent à négocier de bonne foi le remplacement de la condition invalide, l’ensemble des autres conditions demeurant en vigueur.
                </span>
                <span>
                Le cas échant, toute nouvelle version des présentes communiquée par 909 au Client s’appliquera à toute nouvelle commande quelle que soit l’antériorité des relations entre 909 et le Client.
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>ARTICLE 2 - OUVERTURE DE COMPTE</h2>
                <span>
                Pour pouvoir acheter un Produit, ou service, le CLIENT doit être âgé d’au moins 18 ans et disposer de la capacité légale ou, s’il est mineur, être en mesure de justifier de l’accord de ses représentants légaux.
                </span>
                <span>
                L’ouverture de compte sur le SITE WEB est gratuite.
                </span>
                <span>
                Aux fins d’ouverture de son compte, le CLIENT doit :
                </span>
                <span>
                • Remplir le formulaire prévu à cet effet et renseigner toutes les informations requises (nom, prénom, date de naissance, adresse e-mail. Le signe (*) indique les champs obligatoires qui doivent être remplis pour que l’inscription du CLIENT soit traitée.
                </span>
                <span>
                • Choisir des identifiants, c’est-à-dire une adresse e-mail ainsi qu’un mot de passe, modifiables à tout moment. Il est recommandé de changer régulièrement de mot de passe.
                </span>
                <span>
                Toute inscription incomplète ne sera pas validée, ce que le CLIENT reconnait et accepte. A l’issue de ce processus, le CLIENT recevra un courriel de confirmation de l’inscription.
                </span>
                <span>
                Les CLIENTS accèdent à tout moment à leur ESPACE PERSONNEL après s’être identifiés à l’aide de leurs IDENTIFIANTS. L’ESPACE PERSONNEL permet au CLIENT de consulter toutes ses COMMANDES effectuées sur le SITE WEB, et lui permet également, le cas échéant, de suivre la LIVRAISON des PRODUITS.                </span>
                <span>
                909 s’engage à conserver de façon sécurisée tous les éléments contractuels dont la conservation est requise par la loi ou la règlementation en vigueur.                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>ARTICLE 3 - COMMANDE - ENGAGEMENTS</h2>
                <h4>3.1 Commande</h4>
                <span>
                Toute offre de vente de Produits/Services s’entend sous réserve des stocks/capacités/équipes disponibles. Sauf stipulation contraire dans la proposition, la durée de validité d’une offre ou proposition de Produits ou Services est d’une semaine. 
                </span>
                <span>
                Le contrat est définitivement conclu dès l’envoi par 909 au Client de l’accusé de réception de la commande. Conformément à la loi, aucune commande, aucun contrat définitivement conclu ne pourra être annulé partiellement ou totalement par le Client.                 
                </span>
                <span>
                Chaque commande doit contenir les informations suivantes :                 
                </span>
                <span>
                – Adresse de livraison et de facturation,                 
                </span>
                <span>
                – Les références exactes, les désignations et quantités des Produits/Services commandés,                
                </span>
                <span>
                – Les prix nets si déterminables au moment de la commande (ex : en cas d’envoi pour réparation, le Client sera réputé avoir pris connaissance de la grille tarifaire d’intervention de 909)                
                </span>
                <span>
                Compte tenu de l’activité, le montant minimum d’une commande ne pourra être inférieur à trente (30) euros hors taxes. Est exclu du montant minimum le paiement des frais administratifs fixés à dix (10) euros.                
                </span>
                <h4>
                3.2 Engagement
                </h4>
                <span>
                Si lors du passage de notre collaborateur pour dépannage, le Client est absent, sans nous avoir prévenu préalablement, il lui sera facturé les frais de déplacements et ¼ d’heure de frais de main d’œuvre, ainsi qu’ éventuellement, le prix des pièces qui ont été commandées pour cette intervention.           
                </span>
                <span>
                De même, si le Client refuse le dépannage qu’il avait initié, et alors que le collaborateur est sur place.                
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>ARTICLE 4 - CHOIX DU PRODUIT OU DU SERVICE</h2>
                <span>
                Les caractéristiques des Produits et des services sont données dans la tarification de 909 à disposition du Client avant la passation de commande et notamment sur le site Web Internet dédié de 909.                
                </span>
                <span>
                Une fois les Produits / services sélectionnés et placés dans son panier, le CLIENT doit cliquer sur le panier et vérifier que le contenu de sa commande est correct. Si le Client ne l’a pas encore fait, il sera ensuite invité à s’identifier.                
                </span>
                <span>
                Une fois que le Client aura validé le contenu du panier et qu’il se sera identifié / inscrit, s’affichera à son attention un formulaire en ligne complété automatiquement et récapitulant le prix, les taxes applicables et, le cas échéant, les frais de livraison.                 
                </span>
                <span>
                Le Client est invité à vérifier le contenu de sa commande (y compris la quantité, les caractéristiques et les références des Produits et services commandés, l’adresse de facturation, le moyen de paiement et le prix) avant de valider son contenu.                 
                </span>
                <span>
                Le Client peut alors procéder au paiement des Produits en suivant les instructions figurant sur le site Web et fournir toutes les informations nécessaires à la facturation et à la livraison des Produits.                
                </span>
                <span>
                Le choix du produit ou du service est donc fait sous la seule responsabilité et aux risques du Client. En cas d’inadéquation des Produits ou du service aux besoins du Client, celui-ci reconnaît être le seul responsable du choix qu’il a fait et se porter garant vis-à-vis de 909.                 
                </span>
                <span>
                Sur simple demande, 909 est en mesure de répondre à toutes interrogations sur les caractéristiques du produit ou du service.                
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>ARTICLE 5 - PRIX – PAIEMENT</h2>
                <h4>
                    5.1 Prix
                </h4>
                <span>
                Les prix des Services sont fixés, selon le cas, par les tarifs standards de 909 en vigueur au jour de la commande ou dans le cadre d’une offre spécifique de 909.                
                </span>
                <span>
                909 se réserve le droit de modifier à tout moment ses tarifs (hors offre spécifique pendant la période de validité). Il revient en conséquence au Client de s’informer des tarifs en vigueur au moment de la commande.                
                </span>
                <span>
                Le prix des Produits est franco pour les commandes d’un montant supérieur à 750 euros hors taxes (matériels uniquement) (375 euros hors taxes pour les commandes Web), et pour une intervention en France métropolitaine à l’exclusion des Produits hors format (taille et/ou poids non-standard et/ou Produits nécessitant un mode de transport spécifique) qui peuvent faire l’objet d’une facturation spécifique.                </span>
                <span>
                Pour les commandes inférieures à 750 euros hors taxes dans ladite zone (375 euros hors taxes pour les commandes web), des frais de transport de 19euros hors taxes seront facturés en sus, le cas échant.                
                </span>
                <span>
                909 appliquera automatiquement, selon le régime applicable, toute nouvelle taxe ou toute augmentation des taux des taxes existantes.
                </span>
                <h4>
                5.2 Paiement, facturation, retard ou refus de paiement
                </h4>
                <span>
                Pendant la procédure de commande, le CLIENT devra saisir les informations nécessaires à la facturation, le signe (*) indiquera les champs obligatoires devant être remplis pour que la commande du Client soit traitée par 909.                
                </span>
                <span>
                Le Client doit notamment indiquer clairement toutes les informations relatives à la livraison, en particulier l’adresse exacte de livraison, ainsi que tout éventuel code d’accès à l’adresse de livraison.                
                </span>
                <span>
                Le CLIENT doit également préciser le moyen de paiement choisi parmi ceux proposés par 909, c’est-à-dire par carte bancaire ou par virement bancaire. A ce titre, le Client garantit à 909 qu’il détient toutes les autorisations requises pour utiliser le moyen de paiement choisi.                
                </span>
                <span>
                909 prendra toutes les mesures nécessaires pour garantir la sécurité et la confidentialité des données transmises en ligne dans le cadre du paiement en ligne sur le site Web.                 
                </span>
                <span>
                Ni le bon de commande que le Client établit en ligne, ni l’accusé de réception de la commande que 909 envoie au Client par courrier électronique ne constituent une facture. Quel que soit le mode de commande ou de paiement utilisé, le Client pourra récupérer sa facture à partir de son espace personnel.                
                </span>
                <span>
                909 se réserve le droit pour la première commande de demander un paiement avant intervention (en totalité ou partiel). 909 se réserve le droit d’adresser les factures par voie électronique conformément à l’article 289VI du Code Général des Impôts, ce que le Client accepte expressément et renonce de ce fait à recevoir une facture sur support papier. En aucun cas une quelconque réclamation n’autorise le Client à suspendre ou refuser tout ou partie d’une facture ni à retenir tout ou partie des sommes dues par lui, ni à opérer une compensation.                
                </span>
                <span>
                En cas de non-paiement de tout ou partie d’une facture, ou d’une échéance, en cas de paiements échelonnées, 909 se réserve le droit, jusqu’au complet paiement de la facture en cours, de suspendre toute livraison ou tout contrat en cours, de résilier tout contrat en cours, et ce, sans préjudice de tout dommage et intérêt pouvant être réclamé par 909 et sans droit à réclamation de la part du Client. En outre, toute autre facture non arrivée à échéance deviendra immédiatement exigible et 909 pourra dès lors, exiger le paiement immédiat de toutes les factures non-échues, les montants des impayés seront majorés des frais consécutifs au retard de paiement. Les factures seront payables comptant à la commande en cas d’impayés.                
                </span>
                <span>
                A défaut de paiement à l’échéance convenu, le Client devra en sus du montant principal, payer :                 
                </span>
                <span>
                – Des pénalités de retard applicables par jour de retard et calculées à compter de l’échéance de la facture en cause au taux de refinancement de la Banque Centrale Européenne en vigueur au premier jour de chaque semestre civil, majoré de 10 points de pourcentage, ce taux ne pouvant être inférieur à 3 fois le taux de l’intérêt légal applicable et ce, conformément à l’article L441-10.-1 du Code de Commerce.                
                </span>
                <span>
                – L’indemnité forfaitaire pour frais de recouvrement (actuellement fixée à 40 euros) par facture,               
                </span>
                <span>
                – Et des éventuels dommages et intérêts que pourrait solliciter 909.                
                </span>
                <span>
                En cas de recouvrement par voie d’huissier ou judiciaire, le Client devra en sus du montant principal, payer à titre de clause pénale un montant forfaitaire de 15% des sommes dues et ce sans préjudice des intérêts conventionnels, ainsi que tous les frais et honoraires de recouvrement.                
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>ARTICLE 6 - CONDITIONS D'INTERVENTION - DÉLAIS - FORCE MAJEURE</h2>
                <h4>
                6.1 Conditions d’intervention
                </h4>
                <span>
                909 fixe la date et le lieu d’intervention en accord avec le Client et se réserve le droit, de différer et/ou annuler l’intervention et de convenir avec le Client d’une nouvelle date pour cette intervention. En aucun cas, 909 ne pourra être tenu responsable des conséquences liées aux dispositions que le Client pourrait avoir prises pour recevoir 909, ni redevable de sommes ou d’indemnités de quelconque nature. L’annulation et/ou le report de l’intervention devra, sauf cas de force majeure, comme les conditions climatiques ou un problème technique, être annoncé au Client au minimum la veille de l’intervention.                
                </span>
                <h4>
                6.2 La livraison dans les délais ne peut intervenir que si le Client est à jour de ses obligations envers 909.
                </h4>
                <h4>
                6.3 Force majeure
                </h4>
                <span>
                Outre-les événement habituellement retenus par la jurisprudence française en cas de force majeure, les obligations des Parties seront automatiquement suspendues dans les hypothèses d’évènement échappant au contrôle de l’une des Parties, qui ne pouvaient raisonnablement être prévus lors de la conclusion du Contrat et dont les effets ne peuvent être évités par des mesures appropriées, et qui empêchent l’exécution de son obligation par la Partie concernée. La Partie constatant l’évènement devra sans délai informer l’autre Partie de son impossibilité à exécuter son obligation et s’en justifier auprès de celle-ci.                
                </span>
                <span>
                Si l’empêchement est temporaire, l’exécution de l’obligation est suspendue à moins que le retard qui en résulterait ne justifie la résolution du Contrat.                
                </span>
                <span>
                Si l’empêchement est définitif, le Contrat est résolu de plein droit et les Parties sont libérés de leurs obligations dans les conditions prévues aux article 1351 et 1351-1 du Code Civil.                
                </span>
                <span>
                La suspension des obligations ne pourra en aucun cas être une cause de responsabilité pour non-exécution de l’obligation en cause, ni induire le versement de dommages et intérêts ou pénalité de retard. Cependant, dès la disparition de la cause de la suspension de leurs obligations réciproques, les Parties feront tous les efforts pour reprendre le plus rapidement possible l’exécution normale de leurs obligations contractuelles.                
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>ARTICLE 7 - RESPONSABILITÉ DE 909</h2>
                <span>
                909 s’engage à mettre en œuvre tous les moyens à sa disposition pour offrir au Client la meilleure qualité de service et de sécurité dans le respect des normes professionnelles applicables. Au titre de l’ensemble de ses obligations vis-à-vis du Client, 909 est débitrice d’une obligation de moyens. Aucune responsabilité de 909 ne peut être engagée lorsque l’inexécution, mauvaise exécution, retard dans l’exécution ou suspension du contrat provient d’une cause étrangère, cas fortuit ou de force majeure, fait d’un tiers ou fait du Client lui-même.                
                </span>
                <span>
                Dans l’hypothèse où la responsabilité contractuelle de 909 devrait être reconnue, les réparations auxquelles le Client pourrait prétendre se limiteront aux seuls préjudices, directs et certains souffert par le Client et qui sont entrés dans le champ contractuel à l’exclusion de tout préjudice qualifiable d’indirect tel que la perte de chiffre d’affaire, la perte de Clients, l’atteinte à l’image de marque du Client, perte de production, perte d’exploitation, perte de chance, perte de données, préjudice financier ou commercial ou autre, etc…                
                </span>
                <span>
                En tout état de cause, la réparation à laquelle le Client pourra prétendre sera limitée dans son quantum au total des sommes que le Client a versé à 909 pour l’exécution de la prestation qui s’est avérée défectueuse (ex : dans le cas d’un seul produit défectueux, le quantum de la réparation sera limité aux sommes engagées pour ce produit).                
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>ARTICLE 8 - OBLIGATIONS DU CLIENT</h2>
                <span>
                Outre le paiement du prix du (des) service(s) et de toutes autres obligations mise à sa charge au titre des présentes, le Client s’engage en tout état de cause à prendre connaissance des précautions d’emploi du produit réparé, 909 ne saurait être tenue responsable de tout désagrément ou dommage lié de manière direction ou indirecte à l’utilisation anormale d’un produit.                
                </span>
                <h4>ABANDON DES MATERIELS :</h4>
                <span>
                En cas d’accord formel (e-mail) de votre part sur l’abandon du produit suite au refus du devis, 909 pourra procéder à la revalorisation de votre produit (destruction, revente, ….)
                </span>
                <span>
                Par dérogation à la loi du 31 décembre 1903, tout appareil non repris par vos soins, dans un délai de 1 mois après y avoir été invité par le SAV par l’envoi d’e-mails, sera considéré comme abandonné et 909 se réservera la possibilité de procéder à la revalorisation de votre produits (revente, destruction,….)
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>ARTICLE 9 - DROIT DE RÉTRACTATION</h2>
                <h4>
                9.1. Champ d’application
                </h4>
                <span>
                Concernant les Produits ou Services commercialisés sur le Site Web internet, et hormis les exceptions énumérées ci-dessous, le Client bénéficie d’un droit de se rétracter et de renvoyer le(s) Produit(s) et/ou Services pendant quatorze (14) jours francs à compter de la livraison des Produits ou à compter de la validation de la Commande pour les Services conformément aux dispositions des articles L 221-18 et suivants du Code de la Consommation.                
                </span>
                <span>
                Pour exercer son droit de rétractation, il appartiendra au Client de notifier à 909 sa volonté de se rétracter suivant les modalités définies à l’article 5.2 des présentes Conditions Générales de Vente.                
                </span>
                <span>
                Conformément à l’article L 221-25 du Code de la consommation, le Client qui demande l’exécution d’un Service avant la fin du délai de rétractation peut toutefois exercer son droit de rétractation dans les conditions définies à l’article 5.3 des présentes Conditions Générales de Vente.                
                </span>
                <span>
                En application de l’article L 221-28 du Code de la Consommation, certains Produits ou Services ne peuvent faire l’objet d’un droit de rétractation. Il s’agit notamment :                
                </span>
                <span>
                – De la fourniture de Services pleinement exécutés avant la fin du délai de rétractation et dont l’exécution a commencé après accord préalable exprès du consommateur et renoncement exprès à son droit de rétractation;                
                </span>
                <span>
                – De la fourniture de Produits susceptibles de se détériorer rapidement;                
                </span>
                <span>
                – De la fourniture de Produits qui, après avoir été livrés et de par leur nature, sont mélangés de manière indissociable avec d’autres articles;                
                </span>
                <span>
                – De travaux d’entretien ou de réparation à réaliser en urgence au domicile du Client et expressément sollicités par lui, dans la limite des pièces de rechange et travaux strictement nécessaires pour répondre à l’urgence;                
                </span>
                <span>
                – De la fourniture d’enregistrements audio ou vidéo ou de logiciels informatiques lorsqu’ils ont été descellés par le Client après la livraison;                
                </span>
                <span>
                – De la fourniture d’un contenu numérique non fourni sur un support matériel dont l’exécution a commencé après accord préalable exprès du Client et renoncement exprès à son droit de rétractation.                
                </span>
                <h4>
                9.2. Modalités d’exercice du droit de rétractation                
                </h4>
                <span>
                Le Client dispose d’un délai de rétractation de 14 jours ouvrables pour exercer son droit de rétractation d’un contrat conclu à distance sans avoir à motiver sa décision. Pour exercer ce droit de rétractation, il devra notifier sa décision de rétractation au moyen d’une déclaration dénuée d’ambiguïté (par exemple, lettre envoyée par la poste, télécopie ou courrier électronique).                 
                </span>
                <span>
                En cas d’envoi par courriel, la société enverra sans délai un accusé de réception de la rétractation par courriel. Le délai mentionné court à compter du jour de la conclusion du contrat.                
                </span>
                <span>
                Ce droit de rétractation s’exerce sans pénalité. Le remboursement, sans frais supplémentaire, s’effectuera suivant le même moyen de paiement que celui que le Client a utilisé pour la transaction initiale, sauf si le Client convient d’un moyen différent. Le remboursement des sommes effectivement réglées par le Client sera effectué dans un délai de 14 jours à compter de la réception, par la société, de la notification de la rétractation du Client.                
                </span>
                <span>
                Conformément à l’article L221-28 du Code de la consommation, le Client est informé que le droit de rétractation est exclu pour les contrats de fourniture de services après que le service a été pleinement exécuté si l’exécution a commencé avec l’accord préalable exprès du Client, lequel a également reconnu qu’il perdra son droit de rétractation une fois que le contrat aura été pleinement exécuté par le professionnel.                
                </span>
                <span>
                En vertu de l’article L221-25 du code de la consommation, le Client qui a exercé son droit de rétractation d’un contrat de prestation de service dont l’exécution a commencé, à sa demande expresse, avant la fin du délai de rétractation doit verser à la société un montant correspondant à la prestation de pose réalisée jusqu’à la communication de sa décision de se rétracter. Le montant est proportionné au prix total de la prestation de services convenu dans le contrat.                
                </span>
                <span>
                Cas spécifique dans le cadre de la commande du service d’assistance à distance (Visio), le Client peut annuler sa réservation jusqu’à 24h avant le rendez-vous avec un professionnel, délais après lequel aucun remboursement ne sera effectué.                
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>ARTICLE 10 - MÉDIATION - RÉCLAMATION</h2>
                <span>
                Conformément à l’article L. 612-1 du Code de la consommation, dans un délai d’un an à compter de sa réclamation écrite, le consommateur, sous réserve de l’article L.152-2 du code de la consommation, a la faculté d’introduire une demande de résolution amiable par voie de médiation, auprès de SAS Médiation Solution, 222 chemin de la bergerie 01800 Saint Jean de Niost,                 
                </span>
                <span>Site Web :  
                    <a href="https://www.sasmediationsolution-conso.fr">https://www.sasmediationsolution-conso.fr</a>
                </span>
                <span>Email  :  
                    <a href="mailto:contact@sasmediationsolution-conso.fr">contact@sasmediationsolution-conso.fr</a>
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>ARTICLE 11 - GARANTIES</h2>
                <h4>
                11.1. Garanties légale
                </h4>
                <span>
                L’ensemble des produits vendus bénéficient de la garantie légale des vices cachés (articles 1641 à 1649 du Code Civil).                
                </span>
                <span>
                Un vice caché est un défaut qui rend impossible l’utilisation du bien dans des conditions normales ou diminue très fortement son usage. Le contrat de prestation de services octroie au client le bénéfice de la garantie des vices cachés, c’est-à-dire les défauts cachés rendant la chose impropre à l’usage auquel on la destine. Toutefois, le client ne pourra se retourner contre le prestataire qu’à la condition que ces vices soient dus à l’intervention de ce dernier, et non préexistants.
                </span>
                <span>
                Le consommateur peut mettre en œuvre la garantie des vices cachés dans un délai de 2 ans à partir du jour de la découverte du défaut. Quant à l’action en réclamation, elle est prescrite cinq ans après la date d’achat du produit.
                </span>
                <span>
                Pour obtenir gain de cause, l’acheteur doit prouver l’existence du vice caché en s’appuyant sur tout élément susceptible de constituer une preuve.
                </span>
                <span>
                Le consommateur peut choisir entre :
                </span>
                <span>
                – Restituer le produit contre remise d’un ticket de dépôt et se faire rembourser intégralement
                </span>
                <span>
                – Conserver le produit et demander un remboursement partiel
                </span>
                <span>
                Cette demande doit être faite par lettre recommandée avec avis de réception.
                </span>
                <span>
                En sus, nous appliquons notre garantie commerciale (articles L217-15 à L217-16-1 du Code de la consommation), selon les modalités ci-dessous :
                </span>
                <h4>
                11.2. Garantie commerciale – Service de réparation :
                </h4>
                <span>
                Cette garantie ne concerne que la réparation effectuée par 909, c’est-à-dire pour un même symptôme. Un article de nouveau en panne pour un symptôme différent n’est pas couvert par la garantie de la réparation précédente, sauf à démontrer un lien de cause à effet.
                </span>
                <span>
                Sauf accord spécifique, la garantie de réparation est de 3 mois pour les interventions réalisées par 909.
                </span>
                <h4>
                11.3. Exclusion de garantie                
                </h4>
                <span>
                Aucune garantie Constructeur ou 909 n’est, quoiqu’il en soit, donnée au Client dans les cas suivants :
                </span>
                <span>
                Ne sont pas couverts les Produits endommagés ou rendus défectueux suite :
                </span>
                <span>
                – à une utilisation non conforme à l’usage pour lequel il a été conçu,
                </span>
                <span>
                – infection par virus ou l’utilisation du Produit avec des logiciels non fournis ou incorrectement installés
                </span>
                <span>
                – à une modification du Produit,
                </span>
                <span>
                – à une installation impropre de produits tiers
                </span>
                <span>
                – à une négligence, erreur de raccordement ou de manipulation, entretien et usage du Produit non conforme aux spécifications techniques des constructeurs/909 ou, plus généralement, une utilisation défectueuse ou maladroit (ex : utilisation abusive, casse, chaleur excessive, corrosion, oxydation, manipulation brutale, etc.)
                </span>
                <span>
                – à un ajout de dispositif complémentaire ou accessoire du Produit, ou utilisation de toutes pièces nécessaires pour l’exploitation du Produit non conforme aux spécifications techniques des constructeurs/909,
                </span>
                <span>
                La garantie ne couvre pas :
                </span>
                <span>
                – les dommages causés par une utilisation avec un autre produit : utilisation d’accessoires ou périphériques dont le type, l’état et les normes ne répondent pas aux prescriptions du fabricant,
                </span>
                <span>
                – les cordons accessoires
                </span>
                <span>
                – les défauts mineurs des écrans LCD survenant sur des produits équipés de la technologie LCD, à la condition que le nombre de pixels défectueux n’excède pas 3 pixels
                </span>
                <span>
                – les défauts esthétiques, y compris les rayures, traces de chocs, ou défauts des plastiques de protection des ports de connexion, les fissures ou éraflures de l’écran LCD et coques,
                </span>
                <span>
                – les remplaces de pièces par suite d’usure normale,
                </span>
                <span>
                – les défauts liés à un défaut d’étanchéité
                </span>
                <h4>
                11.4. Expiration de la durée de garantie :               
                </h4>
                <span>
                L’expiration de la période de garantie commerciale (3mois) met fin aux obligations contractuelles de 909 précisées au présent article 11.2.  A noter que cette expiration n’entraîne pas l’expiration de l’action en garantie légale de vice caché.L’action en garantie légale des vices cachés ne sera expirée qu’à l’issue des délais précisés à l’article 11.1 des Présentes.
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>ARTICLE 12 - RÉSILIATION DU CONTRAT</h2>
                <span>
                En cas de non-respect par le Client de l’une quelconque de ses obligations, après un délai de 48 heures suivant mise en demeure adressée par lettre recommandée avec accusé de réception, restée sans effet, la commande correspondante sera résiliée de plein droit aux torts et griefs du Client. En tout état de cause, le montant total de la commande ou du contrat en cours restera dû par le Client.                 
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>ARTICLE 13 - TRAITEMENT DES DONNÉES PERSONNELLES</h2>
                <span>
                909 collecte sur le site Web des données personnelles concernant ses Clients, y compris par le biais de cookies. Les Clients peuvent désactiver les cookies en suivant les instructions fournies par leur navigateur.                 
                </span>
                <span>
                Les données collectées par 909 sont notamment utilisées afin de traiter les commandes passées sur le site Web, gérer le compte du Client, si le Client a expressément choisi cette option, lui envoyer des courriers de prospection commerciale, des newsletters, des offres promotionnelles et/ou des informations sur des ventes spéciales, sauf si le Client ne souhaite plus recevoir de telles communications de la part de 909.
                </span>
                <span>
                Les données du Client sont conservées confidentiellement par 909, pour les besoins du contrat, de son exécution et dans le respect de la loi.
                </span>
                <span>
                Les Clients peuvent à tout moment se désinscrire en accédant à leur compte.
                </span>
                <span>
                Les données peuvent être communiquées, en tout ou partie, aux prestataires de services de 909 intervenant dans le processus de commande.
                </span>
                <span>
                A des fins commerciales, 909 peut transférer à ses partenaires commerciaux les noms et coordonnées de ses Clients, à condition que ceux-ci aient expressément donné leur accord préalable lors de l’inscription sur le site Web.
                </span>
                <span>
                909 demandera spécifiquement aux Clients s’ils souhaitent que leurs données personnelles soient divulguées. Les Clients pourront changer d’avis à tout moment en contactant 909.
                </span>
                <span>
                Conformément à la loi n°78-17 du 6 janvier 1978 relative à l’informatique aux fichiers et aux libertés et au Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données, et abrogeant la directive 95/46/CE (Règlement général sur la protection des données dit RGPD), 909 assure la mise en œuvre des droits des personnes concernées.
                </span>
                <span>
                Il est rappelé que le Client dont les données à caractère personnel sont traitées bénéficie des droits d’accès, de rectification, de mise à jour, de portabilité et d’effacement des informations qui le concernent, conformément aux dispositions des articles 39 et 40 de la loi Informatique et Libertés modifiée, et aux dispositions des articles 15, 16 et 17 du Règlement Général européen sur la Protection des Personnes (RGPD).
                </span>
                <span>
                Conformément aux dispositions de l’article 38 de la loi Informatique & Libertés modifiée et aux dispositions de l’article 21 du RGPD, le Client peut également, pour des motifs légitimes, s’opposer au traitement des données le concernant, sans motif et sans frais.                
                </span>
                <span>
                Le Client peut exercer ces droits en adressant un courrier électronique à l’adresse <a href="mailto:dpo@909services.com">dpo@909services.com</a>
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>ARTICLE 14 - CESSION DE CONTRAT</h2>
                <span>
                909 est autorisée à céder ses droits et obligation au titre du présent contrat a toute entité détenue ou contrôlée directement ou indirectement par 909. Elle est également autorisée à céder tout ou partie de ses droits dans le cadre d’une fusion, acquisition ou réorganisation de 909, à toute entité à laquelle 909 cède une partie substantielle des actifs intéressants le présent contrat, ou à une de ses filiales. 
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>ARTICLE 15 - DROIT APPLICABLE</h2>
                <span>
                Les présentes Conditions générales ainsi que, le cas échéant, les conditions particulières et annexes sont soumises au seul droit français. Sous réserve de la qualité de commerçant du Client, à défaut de règlement amiable, tout litige relatif à l’interprétation ou à l’exécution des présentes conditions générales de prestation seront de la compétence exclusive du Tribunal de Commerce d’Angers (France), même en cas de référé, d’appel en garantie ou de pluralité de défendeurs.
                </span>
            </div>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>ARTICLE 16 - VALIDITÉ DES CONDITIONS GÉNÉRALES</h2>
                <span>
                Toute modification de la législation ou de la réglementation en vigueur, ou toute décision d’un tribunal compétent invalidant une ou plusieurs clauses des présentes Conditions Générales ne saurait affecter la validité des présentes Conditions Générales. Une telle modification ou décision n’autorise en aucun cas les Clients à méconnaître les présentes Conditions Générales.
                </span>
                <span>
                Toutes conditions non expressément traitées dans les présentes seront régies conformément à l’usage du secteur de la prestation de service de réparation aux particuliers, pour les sociétés dont le siège social se situe en France.
                </span>
            </div>
        </div>
    )
}

export default CGV