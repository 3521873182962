import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    bonusDevices: [],
    isLoading: true,
}

export const getBonusDevices = createAsyncThunk(
    'bonusDevices/getBonusDevices',
    async (thunkAPI) => {
        try {
            return await fetch(global.constantes.proxy.base + '/bonusDevices')
            .then((resp) => resp.json())
            .catch((err) => console.log(err));
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong')
        }
    }
)

const bonusDevicesSlice = createSlice({
    name: 'bonusDevices',
    initialState,
    extraReducers:(builder)=>{
        builder
            .addCase(getBonusDevices.pending, (state) => {
                state.isLoading = true
            })
            // fulfilled mean it's successful and return devices
            // just have to set it in state 
            .addCase(getBonusDevices.fulfilled, (state, action) => {
                state.isLoading = false
                state.bonusDevices = action.payload
            })
            .addCase(getBonusDevices.rejected, (state, action) => {
                state.isLoading = false
            })
    }
})

// export const { clearDevice, removeDevice, createDevice } = devicesSlice.actions
export default bonusDevicesSlice.reducer