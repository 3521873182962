import { useState, useRef, useEffect } from "react"
import { store } from '../store';
import 'react-phone-number-input/style.css'
import { useDispatch } from "react-redux";
import { getUser } from "../features/userSlice";
import FileModal from "../components/FileModal";

const Signup = () => {
    const { user } = store.getState().user;
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [adress, setAdress] = useState('')
    const [zipcode, setZipcode] = useState('')
    const [cities, setCities] = useState([])
    const [city, setCity] = useState('')
    const [phone, setPhone] = useState('')
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const [show,setshow] = useState(false)
    const [_id, set_id] = useState('')
    const [userFiles, setUserFiles] = useState('')
    const pass = useRef();
    const dispatch = useDispatch()

    useEffect(()=>{
        setUser()
        async function fetchUserFiles() {
            await fetch(global.constantes.proxy.base + '/userRepairFile/' + _id,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${user.token}`
                }
              })
            .then( async response =>{
                await response.json()
                .then(json => {
                    if(json){
                        setUserFiles(json)
                    }
                })
            })
        }
        if(_id){
            fetchUserFiles()
        }
    }, [_id])

    console.log(userFiles);

    const setUser = () => {
        if(user){
            set_id(user.user._id)
            setEmail(user.user.email)
            setFirstName(user.user.first_name)
            setLastName(user.user.last_name)
            setAdress(user.user.adress)
            setPhone(user.user.phone_number)
            setZipcode(user.user.zipcode)
            handleZipCode(user.user.zipcode)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(user){
            let newUser = {...user, user :{
                _id: _id,
                activation_token: user.user.activation_token,
                adress: adress,
                city_geoloc: JSON.stringify(city.geoloc),
                city_name: city.name,
                createdAt: user.user.createdAt,
                email: email,
                first_name: firstName,
                id: user.user.id,
                last_name: lastName,
                password: user.user.password,
                phone_number: phone,
                role: user.user.role,
                verified: user.user.verified,
                zipcode: zipcode
            }}
            // console.log(newUser);
            localStorage.setItem('user', JSON.stringify(newUser))
        }
        const cityName = city.name
        const cityGeoLoc = JSON.stringify(city.geoloc)
        const userToFetch = {_id, email, firstName, lastName, adress, zipcode, cityName, cityGeoLoc, phone}
        const response = await fetch(global.constantes.proxy.base + '/user/modifyUser',{
            method: 'POST',
            body: JSON.stringify(userToFetch),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const json = await response.json()
        if(!response.ok){
            setIsLoading(false)
            setError(json.error)
        }else{
            dispatch(getUser())
            setIsLoading(false)
            setError(null)
            setSuccess(json.success)
        }
    }

    const handleZipCode = async (code) => {
        const apiUrl = 'https://geo.api.gouv.fr/communes?codePostal=';
	    const format = '&format=geojson';
        let url = apiUrl + code + format;
        const response = await fetch( url )
        const json = await response.json()
        let nextId = 0
        setCities([])
        const zipCities = []
        if(json){
            json.features.forEach(city => {
                zipCities.push({id: nextId++, name: city.properties.nom, geoloc: city.geometry.coordinates})
            });
        }
        zipCities.forEach(city=>setCities(cities=>[...cities, city]))
        setZipcode(code)
        setCity(zipCities[0])
    }

    const handleCity = async (id) => {
        setCity(cities[id])
    }

    const showpassword = (e) =>{
        e.preventDefault()
        setshow(!show)
            pass.current.type = show ? 'password':'text';
    }

    const formatPhoneNumber = phone => {
        if(phone.substring(0, 3) == '+33'){
            return phone.replace("+33", "0")
        }
    }

    const setPhoneAppointment = (appointment) => {
        const date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        const date1 = new Date(`${month}/${day}/${year}`)
        const rdvDate = appointment.split('/')
        const date2 = new Date(`${rdvDate[1]}/${rdvDate[0]}/${rdvDate[2]}`);
        const isPast = date2 < date1
        if(isPast){
            return ['','']
        }else{
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if(diffDays > 3){
                return [appointment, '#72f692']
            }
            if(diffDays <= 3 && diffDays > 0){
                return [appointment, '#f6ab72']
            }
            if(diffDays == 0){
                return [appointment, '#f67272']
            }
        }

    }

    return (
        <div className="accountContainer">
            <form className='accountSignup' onSubmit={()=>{handleSubmit()}}>
                <h3>Mon compte</h3>
                <label>Email:</label>
                <input 
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                />
                <label>Prénom:</label>
                <input 
                    type="text"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                />
                <label>Nom:</label>
                <input 
                    type="text"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                />
                <label>Adresse:</label>
                <input 
                    type="text"
                    onChange={(e) => setAdress(e.target.value)}
                    value={adress}
                />
                <label>Code Postal:</label>
                <input 
                    type="text"
                    onChange={(e) => handleZipCode(e.target.value)}
                    value={zipcode}
                />
                <label>Ville:</label>
                <select 
                    className="citySelect" 
                    onChange={(e) => handleCity(e.target.value)}
                    defaultValue={()=>{
                        cities.map((city)=>{
                            if(city.name == user.user.city_name){
                                return city.id
                            }
                        })
                    }}
                >
                    {cities === '' && <option value="1">Entrez un Code Postal</option>}
                    {cities.map((city)=>(
                        city.name == user.user.city_name ?
                            <option key={city.id} value={city.id}>{city.name}</option>
                        :
                        <option key={city.id} value={city.id}>{city.name}</option> 
                        ))
                    }
                </select>
                <label>Téléphone : </label>
                <input 
                    type="text"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                />
                <div style={{height:35}}>
                    <button style={{float:"right"}} disabled={isLoading} onClick={handleSubmit}>Enregistrer</button>
                </div>
                {error && <div className="error">{error}</div>}
                {success && <div className="success">{success}</div>}
            </form> 
            <div className="accountFiles">
                {userFiles && !userFiles.length > 1 &&
                    <label style={{marginTop: '5%', marginLeft: '5%', fontWeight: 'bold', fontSize: '1.2em'}}>
                        Mon dossier
                    </label>
                }
                {userFiles && userFiles.length > 1 &&
                    <label style={{marginTop: '5%', marginLeft: '5%', fontWeight: 'bold', fontSize: '1.2em'}}>
                        Mes dossiers
                    </label>
                }
                {userFiles && userFiles.map((file, index)=>(
                    <div 
                        key={index} 
                        style={{display: 'flex', justifyContent: 'center'}}
                        // onClick={()=>{handleFileClicked(file)}}
                        >
                            <FileModal
                                userModal={true}
                                selected={file.selected}
                                id={file.id}
                                erpFileId={file.erp_file_id}
                                firstName={file.first_name} 
                                lastName={file.last_name}
                                phoneAppointment={ file.phone_appointment ? setPhoneAppointment(file.phone_appointment) : ''}
                                timeSlot={file.time_slot}
                            />
                    </div>
                ))
            }
            </div>
        </div>          
    )
}

export default Signup