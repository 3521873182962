import { Link } from 'react-router-dom'

const Trades = () => {

    return (
        <div style={{height: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', overflowY: 'scroll'}}>
            <Link to='/careers' className="modalButton" style={{marginBottom: '25px', marginTop: '25px', textDecorationLine: 'none' }}>Rejoignez-nous</Link>
            <div className="trades">
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '250px', height: '350px', borderRadius: '25px', boxShadow: '4px 4px 3px 4px #c5c5c5', backgroundColor: '#3f2564', margin: '10px'}}>
                    <img style={{height: '95px'}} src={require('../assets/images/Vert_Assistant_logistique.svg').default} alt=""/>
                    <span style={{fontSize: '1.1em', fontWeight: 'bold', color: '#fff', marginTop: '25px'}}>Assistant Logistique</span>
                    <span style={{fontSize: '0.7em', color: '#fff', width: '90%', marginTop: '25px'}}>Nos logisticiens/iennes réceptionnent quotidiennement les commandes et préparent le matériel nécessaire aux techniciens itinérants pour leurs tournées. Ils gèrent également le stock de pièces détachées sur les agences.</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '250px', height: '350px', borderRadius: '25px', boxShadow: '4px 4px 3px 4px #c5c5c5', backgroundColor: '#3f2564', margin: '10px'}}>
                    <img style={{height: '95px'}} src={require('../assets/images/Vert_Assistant_accueil_administratif.svg').default} alt=""/>
                    <span style={{fontSize: '1.1em', fontWeight: 'bold', color: '#fff', marginTop: '25px', marginLeft: '25px'}}>Assistant Accueil & Administratif</span>
                    <span style={{fontSize: '0.7em', color: '#fff', width: '90%', marginTop: '25px'}}>Grâce à leur expérience et à nos outils informatiques, nos assistant(e)s planifient quotidiennement les tournées des techniciens itinérants en optimisant chaque trajet. Ils/Elles assurent un contact téléphonique avec nos interlocuteurs et assurent l’accueil en agence du lundi au vendredi !</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '250px', height: '350px', borderRadius: '25px', boxShadow: '4px 4px 3px 4px #c5c5c5', backgroundColor: '#3f2564', margin: '10px'}}>
                    <img style={{height: '95px'}} src={require('../assets/images/Vert_Techniciens_Alternants.svg').default} alt=""/>
                    <span style={{fontSize: '1.1em', fontWeight: 'bold', color: '#fff', marginTop: '25px'}}>Techniciens Alternants</span>
                    <span style={{fontSize: '0.7em', color: '#fff', width: '90%', marginTop: '25px'}}>Bon à savoir : 909 s’implique aussi dans la formation des jeunes techniciens/iennes. Alternant(e)s, envoyez-nous vos CV pour postulez et peut-être rejoindre l’aventure 909.</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '250px', height: '350px', borderRadius: '25px', boxShadow: '4px 4px 3px 4px #c5c5c5', backgroundColor: '#3f2564', margin: '10px'}}>
                    <img style={{height: '95px'}} src={require('../assets/images/Vert_Technicien_DIAG.svg').default} alt=""/>
                    <span style={{fontSize: '1.1em', fontWeight: 'bold', color: '#fff', marginTop: '25px'}}>Technicien DIAG</span>
                    <span style={{fontSize: '0.7em', color: '#fff', width: '90%', marginTop: '25px'}}>Nos experts établissent le premier contact avec nos clients afin de déterminer la panne du client et d’y associer la commande du bon matériel nécessaire à sa réparation. Ils/elles sont le contact privilégié de nos techniciens/iennes réparateurs jusqu’à la finalisation de l’intervention client.</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '250px', height: '350px', borderRadius: '25px', boxShadow: '4px 4px 3px 4px #c5c5c5', backgroundColor: '#3f2564', margin: '10px'}}>
                    <img style={{height: '95px'}} src={require('../assets/images/Vert_Techniciens_atelier.svg').default} alt=""/>
                    <span style={{fontSize: '1.1em', fontWeight: 'bold', color: '#fff', marginTop: '25px'}}>Techniciens Atelier</span>
                    <span style={{fontSize: '0.7em', color: '#fff', width: '90%', marginTop: '25px'}}>Après une formation en atelier auprès de nos experts sur la réparation de matériels blanc (électroménager) et brun (tv/hi-fi), nos techniciens/iennes réparent des lots de matériels (blancs et bruns) reçus directement en agences, du lundi au vendredi !</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '250px', height: '350px', borderRadius: '25px', boxShadow: '4px 4px 3px 4px #c5c5c5', backgroundColor: '#3f2564', margin: '10px'}}>
                    <img style={{height: '95px'}} src={require('../assets/images/Vert_Techiniciens_itinerants_909.svg').default} alt=""/>
                    <span style={{fontSize: '1.1em', fontWeight: 'bold', color: '#fff', marginTop: '25px'}}>Techiniciens Itinérants 909</span>
                    <span style={{fontSize: '0.7em', color: '#fff', width: '90%', marginTop: '25px'}}>Après une formation en atelier auprès de nos experts sur la réparation de matériels blancs (électroménager) et bruns (tv/hi-fi), nos techniciens/iennes se déplacent au domicile de nos clients afin de réparer tout type de matériel sous garantie ou hors garantie constructeur, du lundi au vendredi !</span>
                </div>
            </div>          
        </div>
        
    )
}

export default Trades