import { useDispatch, useSelector } from "react-redux"
import ArticleEditorModal from "../components/ArticleEditorModal";
import ArticleDeleteModal from "../components/ArticleDeleteModal";
import { removeJob } from "../features/jobsSlice";
import JobEditorModal from "./JobEditorModal";

const JobDetails = ({id, title, text, contact, created_at}) => {
    const dispatch = useDispatch()
    const { user } = useSelector((store) => store.user)

    const handleDeleteClick = async () => {
        if(!user){
            return
        }
        const response = await fetch(global.constantes.proxy.base + '/job/' + id, {
            method: 'DELETE',
            headers: {
                'Authorization':`Bearer ${user.token}`
            }
        })
        .then(()=>{
            dispatch(removeJob(id))
        })
    }

    const setCreatedDate = (date) => {
        let dateFormatted = date.split('T')[0]
        dateFormatted = dateFormatted.split('-')
        dateFormatted = `${dateFormatted[2]}/${dateFormatted[1]}/${dateFormatted[0]}`
        return dateFormatted
    }
    
    const setPreviewText = (text) => {
        let previewText = text.split(' ')
        previewText = previewText.slice(0, 7)
        previewText.push('...')
        previewText = previewText.join(' ')
        return previewText
    }

    return(
        <div className="article-details">
            <div>
                <h4>{title}</h4>
                <p><strong>Texte: </strong>{setPreviewText(text)}</p>
                <p>Contact: {contact}</p>
                <p>{setCreatedDate(created_at)}</p>
            </div>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-end"}}>
                {/* <button className="article-details-delete" onClick = {handleDeleteClick}></button> */}
                <ArticleDeleteModal
                    title= {title}
                    handleDeleteClick={handleDeleteClick}
                />
                <JobEditorModal
                    id= {id}
                    title= {title}
                    text= {text}
                    contact = {contact}
                    created_at= {created_at}
                    type= {'update'}
                />
            </div>
        </div>
    )
}

export default JobDetails