import { configureStore } from "@reduxjs/toolkit";
import articlesSlice from './features/articlesSlice'
import userReducer from './features/userSlice'
import pathReducer from './features/pathSlice'
import devicesSlice from "./features/devicesSlice";
import repairFileSlice from "./features/repairFileSlice";
import brandsSlice from "./features/brandsSlice";
import agenciesSlice from "./features/agenciesSlice";
import photosSlice from "./features/photosSlice";
import cookiesSlice from "./features/cookiesSlice";
import deviceCodesSlice from "./features/deviceCodesSlice"
import jobsSlice from "./features/jobsSlice";
import bonusDevicesSlice from "./features/bonusDevicesSlice";
import repairLogFileSlice from "./features/repairLogFileSlice";

export const store = configureStore({
    reducer:{
        articles: articlesSlice,
        jobs: jobsSlice,
        user: userReducer,
        path: pathReducer,
        devices: devicesSlice,
        repairFile: repairFileSlice,
        repairFileLog: repairLogFileSlice,
        brands: brandsSlice,
        agencies: agenciesSlice,
        photos: photosSlice,
        cookies: cookiesSlice,
        deviceCodes: deviceCodesSlice,
        bonusDevices: bonusDevicesSlice,
    }
})