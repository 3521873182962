import React, { useState } from 'react';
import Modal from 'react-modal';
import '../css/infoModal.css';

import { Link } from 'react-router-dom';
import { useEffect } from 'react';

Modal.setAppElement('#root');

const RgpdModal = () => {
    const rgpdSeen = JSON.parse(localStorage.getItem('rgpdSeen'))
    const [modalIsOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if(!rgpdSeen){
            setIsOpen(true)
        }
    }, [rgpdSeen]);

    const closeModal = () => {
        setIsOpen(false);
        localStorage.setItem('rgpdSeen', true)
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            style={customStyles}
        >
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'space-around', alignItems: 'center'}}>
                <button 
                    className="modalButton" 
                    style={{padding: '5px', fontSize: '0.8em'}}
                    onClick={closeModal}>
                        Accepter les cookies
                </button>
                <button 
                    className="modalButton" 
                    style={{padding: '5px', fontSize: '0.8em', background: 'red'}}
                    onClick={closeModal}>
                        Refuser les cookies
                </button>
                <Link className="modalButton" style={{padding: '5px', fontSize: '0.8em', background: '#cfcfd0', color: '#444444', marginTop: '10px'}} to='/rgpd'>
                    Utilisation des Cookies
                </Link>
            </div>
        </Modal>
    )
}

const customStyles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    top: '78%',
    right: '10%',
    left: 'auto',
    bottom: 'auto',
    width:'200px',
    height:'12vh',
    color: '#444444',
    borderRadius: '12px'
  },
  overlay: {
    position: 'fixed',
    zIndex: 50,
    background: 'rgba(115, 115, 115, 0.8)'
  },
  article: {
    fontSize: '1.1em', 
    display: 'flex', 
    justifyContent: 'space-around', 
    flexDirection: 'column',
    marginTop:'2vh', 
    height:'90%'
  }
};

export default RgpdModal