import { useSelector } from "react-redux"
import { useState } from "react"
import { useEffect } from "react"
import MagnifyingGlassIcon from '../../assets/images/magnifying-glass-white.png'
import CrossIcon from '../../assets/images/cross-icon.png'
import UserModal from "../../components/UserModal"

const AdminUsers = () => {
    const { user } = useSelector((store) => store.user)
    const [ users, setUsers] = useState([])
    const [ allUsers, setAllUsers] = useState([])
    const [ lastName, setLastName ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ listFilter, setListFilter ] = useState(true)
    const [ emailListFilter, setEmailListFilter ] = useState(true)

    useEffect(() => {
        async function fetchUsers() {
            await fetch(global.constantes.proxy.base + '/users',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${user.token}`
                }
            })
            .then(async response =>{
                await response.json()
                .then( async json => {
                    setAllUsers(json)
                    if(allUsers){
                        setUsers(allUsers)
                    }
                })
            })
        }
        if(users.length == 0){
            fetchUsers()
        }
        // eslint-disable-next-line
    }, [users])

    const handleFileClicked = (user) => {
        setSelectedUser(user.id)
    }

    const setSelectedUser = (id) => {
        const newList = users.map((item) => { 
            if (item.id === id) {
                const updatedItem = {...item, selected: true}
                return updatedItem;
            }
            else{
                const updatedItem = {...item, selected: false}
                return updatedItem;
            }
        });
        setUsers(newList);
    }

    const filterOnLastname = () => {
        if(lastName != ''){
            setListFilter(!listFilter)
            if(listFilter){
                setUsers(allUsers.filter(item => item.last_name.toLowerCase() == lastName.toLowerCase()))
            }else{
                setUsers(allUsers)
                setLastName('')
            }
        }
    }

    const filterOnEmail = () => {
        if(email != ''){
            setEmailListFilter(!emailListFilter)
            if(emailListFilter){
                setUsers(allUsers.filter(item => item.email.toLowerCase() == email.toLowerCase()))
            }else{
                setUsers(allUsers)
                setEmail('')
            }
        }
    }

    return(
        <>
            <div className="adminUsers">
                <div style={{width: '100%',height: '10%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <input 
                        type="text" 
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Filtrer par nom de famille"
                        style={{width: '185px', height: '40px',marginLeft: '5%', marginTop: '4%'}}
                    />
                    <button
                        style={{
                            width:'42px', 
                            height:'38px', 
                            right:'0',  
                            marginRight:'5px', 
                            cursor:'pointer',
                            backgroundColor:'rgba(15,85,255,0.6)',
                            borderStyle:'none', borderRadius:'6px'
                        }}
                        onClick={(() => filterOnLastname())}
                    >
                    <img src={listFilter ? MagnifyingGlassIcon : CrossIcon} alt="MagnifyingGlassIcon" style={{width:'90%', height:'90%', padding:'2px'}}/>
                    </button>        
                </div>
                <div style={{width: '100%',height: '10%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <input 
                        type="text" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Filtrer par adresse mail"
                        style={{width: '185px', height: '40px',marginLeft: '5%', marginTop: '4%'}}
                    />
                    <button
                        style={{
                            width:'42px', 
                            height:'38px', 
                            right:'0', 
                            marginRight:'5px', 
                            cursor:'pointer',
                            backgroundColor:'rgba(15,85,255,0.6)',
                            borderStyle:'none', borderRadius:'6px'
                        }}
                        onClick={(() => filterOnEmail())}
                    >
                    <img src={emailListFilter ? MagnifyingGlassIcon : CrossIcon} alt="MagnifyingGlassIcon" style={{width:'90%', height:'90%', padding:'2px'}}/>
                    </button>        
                </div>
            </div>
            {users && !listFilter && users.length == 0 &&
                <span style={{display: 'flex', width: '100%', justifyContent: 'center'}}>Pas de résultat pour <b style={{marginLeft: '8px'}}>{lastName}</b></span>
            }
            {users && !emailListFilter && users.length == 0 &&
                <span style={{display: 'flex', width: '100%', justifyContent: 'center'}}>Pas de résultat pour <b style={{marginLeft: '8px'}}>{email}</b></span>
            }
            <div className="adminContainer">
                {users && users.length > 0 && users.map((user, index)=>(
                    <div 
                        key={index} 
                        onClick={()=>{handleFileClicked(user)}}
                        >
                            <UserModal
                                selected={user.selected}
                                id={user.id}
                                email={user.email}
                                firstName={user.first_name} 
                                lastName={user.last_name}
                            />
                    </div>
                    ))
                }
            </div>
        </>
    )
}

export default AdminUsers