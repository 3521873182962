import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getRepairFiles } from "../features/repairFileSlice"
import { useNavigate } from "react-router-dom"
import { getArticles } from "../features/articlesSlice"
import { getRepairFilesLog } from "../features/repairLogFileSlice"

const Admin = () => {

    const { user } = useSelector((store) => store.user)
    const { repairFiles } = useSelector((store) => store.repairFile)
    const { repairFilesLog } = useSelector((store) => store.repairFileLog)
    const { articles } = useSelector((store) => store.articles)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if(!repairFiles){
            dispatch(getRepairFiles(user))
        }
        if(!repairFilesLog){
            dispatch(getRepairFilesLog(user))
        }
        if(!articles){
            dispatch(getArticles(user))
        }
        // eslint-disable-next-line
    }, [])

    const handleFilesClick = () => {
        navigate('/admin-files')
    }

    const handleDatesClick = () => {
        navigate('/admin-dates')
    }

    const handleBlogClick = () => {
        navigate('/blog')
    }

    const handleJobEditorClick = () => {
        navigate('/jobEditor')
    }

    const handleUsersClick = () => {
        navigate('/users')
    }

    const handleRecoveryClick = () => {
        navigate('/admin-recovery')
    }

    return (
        <div className="adminContainer">
            <div style={{display: 'flex', flexDirection: 'row', width: '80%', height: '50%', justifyContent: 'space-around'}}>
                <div 
                    className="admin"
                    onClick={handleFilesClick}
                    >
                    <h2 style={{margin: 0, color: '#414141'}}>Dossiers</h2>
                    {/* {repairFiles &&
                        <div style={{display: 'flex', width:'100%', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                            <p>{repairFiles.length}</p>
                            {repairFiles.length > 1 ? <p style={{textIndent: '10px'}}>dossiers</p> : <p style={{textIndent: '10px'}}> dossier</p>}
                        </div>
                    } */}
                </div>
                <div 
                    className="admin"
                    style={{backgroundColor: '#b2e743'}}
                    onClick={handleDatesClick}
                    >
                    <h2 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#414141'}}>Rdv téléphonique</h2>
                    {/* {repairFiles &&
                        <div style={{display: 'flex', width:'100%', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                            <p>{repairFiles.length}</p>
                            {repairFiles.length > 1 ? <p style={{textIndent: '10px'}}>rendez-vous</p> : <p style={{textIndent: '10px'}}>rendez-vous</p>}
                        </div>
                    } */}
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', width: '80%', height: '50%', justifyContent: 'space-around'}}>
                <div 
                    className="admin"
                    style={{backgroundColor: '#4357e7'}}
                    onClick={handleRecoveryClick}
                    >
                    <h2 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff'}}>Récuperation dossiers</h2>
                </div>
                <div 
                    className="admin"
                    style={{backgroundColor: '#b243e7'}}
                    onClick={handleUsersClick}
                    >
                    <h2 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#414141'}}>Utilisateurs</h2>
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', width: '80%', height: '50%', justifyContent: 'space-around'}}>
                <div 
                    className="admin"
                    style={{backgroundColor: '#e7a843'}}
                    onClick={handleBlogClick}
                    >
                    <h2 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#414141'}}>Blog</h2>
                    {/* {repairFiles &&
                        <div style={{display: 'flex', width:'100%', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                            <p>{repairFiles.length}</p>
                            {repairFiles.length > 1 ? <p style={{textIndent: '10px'}}>rendez-vous</p> : <p style={{textIndent: '10px'}}>rendez-vous</p>}
                        </div>
                    } */}
                </div>   
                <div 
                    className="admin"
                    style={{backgroundColor: '#e7da43'}}
                    onClick={handleJobEditorClick}
                    >
                    <h2 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#414141'}}>Emplois</h2>
                    {/* {repairFiles &&
                        <div style={{display: 'flex', width:'100%', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                            <p>{repairFiles.length}</p>
                            {repairFiles.length > 1 ? <p style={{textIndent: '10px'}}>rendez-vous</p> : <p style={{textIndent: '10px'}}>rendez-vous</p>}
                        </div>
                    } */}
                </div>            
            </div>
            
        </div>
    )
}

export default Admin