import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const JobModal = (props) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  const setCreatedDate = (date) => {
    let dateFormatted = date.split('T')[0]
    dateFormatted = dateFormatted.split('-')
    dateFormatted = `${dateFormatted[2]}/${dateFormatted[1]}/${dateFormatted[0]}`
    return dateFormatted
  }

  const setPreviewText = (text) => {
    let previewText = text.split(' ')
    previewText = previewText.slice(0, 7)
    previewText.push('...')
    previewText = previewText.join(' ')
    return previewText
  }

  return (
    <div className="jobPreview">
      <button 
        style={{width: '350px',height: '190px' , padding: '10px',fontSize:'1.2em', borderRadius:'6px', borderStyle:'none', cursor:'pointer'}} 
        onClick={openModal}
      >
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className="articlePreviewSubject">{props.job.subject}</div>
                <div className="articlePreviewDate">{setCreatedDate(props.job.created_at)}</div>
            </div>
            <div className="articlePreviewTitle">{props.job.title}</div>
             <div className="articlePreviewText">{setPreviewText(props.job.text)}</div>
        {/* </div> */}
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div style={customStyles.article}>
            <div style={{width : '100%', height: '15%', display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '0.9em', fontWeight: 'bold', color: '#202020'}}>
                <div style={{width : '100%', display: 'flex', justifyContent: 'flex-start', fontSize: '1.5em'}}>Poste : {props.job.title}</div>
                <div style={{width : '100%', display: 'flex', justifyContent: 'flex-start', fontWeight: 'normal', fontStyle: 'italic'}}>Offre publiée le : {setCreatedDate(props.job.created_at)}</div>
            </div>
            <div style={{width : '100%', height: '75%', display: 'flex', overflowY: 'scroll', whiteSpace: 'pre-line', justifyContent: 'center'}}>
                {props.job.text}
            </div>
            <div style={{width : '100%', height: '15%', display: 'flex', whiteSpace: 'pre-line', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', color: '#3f2564'}}>
                {props.job.contact}
            </div>
        </div>
      </Modal>
    </div>
  )
}

const customStyles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width:'80vw',
    height:'80%',
    color: '#444444'
  },
  overlay: {
    position: 'fixed',
    zIndex: 50,
    background: 'rgba(115, 115, 115, 0.8)'
  },
  article: {
    fontSize: '1.1em', 
    display: 'flex', 
    justifyContent: 'space-around', 
    flexDirection: 'column',
    marginTop:'2vh', 
    height:'100%',
    overflowY: 'hidden'
  }
};

export default JobModal