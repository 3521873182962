import React, { useState } from 'react';
import Modal from 'react-modal';

import CloseCross from '../assets/images/close_cross.png'
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

Modal.setAppElement('#root');

const ZipCodesModal = (props) => {

  return (
    <div>
      <Modal
        isOpen={props.openZipCodesModal}
        style={customStyles}
      >
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <div style={{width: '100%', display: 'flex', justifyContent: 'center', fontSize: '1.1em', fontWeight:'bold'}}>Incohérence des codes postaux</div>
          <img style={{width:30, marginBottom:'25px', cursor:'pointer', float:'right'}}
                        src={CloseCross}
                        alt="909"
                        onClick={()=>{
                            props.setOpenZipCodesModal(false)
                        }}
                    />
        </div>
        <div style={{fontSize: '1.1em', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop:'2vh'}}>
            <span>Le code postal renseigné précédemment : {props.repairFileCode}</span>
            <span>Ne correspond pas à celui de votre compte : {props.userZipCode}</span>
            <span style={{marginTop: '5%'}}>Merci de : </span>
            <Link 
                to="/account"
                className="contactLink"
                style={{width: '80%', marginTop: '2%'}}
             >Modifier le code postal dans votre compte</Link>
             <span style={{marginTop: '5%'}}>ou bien : </span>
             <Button 
                onClick={()=>{
                    props.handlePrevClick()
                    props.setOpenZipCodesModal(false)
                }}
                // className="contactLink"
                style={{
                    width: '80%', 
                    color: '#fff', 
                    backgroundColor: '#4ab99a',
                    // background: var(--primary),
                    border: 0,
                    padding: '10px',
                    fontFamily: "Poppins",
                    borderRadius: '4px',
                    cursor: 'pointer',
                    fontSize: '1em',
                    borderRadius: '24px',
                    marginTop: '2%'
                }}
             >Corriger l'information précédente</Button>
        </div>
      </Modal>
    </div>
  )
}

const customStyles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width:'80vw'
  },
  overlay: {
    position: 'fixed',
    zIndex: 50,
    background: 'rgba(255, 255, 255, 0.5)'
  },
};

export default ZipCodesModal