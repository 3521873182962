import { useDispatch, useSelector } from "react-redux"
import FileModal from "../../components/FileModal"
import { useState } from "react"
import { useEffect } from "react"
import { setRepairFileSelected } from "../../features/repairFileSlice"
import Calendar from "react-calendar"

const AdminFiles = () => {
    const { user } = useSelector((store) => store.user)
    const { repairFiles } = useSelector((store) => store.repairFile)
    const [ files, setFiles] = useState([])
    const [ year ,setYear ] = useState(new Date().toLocaleString('fr-FR').split(' ')[0].split('/')[2])
    const [ month ,setMonth ] = useState(new Date().toLocaleString('fr-FR').split(' ')[0].split('/')[1])
    const [ holidays, setHolidays ] = useState([])
    const [ phoneDatesDays, setPhoneDatesDays ] = useState([])
    const [ allPhoneDatesDays, setAllPhoneDatesDays ] = useState([])
    const [ agencyId, setAgencyId ] = useState(0)
    const [ blockedDate, setBlockedDate ] = useState()
    const [ blockedTimeSlot, setBlockedTimeSlot ] = useState()
    const [ blockedTimeSlotSaved, setBlockedTimeSlotSaved ] = useState(false)
    const [ selectedDate, setSelectedDate ] = useState(new Date().toLocaleString('fr-FR').split(' ')[0])
    const [ agencyName, setAgencyName ] = useState('toutes agences')
    const dispatch = useDispatch()

    useEffect(() => {
        async function fetchPhoneDates() {
            await fetch(global.constantes.proxy.base + '/remoteDiagPlanning/' + month + '/' + year,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${user.token}`
                }
            })
            .then(async response =>{
                await response.json()
                .then( async json => {
                    json.forEach(element => {
                        setAllPhoneDatesDays( allPhoneDatesDays => [...allPhoneDatesDays, {phone_appointment: element.phone_appointment, agency_id: element.agency_id}])
                    });
                })
            })
        }
        async function fetchHolidays() {
            const url = `https://calendrier.api.gouv.fr/jours-feries/metropole/${year}.json`;
            await fetch( url )
            .then(async response =>{
                await response.json()
                .then( async json => {
                    Object.keys(json).map((key, index) => {
                        let holiday = key
                        holiday = holiday.split('-')
                        let holiDate = `${holiday[2]}/${holiday[1]}/${holiday[0]}`
                        setHolidays( holidays => [...holidays, holiDate])
                    })
                })
            })
        }
        fetchHolidays()
        if(allPhoneDatesDays.length == 0){
            fetchPhoneDates()
        }
        if(phoneDatesDays.length > 0 && agencyId){
            var parts =selectedDate.split('/');
            handleAppointment(new Date(parts[2], parts[1] - 1, parts[0]))
        }
        if(phoneDatesDays.length > 0 && agencyId == 0){
            var parts =selectedDate.split('/');
            handleAppointment(new Date(parts[2], parts[1] - 1, parts[0]))
        }
        if(phoneDatesDays.length == 0 && allPhoneDatesDays.length > 0){
            setPhoneDatesDays(allPhoneDatesDays)
            handleAppointment(new Date())
        }
        // eslint-disable-next-line
    }, [repairFiles, month, year, allPhoneDatesDays, agencyId])

    const handleFileClicked = (file) => {
        dispatch(setRepairFileSelected(file.id))
        setSelectedFile(file.id)
    }

    const setSelectedFile = (id) => {
        const newList = files.map((item) => { 
            if (item.id === id) {
                const updatedItem = {...item, selected: true}
                return updatedItem;
            }
            else{
                const updatedItem = {...item, selected: false}
                return updatedItem;
            }
        });
        setFiles(newList);
    }

    const setPhoneAppointment = (appointment) => {
        const date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        const date1 = new Date(`${month}/${day}/${year}`)
        const rdvDate = appointment.split('/')
        const date2 = new Date(`${rdvDate[1]}/${rdvDate[0]}/${rdvDate[2]}`);
        const isPast = date2 < date1
        if(isPast){
            return ['','']
        }else{
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
            if(diffDays > 3){
                return [appointment, '#72f692']
            }
            if(diffDays <= 3 && diffDays > 0){
                return [appointment, '#f6ab72']
            }
            if(diffDays == 0){
                return [appointment, '#f67272']
            }
        }

    }

    const filterAgencyId = (event) => {
        setAgencyId(Number(event.target.value));
        setAgencyName(event.nativeEvent.target[event.target.value].text)
        setFiles([])
        setPhoneDatesDays([])
        if(Number(event.target.value) != 0){
            setPhoneDatesDays(allPhoneDatesDays.filter(item => Number(item.agency_id) == Number(event.target.value)))
        }else{
            setPhoneDatesDays(allPhoneDatesDays)
        }
    }

    // instead of setDayPlanning -> setList des diag à distance
    const handleAppointment = async (e) => {
        let date = e.toLocaleDateString()
        setSelectedDate(date)
        setFiles([])
        await fetch(global.constantes.proxy.base + '/remoteDiagPlanning',{
            method: 'POST',
            body: JSON.stringify({date: date}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${user.token}`
            }
        })
        .then( async response =>{
            await response.json()
            .then(async json => {
                if(json.length > 0){
                    // console.log(json);
                    json.forEach(async file => {
                        if(agencyId == 0){
                            await fetch(global.constantes.proxy.base + '/phoneDateFile/' + file.id,{
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization':`Bearer ${user.token}`
                                }
                            })
                            .then(async fileResponse => {
                                await fileResponse.json()
                                .then( async fileJson => {
                                    // console.log(fileJson);
                                    setFiles(files => [...files, fileJson[0]])
                                    if(files){
                                        setFiles((files) => {
                                            const filesToSort = [...files];
                                            filesToSort.sort((a, b) => Number(a.time_slot) - Number(b.time_slot));
                                            return filesToSort;
                                        })
                                    }
                                })
                            })
                        }
                        else if(file.agency_id == agencyId){
                            await fetch(global.constantes.proxy.base + '/phoneDateFile/' + file.id,{
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization':`Bearer ${user.token}`
                                }
                            })
                            .then(async fileResponse => {
                                await fileResponse.json()
                                .then( async fileJson => {
                                    // console.log(fileJson);
                                    setFiles(files => [...files, fileJson[0]])
                                    if(files){
                                        setFiles((files) => {
                                            const filesToSort = [...files];
                                            filesToSort.sort((a, b) => Number(a.time_slot) - Number(b.time_slot));
                                            return filesToSort;
                                        })
                                    }
                                })
                            })
                        }
                    })
                }
            })
        })
    }

    const tileDisabled = ({ date }) => {
        let middleDay = date.toLocaleString('fr-FR').split(' ')[0].split('/')[0]
        if(middleDay == 15){
            setYear(date.toLocaleString('fr-FR').split(' ')[0].split('/')[2])
            setMonth(date.toLocaleString('fr-FR').split(' ')[0].split('/')[1])
        }
        let todayDate = new Date();
        todayDate.setDate(todayDate.getDate() - 1);
        if(date < todayDate || 
            date.getDay() == 0 || 
            date.getDay() == 6 || 
            holidays.includes(date.toLocaleString('fr-FR').split(' ')[0])
            ){
            return true
        }
    }

    const handleTimeSlotBlocker = async () => {
        if(blockedDate && blockedTimeSlot){
            let timeSlot = blockedTimeSlot.split('h')
            if(timeSlot[1] == '30'){
                timeSlot[1] = 5
                timeSlot = timeSlot.join('.')
            }else{
                timeSlot[1] = ''
                timeSlot = timeSlot[0]
            }
            timeSlot = Number(timeSlot)
            console.log(timeSlot);
            let repairFile = {
                device: { id: 10},
                deviceCode: 'DIVERS REF',
                category: 'TV',
                selectedOffer: { id: 10},
                issue: { id: 33},
                serialNumber: '09G13SMJ100339R',
                comment: 'reservation créneau par Admin',
                phoneAppointment: blockedDate,
                selectedTimeSlotRef: {hour: timeSlot},
                brand: 'SAMSUNG',
                zipCode: '91770',
                cities: [
                    {
                        id: 0,
                        name: 'Paris'
                    }
                ],
                cityId: 0,
                nearestAgency: { id: 5},
                price: 35,
                ratezone: 'Z1',
                photoFile: []
                    
            }
            // console.log(repairFile);
            await fetch(global.constantes.proxy.base + '/repairfile',{
                method: 'POST',
                body: JSON.stringify(repairFile),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${user.token}`
                }
            })
            .then( async response =>{
                await response.json()
                .then( async json => {
                    if(json.success){
                        // console.log(json);
                        setBlockedTimeSlotSaved(true)
                    }
                })
            })
        }
    }

    return(
        <div className="adminDatesContainer">
            <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                        <label for="">Bloquer un créneau :</label>
                        <label style={{color: 'red', fontSize: '0.7em'}}>Attention à vérifier que le créneau ciblé n'est pas déjà réservé</label>
                        <label style={{color: 'red', fontSize: '0.7em'}}>Attention à entrer date et heure sous le même format que ci-dessous (heure : ex : 9h00, 10h30)</label>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <input 
                                // type="text" 
                                style={{width: '30%'}}
                                value={blockedDate}
                                placeholder="22/12/2024"
                                onChange={(e) => setBlockedDate(e.target.value)}
                            />
                            <input 
                                // type="text" 
                                style={{width: '30%', marginLeft: '5%', marginRight: '5%'}}
                                value={blockedTimeSlot} 
                                placeholder="11h30"
                                onChange={(e) => setBlockedTimeSlot(e.target.value)}
                            />
                            <button 
                                className="modalButton"
                                style={{height: '45px'}}
                                onClick={handleTimeSlotBlocker}
                            >Valider</button>
                        </div>
                    </div>
                    {blockedTimeSlotSaved &&
                        <span style={{color: 'green', fontSize: '0.8em'}}>Le créneau a été réservé</span>
                    }
                    <select 
                        value={agencyId} 
                        onChange={filterAgencyId}
                        className="modalButton"
                        style={{marginLeft: '2%', marginTop: '5%', marginBottom: '2%', backgroundColor: 'rgba(15,85,255,0.6)'}}
                    >
                        <option value="0">Toutes agences</option>
                        <option value="1">Paris</option>
                        <option value="2">Lyon</option>
                        <option value="3">Toulouse</option>
                        <option value="4">Tours</option>
                        <option value="5">Strasbourg</option>
                        <option value="6">Rouen</option>
                    </select> 
                <div style={{width:'90%', height:'50%', marginLeft:'3%', display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <Calendar
                        // faire un handle RDVDate qui setState & dispatche dans Redux
                        onChange={handleAppointment}
                        value={new Date()}
                        // tileDisabled={tileDisabled}
                        dateFormat="d MMM yyyy"
                        tileClassName={({ date, view }) => {
                            if(phoneDatesDays.some(e => e.phone_appointment === date.toLocaleString('fr-FR').split(' ')[0])){
                            return  'adminDatesTile'
                            }
                        }}
                    />
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', marginTop: '3vh', width: '50%', height:'100%', alignItems: 'center'}}>
            <span style={{fontSize:'1em', fontWeight: 'bold', color: '#3f3f3f'}}>Diagnostics du {selectedDate} pour {agencyName}</span>
                <div style={{display: 'flex', flexDirection: 'column',  width: '90%', height:'100%', overflow: 'scroll', alignItems: 'center'}}>
                    {!files &&
                        <span>Chargement...</span>
                    }
                    {files && files.map((file, index)=>(
                        <div 
                            key={index} 
                            onClick={()=>{handleFileClicked(file)}}>
                                <FileModal
                                selected={file.selected}
                                id={file.id}
                                erpFileId={file.erp_file_id}
                                paidAt={file.paid_at}
                                firstName={file.first_name} 
                                lastName={file.last_name}
                                phoneAppointment={setPhoneAppointment(file.phone_appointment)}
                                timeSlot={file.time_slot}
                                context={'planning'}
                                    />
                        </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default AdminFiles