import React, { useState } from "react";
import TrashIcon from '../assets/images/trash_icon_white.png'
import { useEffect } from "react";

const UploadFile = (props) => {
  
  const [ alreadyImport, setAlreadyImported ] = useState(false)

  useEffect(()=>{
    if(props.deleteFile == true){
      handleDelete(props.fileName)
    }
  },[props.deleteFile])

  const handleDelete = async (name) => {
    // delete on server at /public/temp/candidateCV
    await fetch(global.constantes.proxy.base + '/manageFile/' + name, {
        method: 'DELETE',
    }).then(() => {
      //message "CV supprimé"
      console.log('file deleted');
      props.setIsFileDeleted(true)
    })
  }

  const handleAdd = async (event) => {
    setAlreadyImported(false)
    const name = event.target.files[0].name
    if(!props.fileName){
      // save on server in /public/temp/candidateCV
      await fetch(global.constantes.proxy.base + '/uploadFile/' + name,{
        method: 'POST',
        body: event.target.files[0],
        headers: {
            'Content-Type': 'image/jpg'
        }
    }).then(async response => {
        //après upload recupère nom du file depuis serveur et envoi mail
        await response.json()
        .then(json=>{
          console.log(json);
          if(json.success){
            props.setFileName(json.fileName)
          }
        })
      })
    }else{
      setAlreadyImported(true)
    }
  }

  return (
    <div style={{width:'80%', marginTop:'2%'}}>
      <label>Importer votre CV au format .pdf (5 Mo max)</label>
      <div style={{display:'flex', position:'relative'}}>
      <input
        type="file"
        name="myImage"
        onChange={(event) => {handleAdd(event)}}
      />
      {props.fileName &&
        <button
          style={{
            width:'35px', 
            height:'35px', 
            position:'absolute',
            right:'0', 
            marginTop:'10px', 
            marginRight:'5px', 
            cursor:'pointer',
            backgroundColor:'rgba(255,0,0,0.6)',
            borderStyle:'none', borderRadius:'6px',
          }}
          onClick={() => handleDelete(props.fileName)}>
          <img src={TrashIcon} alt="TrashIcon" style={{width:'90%', height:'80%', padding:'2px'}}/>
        </button>
      }
      </div>
      {alreadyImport && 
        <span style={{color:'red', fontSize:'0.9em'}}>Vous avez déjà importer ce CV.</span>
      }
    </div>
  );
};

export default UploadFile;