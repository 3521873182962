import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    infosSeen: false,
    rgpdSeen: false,
}

const cookiesSlice = createSlice({
    name: 'cookies',
    initialState,
    reducers:{
        setInfosSeen: (state, action)=>{
            state.infosSeen = action.payload
        },
        setRgpdSeen: (state, action)=>{
            state.rgpdSeen = action.payload
        },
    }
})

export const { setInfosSeen, setRgpdSeen } = cookiesSlice.actions
export default cookiesSlice.reducer