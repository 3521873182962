import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    repairFilesLog: null,
    isLoading: true,
}

export const getRepairFilesLog = createAsyncThunk(
    'repairFileLog/getRepairFilesLog',
    async (user) => {
        try {
            return await fetch(global.constantes.proxy.base + '/repairfilelog',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${user.token}`
                }
            })
            .then((resp) => resp.json())
            .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }
    }
)

const repairFileSlice = createSlice({
    name: 'repairFileLog',
    initialState,
    extraReducers:(builder)=>{
        builder
            .addCase(getRepairFilesLog.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getRepairFilesLog.fulfilled, (state, action) => {
                state.isLoading = false
                state.repairFilesLog = action.payload
            })
            .addCase(getRepairFilesLog.rejected, (state) => {
                state.isLoading = false
            })
    }
})

export default repairFileSlice.reducer