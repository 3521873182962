import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setRepairFileFromLocalStorage } from '../features/repairFileSlice';
import { setPathFromLocalStorage } from '../features/pathSlice';
import { setPhotosFromLocalStorage } from '../features/photosSlice';
import { Link, useNavigate } from "react-router-dom";
import { setPath } from "../features/pathSlice";
import checkImg from '../assets/images/checkImg.png'
import loadingImg from '../assets/images/loading.gif'

const CheckOutSuccess = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector((store) => store.user)
    const [ sessionId, setSessionId ] = useState()
    const [ sessionStatus, setSessionStatus] = useState()
    const [ waitStatus, setWaitStatus ] = useState(0)
    const [ paidConfirmed, setPaidConfirmed ] = useState(false)

    const repairFile = JSON.parse(localStorage.getItem('repairFile'))
    const pathFromLocalStorage = JSON.parse(localStorage.getItem('path'))
    const photos = JSON.parse(localStorage.getItem('photos'))

    const waitStatutMsgs = [
        "Dossier en cours de traitement...",
        "Envoi mail de confirmation...",
    ]
    
    useEffect(()=>{
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        setSessionId(urlParams.get('session_id'))
        
        if(sessionId && !sessionStatus){
            fetchSessionStatus()
        }

        async function fetchSessionStatus() {
            console.log(sessionId);
            await fetch(global.constantes.proxy.base + '/stripeCheckoutSession/' + sessionId,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${user.token}`
                }
              })
              .then( async response =>{
                await response.json()
                .then(json => {
                    if(json){
                        console.log(json);
                        setSessionStatus(json.status)
                    }
                })
            })
        } 

        if(repairFile){
            dispatch(setRepairFileFromLocalStorage(repairFile))
        }
        if(pathFromLocalStorage){
          dispatch(setPathFromLocalStorage(pathFromLocalStorage))
        }
        if(photos){
          dispatch(setPhotosFromLocalStorage(photos))
        }
        if(sessionStatus == 'complete' && repairFile){
            saveAndTransferToBOAndSendMailConfirmations()
        }
        if(sessionStatus == 'open'){
            navigate("/rendez-vous");
        }
    }, [sessionId, sessionStatus])

    const saveAndTransferToBOAndSendMailConfirmations = async () => {
        const id = localStorage.getItem('repairFileId')
        await fetch(global.constantes.proxy.base + '/updateFilePaidAt',{
            method: 'POST',
            body: JSON.stringify({id: id}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${user.token}`
                 }
        })
        .then( async response => {
            await response.json()
            .then( async json => {
                if(json.success){
                    let infos = {
                        email: user.email, 
                        prenom: user.user.first_name,
                        id: id, 
                        phoneAppointment: repairFile.phoneAppointment,
                        timeSlot: repairFile.selectedTimeSlotRef.hour,
                        phoneNumber: user.user.phone_number,
                        price: repairFile.price
                    }
                    setWaitStatus(1)
                    await fetch(global.constantes.proxy.base + '/confirmationMail',{
                        method: 'POST',
                        body: JSON.stringify(infos),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization':`Bearer ${user.token}`
                        }
                    })
                    .then( () => {
                        setPaidConfirmed(true)
                        resetStorageAndSlice()
                    })
                }
            })
        })
    }

    const resetStorageAndSlice = () => {
        dispatch(setPath({indexStepper:0}))
        localStorage.removeItem("repairFile")
        localStorage.removeItem("path")
        localStorage.removeItem("photos")
        localStorage.removeItem("repairFileId")
    }

    return (
        <>
            {sessionStatus == 'complete' && !paidConfirmed &&
                <div className="checkoutSuccess">
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <img 
                            style={{width: '48px', height: 'auto'}}
                            src={loadingImg}
                            alt="909"
                        />
                        <span>Merci de patienter...</span>
                    </div> 
                    <span>{waitStatutMsgs[waitStatus]}</span>
                </div>
            }
            {sessionStatus == 'complete' &&
                <div className="checkoutSuccess">
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <img 
                            style={{width: '48px', height: 'auto'}}
                            src={checkImg}
                            alt="909"
                        />
                        <span>Votre paiement a été reçu.</span>
                    </div> 
                    <span>Un mail de confirmation vous a été envoyé</span> 
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <span>Vous pouvez suivre l'état de votre dossier depuis</span> 
                        <Link to='/account' style={{paddingLeft: '8px'}}>votre compte</Link>
                    </div>
                </div>
            }
        </>
    )
}

export default CheckOutSuccess