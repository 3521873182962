import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    // at "pending" is empty
    // at "fulfilled", has 'jobs' (check in Redux devTools)
    jobs: [],
    isLoading: true,
}

export const getJobs = createAsyncThunk(
    'jobs/getJobs',
    async () => {
        try {
            return await fetch(global.constantes.proxy.base + '/getJobs')
            .then((resp) => resp.json())
            .catch((err) => console.log(err));
        } catch (error) {
            console.log(error);
        }
    }
)

const jobsSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers:{
        clearJobs:(state)=>{
            state.jobs = []
        },
        createJob: (state, action) => {
            state.jobs = [action.payload, ...state.jobs]
        },
        // id != ID
        removeJob: (state, action) => {
            const itemId = action.payload
            state.jobs = state.jobs.filter((item)=> item.id !== itemId)
        },
    },
    extraReducers:(builder)=>{
        builder
            .addCase(getJobs.pending, (state) => {
                state.isLoading = true
            })
            // fulfilled mean it's successful and return jobs
            // just have to set it in state 
            .addCase(getJobs.fulfilled, (state, action) => {
                state.isLoading = false
                state.jobs = action.payload
            })
            .addCase(getJobs.rejected, (state, action) => {
                state.isLoading = false
            })
    }
})

export const { clearJobs, removeJob, createJob } = jobsSlice.actions
export default jobsSlice.reducer