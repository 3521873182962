import { useSelector } from "react-redux"
import JobDetails from "../components/JobDetails"
import JobEditorModal from "../components/JobEditorModal"

const JobEditor = () => {
    const { jobs } = useSelector((store) => store.jobs)

    return (
        <div className="blog">
            <JobEditorModal
                    type= {'create'}
                />
            <div className="article">
                {jobs && jobs.map((job) => (
                    <JobDetails
                        key={job.id} {...job} 
                    />
                ))}
            </div>
        </div>
    )
}

export default JobEditor