import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react"
import { useState } from "react"
import { useSelector } from "react-redux"
import { store } from '../../store';

const stripePromise = loadStripe('pk_live_51O0Q8TByBwyPgkvvVG8HwTu5nSHbOYVZy42JPmYI54pTzkFLY7xNXVkv1SXNoWXVRNZzuVuIoRZUq7LkbivJUO2800pAh1qN9q');

const RepairFilePayment = () => {
    const { path } = store.getState().path;
    const { photos } = store.getState().photos;
    const { repairFile } = useSelector((store) => store.repairFile)
    const { user } = useSelector((store) => store.user)
    const [ options, setOptions ] = useState('')

    console.log(options);
    console.log(stripePromise);
    const [clientSecret, setClientSecret] = useState('');

    console.log(clientSecret);

    useEffect(() => {
        // Create a Checkout Session as soon as the page loads
        localStorage.setItem('repairFile', JSON.stringify(repairFile))
        if(photos){
            localStorage.setItem('photos', JSON.stringify(photos))
        }
        if(path){
            localStorage.setItem('path', JSON.stringify(path))
        }
        const isRepairFile = JSON.parse(localStorage.getItem('repairFile'))
        const id = localStorage.getItem('repairFileId')
        if(isRepairFile){
            fetch(global.constantes.proxy.base + '/stripeCheckoutSession', {
                method: 'POST',
                body: JSON.stringify({price: repairFile.price, interventionType: repairFile.selectedOffer.intervention_type, id: id}),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${user.token}`
                }
            })
            .then((res) => res.json())
            .then((data) => setClientSecret(data.clientSecret));
        }
    }, []);


    return (
        <div id="checkout" className="checkoutStripe">
            {clientSecret && (
                <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{clientSecret}}
                >
                <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            )}
        </div>
    )
}

export default RepairFilePayment