import { useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import checkImg from '../assets/images/checkImg.png'
import PhoneInput from 'react-phone-number-input/input'
import { store } from '../store';
import { useSelector } from "react-redux";
import AgencyInfos from "../components/AgencyInfos";

const Contact = () => {
    const { agencies } = useSelector((store) => store.agencies)
    const [text, setText] = useState('')
    const [ isSubmitted, setIsSubmitted ] = useState(false)
    const [ captchaValue, setCaptchaValue ] = useState(null)
    const [ firstName, setFirstName ] = useState('')
    const [ lastName, setLastName ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ mailSent, setMailSent ] = useState(false)

    const handleSubmit = async () => {
        setIsSubmitted(true)
        // si fileName et si texte -> mail avec comme attachment le fileName vers emploi@909services.com
        if(firstName && lastName && email && phone && text && captchaValue != null){
            let mailDatas = {
                firstName: firstName, 
                lastName: lastName, 
                email: email, 
                phone: phone, 
                msg: text,
            }
            await fetch(global.constantes.proxy.base + '/contactMail',{
                method: 'POST',
                body: JSON.stringify(mailDatas),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then( async response =>{
                await response.json()
                .then( json => {
                    console.log(json);
                    if(json.success){
                        setMailSent(true)
                    }
                })
            })
        }
    }

    const onChangeCaptcha = (value) => {
        console.log("Captcha value:", value);
        setCaptchaValue(value)
      }

    return (
        <div style={{display: 'flex', flexDirection: 'column', width: '80%', height: '100%', marginLeft: '12%'}}>            
                    <label style={{display: 'flex', fontWeight: 'bold', fontSize: '1.4em', color: '#3f2564', width: '100%', justifyContent: 'center', marginTop: '45px'}}>Contactez nos agences </label>
                    <div className="contactMail">
                        { !mailSent &&
                            <>
                                <label >Votre Prénom: </label>
                                <input 
                                    type="text" 
                                    onInput={(e) => setFirstName(e.target.value)}
                                    style={{width: '90%'}}
                                    value={firstName}/>
                                { isSubmitted && firstName == '' &&
                                    <span className='warning center' style={{marginTop: '1vh', fontSize: '1em'}}>Merci de remplir votre prénom</span>
                                }
                                <label >Votre Nom: </label>
                                <input 
                                    type="text" 
                                    onInput={(e) => setLastName(e.target.value)}
                                    style={{width: '90%'}}
                                    value={lastName}/>
                                { isSubmitted && lastName == '' &&
                                    <span className='warning center' style={{marginTop: '1vh', fontSize: '1em'}}>Merci de remplir votre nom</span>
                                }
                                <label >Votre Email: </label>
                                <input 
                                    type="email" 
                                    onInput={(e) => setEmail(e.target.value)}
                                    style={{width: '90%'}}
                                    value={email}/>
                                { isSubmitted && email == '' &&
                                    <span className='warning center' style={{marginTop: '1vh', fontSize: '1em'}}>Merci de remplir l'adresse email</span>
                                }
                                <label >Votre N° de téléphone: </label>
                                <PhoneInput 
                                    country="FR" 
                                    onChange={setPhone}
                                    style={{width: '90%'}}
                                    value={phone}/>
                                { isSubmitted && phone == '' &&
                                    <span className='warning center' style={{marginTop: '1vh', fontSize: '1em'}}>Merci de remplir le N° de téléphone</span>
                                }
                                <label >Votre Message (pour une demande sur un dossier, merci de rappeler votre N° de dossier): </label>
                                <textarea  
                                    type="textarea"
                                    onInput={(e) => setText(e.target.value)}
                                    value={text}
                                    style={{width: '90%', height: '115px', overflowY: 'scroll'}}
                                />
                                { isSubmitted && text == '' &&
                                    <span className='warning center' style={{marginTop: '1vh', fontSize: '1em'}}>Merci de remplir le message</span>
                                }
                                <div className="captchaSendBtnDiv">
                                    <ReCAPTCHA
                                        sitekey="6Lc1ERcpAAAAABoxEL59-UkdlYnFc8Dny9banEdf"
                                        onChange={onChangeCaptcha}
                                    />
                                    { isSubmitted && captchaValue == null &&
                                        <span className='warning center' style={{marginTop: '1vh', fontSize: '1em'}}>Merci de cocher le CAPTCHA</span>
                                    }
                                    <div style={{display: 'flex', justifyContent: 'flex-end', width: '50%', alignItems: 'center'}}>
                                        <button 
                                            className="modalButton" 
                                            style={{height: '60%'}} 
                                            onClick={()=>{handleSubmit()}}>
                                                {!isSubmitted &&
                                                    <span>Envoyer</span>
                                                }
                                                {isSubmitted &&
                                                    <span>Envoi en cours...</span>
                                                }
                                        </button>
                                    </div>
                                </div>
                            </>
                        } 
                        {   mailSent  &&
                            <div style={{display: 'flex', flexDirection: 'column', width: '80%', height: '60%', marginLeft: '10%', alignItems: 'center', justifyContent: 'center', fontSize: '1.2em'}}>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <img 
                                        style={{width: '48px', height: 'auto'}}
                                        src={checkImg}
                                        alt="909"
                                    />
                                    <span>Votre message a été envoyée.</span>
                                </div> 
                                <span>Nous vous répondrons rapidement !</span> 
                            </div>
                        }
                        <div style={{width: '100%', height: '50%', marginTop: '25px'}}>
                            {agencies && agencies.map((agency, index)=>{
                                return <AgencyInfos 
                                    key={index}
                                    agency={agency}
                                />
                            })}
                        </div>
                    </div>
        </div>                                                                                                             
    )
}

export default Contact