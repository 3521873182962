import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    isLoading: true,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers:{
        login: (state, action)=>{
            state.user = action.payload
        },
        logout: (state) => {
            state.user = null
        },
        getUser: (state) => {
            const user = JSON.parse(localStorage.getItem('user'))
            if(user){
                state.user = user
            }
        },
        // setUser: (state, action)=>{
        //     const type = Object.keys(action.payload)[0]
        //     const data = Object.values(action.payload)[0]
        //     if(type === 'zipcode'){
        //         state.user.user = {...state.user.user, zipcode: data}
        //     }
        // }
    }
})

export const { login, logout, getUser, setUser } = userSlice.actions
export default userSlice.reducer