import React, { useState } from 'react';
import Modal from 'react-modal';

import CloseCross from '../assets/images/close_cross.png'
import { useSelector } from 'react-redux';
import { TbPhoneCall } from "react-icons/tb";
import ReactSimpleImageViewer from 'react-simple-image-viewer';
import { useCallback } from 'react';
import CreditCardIcon from '../assets/images/credit-card-icon.png'
import CreditCardUnpaidIcon from '../assets/images/credit-card-icon-unpaid.png'
import ServerTransferIcon from '../assets/images/server-transfer.png'
import ServerTransferUntransferedIcon from '../assets/images/server-transfer-untransfered.png'


Modal.setAppElement('#root');

const FileModal = (props) => {

  const { user } = useSelector((store) => store.user)
  const [modalIsOpen, setIsOpen] = useState(false);
  const [ intervention, setIntervention ] = useState()
  const [ images, setImages ] = useState([])
  const [ imageDetails, setImageDetails ] = useState([])
  const [ currentImage, setCurrentImage ] = useState(0);
  const [ isViewerOpen, setIsViewerOpen ] = useState(false);
  const [ erpFileId, setErpFileId] = useState(false);
  const fileStatusInfos = [
    {id: 0, info: 'En attente de prise en charge'},
    {id: 110, info: 'En attente d\'appel de diagnostic'},
    {id: 115, info: 'En attente de prise de rendez-vous'},
    {id: 161, info: 'Rendez-vous confirmé'}
  ]

  const openModal = async () => {
    await fetch(global.constantes.proxy.base + '/repairfile/' + props.id,{
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${user.token}`
      }
    })
    .then( async response =>{
      await response.json()
      .then(json => {
          if(json){
            setIntervention(json)
            if(images.length == 0 && json.photo_files){
              getPhotoFiles(json.photo_files, json.id)
            }
            setIsOpen(true);
          }
        })
    })
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  const setPhoneNumber = (phonenumber) => {
    let phoneNumber = []
    // let numbers = phonenumber.substring(3, phonenumber.length).split('')
    let numbers = phonenumber.split('')
    // numbers.unshift('0')
    numbers.map((number,index) => {
      if(index % 2 != 0){
        phoneNumber.push(numbers[index-1] + number)
      }
    })
    return phoneNumber.join(' ')
  }

  const setTimeSlot = (timeSlot) => {
    console.log(timeSlot);
    if(timeSlot == 'morning'){
      return 'matin'
    }else{
      return 'après-midi'
    }
  }

  const setDate = (date) => {
      let hourFR = new Date(date.toLocaleString( 'fr-FR', { timeZone: 'Europe/Paris' })).toString().split(' ')[4]
      let dateFormatted = date.split('T')[0]
      dateFormatted = dateFormatted.split('-')
      dateFormatted = `${dateFormatted[2]}/${dateFormatted[1]}/${dateFormatted[0]} à ${hourFR}`
      return dateFormatted
  }

  const setErpDate = (date) => {
      let dateFormatted = date.split('_')[1]
      let year = dateFormatted.substring(0,4)
      let month = dateFormatted.substring(4,6)
      let day = dateFormatted.substring(6,8)
      let hour = dateFormatted.substring(8,10)
      let min = dateFormatted.substring(10,12)
      let sec = dateFormatted.substring(12,14)
      return `${day}/${month}/${year} à ${hour}:${min}:${sec}`
  }

  const getPhotoFiles = async (photoFiles, id) => {
    let files = photoFiles.split(',')
    files.map( async (photoFile) => {
        const response = await fetch(global.constantes.proxy.base + '/getImg/',{
            method: 'POST',
            body: JSON.stringify({photoFile: photoFile, id: id}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${user.token}`
            }
        })
        const blob = await response.blob()
        if(blob){
          let photo = URL.createObjectURL(blob)
          let name = photoFile.split('_')
          name = name.slice(1).join('_').toString()
          let image = { photo, name }
          setImages(images => [...images, image])
          setImageDetails(imageDetails => [...imageDetails, photo])
        }
    })
  }

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleGetFileStatus = async () => {
    await fetch(global.constantes.proxy.base + '/repairFileStatusUpdater', {
        method: 'POST',
        body: JSON.stringify({erpFileId: props.erpFileId}),
        headers: {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${user.token}`
        }
    })
    .then((res) => res.json())
    .then((data) => 
      console.log(data)
      // setClientSecret(data.clientSecret)
    );
  }

  const displayTimeSlot = timeSlot => {
    // is float    
    if(!Number.isInteger(Number(timeSlot))) {
      return {start: timeSlot.toString().split('.')[0] + ' h 30', end: (Number(timeSlot) + 0.5) + ' h'}
    }else{
      return {start: timeSlot + ' h', end: ((Number(timeSlot) + 0.5)).toString().split('.')[0] + ' h 30'}
      
    }
}

const handleTransfer = async () => {
  await fetch(global.constantes.proxy.base + '/transferFile',{
      method: 'POST',
      body: JSON.stringify(intervention),
      headers: {
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${user.token}`
      }
  })
  .then( async response => {
    await response.json()
    .then( async json => {
      console.log(json);
      const result = JSON.parse(json.resultToJson)
      setErpFileId(result.message.body.createDossierResponse.dossier.reference)
      await fetch(global.constantes.proxy.base + '/updateFileErpFileId',{
          method: 'POST',
          body: JSON.stringify({id: props.id, erp_file_id: result.message.body.createDossierResponse.dossier.reference}),
          headers: {
              'Content-Type': 'application/json',
              'Authorization':`Bearer ${user.token}`
          }
      })
    })
  })
}

  return (
    <div style={
      !props.userModal ? 
      {height:'10vh', marginLeft:'5vw', marginTop:'3vh'} :
      {height:'15vh', marginTop:'3vh'}
      }>
      <button 
        style={!props.userModal ? 
          {...customStyles.button, 
            ...props.selected ? 
              {backgroundColor: '#b5f3ca'} : 
              {backgroundColor: 'rgba(255,255,255,0.7)'},
            ...props.context == 'planning' ? 
              {width: '40vw'} : 
              {width: '60vw'}
          } :
          {...customStyles.userButton, 
            ...props.selected ? 
            {backgroundColor: '#b5f3ca'} : 
            {backgroundColor: '#d2bdf0'}}
        }
        id='repairButton' 
        onClick= {()=>{openModal()}}>
              {props.userModal &&
                <div>
                  <span>N° Dossier : </span>
                  <span>{props.id}</span>
                </div>
              }
              <div style={
                !props.userModal ? 
                {width: '60%', display: 'flex', flexDirection: 'row', alignItems: 'center'} :
                {}
                }>
                {props.userModal &&
                  <span>Ref 909 : </span>
                }
                {!props.userModal &&
                  <span style={{padding: '4px', backgroundColor: '#3f2564', color: '#7cd3ba', borderRadius: '4px'}}>{props.id}</span>
                }
                {!props.userModal && 
                  <div style={{width: '80%',marginLeft: '5%', display: 'flex', justifyContent: 'space-around'}}>
                    <div style={{display: 'flex', flexDirection: 'column', width: '80%', height: '100%', alignItems: 'center'}}>
                      <img style={{width: '45px', height: '45px'}} src={props.paidAt ? CreditCardIcon : CreditCardUnpaidIcon}/>
                      {props.CBName &&
                        <span style={{fontSize: '0.6em'}}>CB : {props.CBName}</span>
                      }
                      {props.CBEmail &&
                        <span style={{fontSize: '0.6em'}}>CB email : {props.CBEmail}</span>
                      }
                    </div>
                  {/* <img style={{width: '45px', height: '45px'}} src={props.paidAt ? CreditCardIcon : CreditCardUnpaidIcon}/> */}
                  <img style={{width: '45px', height: '45px'}} src={props.erpFileId ? ServerTransferIcon : ServerTransferUntransferedIcon}/>
                  </div>
                }
                {/* <span>{props.erpFileId}</span> */}
              </div>
              <div style={{display: 'flex', flexDirection: 'column', width: '30%', height: '80%', alignItems: 'center', justifyContent: 'center'}}>
                {!props.userModal &&
                  <div>
                    {props.firstName} {props.lastName}
                  </div>
                }
                {props.phoneAppointment && props.context != 'planning' &&
                  <div style={
                    !props.userModal ? 
                    {...customStyles.buttonDateRDV,
                    backgroundColor: props.phoneAppointment[1],
                    color:'#3b3b3b'
                    } :
                    {...customStyles.userButtonDateRDV,
                      backgroundColor: props.phoneAppointment[1],
                      color:'#3b3b3b'
                      } 
                  }>
                    {props.phoneAppointment[0] != '' &&
                      <TbPhoneCall style={{marginRight:'10px', fontSize:'1.2em'}}/>
                    }
                    <span style={{fontSize:'0.8em'}}>{props.phoneAppointment[0]}</span>
                  </div>
                }
                {props.phoneAppointment && props.context == 'planning' &&
                  <div style={
                    {...customStyles.buttonDateRDV,
                    backgroundColor: '#484581',
                    color:'#f6c868',
                    marginTop: '5px',
                    width: '100px'
                    } 
                  }>
                    <span style={{fontSize:'1em', fontFamily: 'Octosquares'}}>{displayTimeSlot(props.timeSlot).start}</span>
                  </div>
                }
              </div>
              
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className='fileModal'>
          <div style={{fontSize: '1.4em', fontWeight:'bold', position:'absolute'}}>N° dossier : {props.id}</div>
          <img 
            style={{width:30, marginBottom:'25px', cursor:'pointer', right:'5px', position:'absolute'}}
            src={CloseCross}
            alt="909"
            onClick={closeModal}/>
          {intervention && 
            <div style={{marginTop:'12vh', width:'90%', height: '90%', maxHeight:'80%', overflowX:'hidden'}}>
              
              <div style={{display:'flex', flexDirection:'row', justifyContent:'center', marginTop:'2vh', marginBottom:'2vh', fontSize: '1.1em', backgroundColor: '#9ceed7', width: '80%', marginLeft: '10%'}}>
                <span style={{fontWeight:'bold'}}>Etat Dossier : </span>
                {fileStatusInfos.map((info, index) => {
                  if(info.id == intervention.file_status){
                    return(
                      <span key={index} style={{marginLeft:'15px'}}>{info.info}</span>
                    )
                  }
                })              
                }
              </div>
              {!props.userModal &&
                <>
                  <div style={{display:'flex', flexDirection:'column', justifyContent:'space-around', marginTop:'2vh', marginBottom:'2vh', fontSize: '0.9em', backgroundColor: '#c1c6c7', padding: '5px', borderRadius: '4px', width: '95%'}}>
                    <span>Créé le {setDate(intervention.created_at)}</span>
                    <span>{intervention.paid_at ? `Payé le ${setDate(intervention.paid_at)} - CB : ${props.CBName} - CB email : ${props.CBEmail}` : `Non payé`}</span>
                    <div style={{display:'flex', flexDirection:'row', alignItems: 'center', width: '100%'}}>
                      {!erpFileId &&
                        <span>{intervention.erp_file_id ? `Transféré vers BO le ${setErpDate(intervention.erp_file_id)}` : `Non transféré vers BO`}</span>
                      }
                      {!erpFileId && !intervention.erp_file_id &&
                        <button 
                          onClick={()=>{handleTransfer()}}
                          className='modalButton'
                          style={{marginLeft: '50px', padding: '6px'}}
                        >Transférer vers BO</button>
                      }
                      {erpFileId &&
                          <span style={{fontSize: '0.8em', color: 'green', marginLeft: '25px'}}> Dossier transféré vers BO sous la référence : <b style={{fontSize: '1.2em'}}>{erpFileId}</b></span>
                      }
                    </div>
                  </div>
                  <div style={{display:'flex', flexDirection:'row', justifyContent:'center', marginTop:'2vh', marginBottom:'2vh', fontSize: '1.1em'}}>
                    <span style={{fontWeight:'bold'}}>Client : </span>
                    <span style={{marginLeft:'5px'}}>{intervention.first_name} {intervention.last_name}</span>
                  </div>
                  <div style={{display:'flex', flexDirection:'row', left:'2vw'}}>
                    <span style={{fontWeight:'bold'}}>Adresse : </span>
                    <span style={{marginLeft:'5px'}}>{intervention.adress} {intervention.zipcode} {intervention.city_name} </span>
                  </div>
                  <div style={{display:'flex', flexDirection:'row', left:'2vw'}}>
                    <span style={{fontWeight:'bold'}}>Email : </span>
                    <span style={{marginLeft:'5px'}}>{intervention.email}</span>
                  </div>
                  <div style={{display:'flex', flexDirection:'row', left:'2vw'}}>
                    <span style={{fontWeight:'bold'}}>Téléphone : </span>
                    <span style={{marginLeft:'5px'}}>{setPhoneNumber(intervention.phone_number)}</span>
                  </div>
                </>
              }
              <div style={{display:'flex', flexDirection:'row', justifyContent:'center', marginTop:'5vh', marginBottom:'2vh', fontSize: '1.1em'}}>
                <span style={{fontWeight:'bold'}}>Appareil</span>
              </div>
              <div style={{display:'flex', flexDirection:'row', left:'2vw'}}>
                <span style={{fontWeight:'bold'}}>Type : </span>
                <span style={{marginLeft:'5px'}}>{intervention.name}</span>
              </div>
              <div style={{display:'flex', flexDirection:'row', left:'2vw'}}>
                <span style={{fontWeight:'bold'}}>Marque : </span>
                <span style={{marginLeft:'5px'}}>{intervention.brand}</span>
              </div>
              {intervention.serial_number &&
                <div style={{display:'flex', flexDirection:'row', left:'2vw'}}>
                  <span style={{fontWeight:'bold'}}>N° de série : </span>
                  <span style={{marginLeft:'5px'}}>{intervention.serial_number}</span>
                </div>
              }
              {intervention.comment &&
                <div style={{display:'flex', flexDirection:'row', left:'2vw'}}>
                  <span style={{fontWeight:'bold'}}>Commentaire : </span>
                  <span style={{marginLeft:'5px'}}>{intervention.comment}</span>
                </div>
              }
              <div style={{display:'flex', flexDirection:'row', left:'2vw'}}>
                <span style={{fontWeight:'bold'}}>Panne : </span>
                <span style={{marginLeft:'5px'}}>{intervention.issue}</span>
              </div>
              <div style={{display:'flex', flexDirection:'row', justifyContent:'center', marginTop:'5vh', marginBottom:'2vh', fontSize: '1.1em'}}>
                <span style={{fontWeight:'bold'}}>Intervention</span>
              </div>
              <div style={{display:'flex', flexDirection:'row', left:'2vw'}}>
                <span style={{fontWeight:'bold'}}>Agence : </span>
                <span style={{marginLeft:'5px'}}>{intervention.agency}</span>
              </div>
              <div style={{display:'flex', flexDirection:'row', left:'2vw'}}>
                <span style={{fontWeight:'bold'}}>Type : </span>
                <span style={{marginLeft:'5px'}}>{intervention.intervention_type == 'remote' ? 'Diagnostic à distance' : intervention.intervention_type}</span>
              </div>
              {props.phoneAppointment && props.phoneAppointment != 'noRdv' &&
                <div style={{display:'flex', flexDirection:'row', left:'2vw'}}>
                  <span style={{fontWeight:'bold'}}>Date rdv téléphonique : </span>
                  <span style={{marginLeft:'5px'}}>{props.phoneAppointment[0]}</span>
                </div>
              }
              {props.phoneAppointment && props.phoneAppointment != 'noRdv' &&
                <div style={{display:'flex', flexDirection:'row', left:'2vw'}}>
                  <span style={{fontWeight:'bold'}}>Plage horaire rdv téléphonique : </span>
                  <span style={{marginLeft:'5px'}}>
                  <span>{displayTimeSlot(props.timeSlot).start} - {displayTimeSlot(props.timeSlot).end}</span> 
                  </span>
                </div>
              }
              {images.length > 0 &&
                <div style={{display:'flex', flexDirection:'row', left:'2vw', justifyContent:'space-around', overflowX:'scroll'}}>
                  {images && images.map((item, index)=>(
                          <div key={index} style={{display:'flex', position:'relative', margin:'10px'}}>
                              <img
                              style={{borderRadius:'6px', height:'90%', cursor:'pointer'}}
                                alt="not found"
                                width={"250px"}
                                src={images[index].photo}
                                onClick={ () => openImageViewer(index) }
                              />
                              <span style={{ 
                                  position:'absolute',
                                  bottom:'0', fontSize:'0.7em',height:'10%' }}>{item.name}</span>
                          </div>
                      )
                  )}
                </div>
              }
            </div>
          }
        </div>
        <div style={{fontSize: '1.1em', display: 'flex', justifyContent: 'space-around', marginTop:'2vh'}}>{props.advice}</div>
      </Modal>
              {isViewerOpen && (
                <ReactSimpleImageViewer
                  src={ imageDetails }
                  currentIndex={ currentImage }
                  disableScroll={ false }
                  closeOnClickOutside={ true }
                  onClose={ closeImageViewer }
                  backgroundStyle={{position:'fixed', zIndex:'60', backgroundColor:'rgba(125,125,125,0.7)'}}
                />
              )}
    </div>
  )
}

const customStyles = {
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position:'relative',
        fontSize:'1.2em', 
        borderRadius:'6px', 
        borderStyle:'none', 
        backgroundColor:'rgba(255, 255, 255, 0.5)',
        // width:'60vw',
        height:'100%', 
        cursor:'pointer'
    },
    userButton: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position:'relative',
        fontSize:'1.1em', 
        borderRadius:'6px', 
        borderStyle:'none', 
        width:'100%',
        height:'100%', 
        cursor:'pointer'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width:'70vw',
        height:'80vh'
    },
    overlay: {
        position: 'fixed',
        zIndex: 50,
        background: 'rgba(150, 150, 150, 0.5)'
    },
    buttonDateRDV: {
      right:'10px', 
      // position:'absolute', 
      display:'flex', 
      justifyContent:'center', 
      alignItems:'center',
      padding: '5px',
      borderRadius: '4px'
    },
    userButtonDateRDV: {
      right:'10px', 
      display:'flex', 
      justifyContent:'center', 
      alignItems:'center',
      padding: '5px',
      borderRadius: '4px',
      marginTop: '25px'
    }
};

export default FileModal