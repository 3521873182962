import { useSelector } from 'react-redux';

import AspirateurIcon from '../assets/images/aspi_icon.png'
import FrigoIcon from '../assets/images/frigo_icon.png'
import CaveAVinIcon from '../assets/images/cave_a_vin_icon.png'
import CuisiniereIcon from '../assets/images/cuisiniere_icon.png'
import FourIcon from '../assets/images/four_icon.png'
import HotteIcon from '../assets/images/hotte_icon.png'
import LaveLingeIcon from '../assets/images/lave_linge_icon.png'
import LaveVaisselleIcon from '../assets/images/lave_vaisselle_icon.png'
import SecheLingeIcon from '../assets/images/seche_linge_icon.png'
import TableCuissonIcon from '../assets/images/table_cuisson_icon.png'
import HomeCinemaIcon from '../assets/images/home_cinema_icon.png'
import LecteurDVDIcon from '../assets/images/lecteur_dvd_icon.png'
import TelevisionIcon from '../assets/images/television_icon.png'
import { useEffect, useState } from 'react';

const Bonus = () => {
    const { bonusDevices } = useSelector((store) => store.bonusDevices)
    const [ devices, setDevices ] = useState([])
    const icons = [
        null,
        AspirateurIcon,
        null,
        CaveAVinIcon,
        FourIcon,
        HotteIcon,
        LaveLingeIcon,
        LaveVaisselleIcon,
        null,
        TableCuissonIcon,
        FrigoIcon,
        SecheLingeIcon,
        TelevisionIcon,
        LecteurDVDIcon,
        HomeCinemaIcon,
        CuisiniereIcon
    ]

    useEffect(()=>{
        let devices = bonusDevices.reduce( (acc, item, index) => {
            if(acc[item.id] && !acc[item.id][1]){
                if(!acc[item.id][1]){
                    acc[item.id][1].push(item)
                }
            } else {
                acc[item.id] = [item.id, [item]];
            }
            return acc;
          }, [])
        let sortDevices
        if(devices){
            sortDevices = devices.sort((a, b) => Number(a[1][0].repair_bonus) - Number(b[1][0].repair_bonus));
        }
        if(sortDevices)
        setDevices(sortDevices)
    },[])

    return (
        <div style={{overflowY: 'scroll', height: '80%'}}>
            <div style={{width: '90%', marginLeft: '8%', display: 'flex', flexDirection: 'column'}}>
                <h2>BONUS REPARATION</h2>
                <div className='iconsContainer'>
                    {devices && devices.map((device, index) => {
                        if(device[1][0].repair_bonus != null){
                            return <span 
                                        key={index}
                                        style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '25px', marginRight: '10px', height: '264px'}}
                                        >
                                        <img 
                                            style={{width: '96px'}}
                                            src={icons[device[1][0].id]}/>
                                        <p style={{flexGrow: 1}}>{device[1][0].name}</p>
                                        <p style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '48px', height: '48px', borderRadius: '24px', backgroundColor: '#b8f8ff', color: '#002750'}}><b>{device[1][0].repair_bonus} €</b></p>
                                    </span>
                        }
                    })}
                </div>
                <div>
                    <h2>Quelles conditions pour toucher le bonus réparation électroménager en 2023 ?</h2>
                    <span>Pour bénéficier du bonus réparation électroménager mis en place par l’État, il faut que l’appareil concerné soit éligible. <b>L’aide financière est accordée dans le cadre de ce dispositif si :</b></span>
                    <ul>
                        <li><b>l’appareil électrique ou électronique n’est plus couvert par les garanties légales des fabricants ou commerciales</b> – y compris les extensions de garantie -, ni par une assurance (les produits encore sous garantie ne sont pas concernés). Il doit donc être utilisé depuis deux ans (c’est la durée de la garantie légale de conformité)</li>
                        <li><b>l’appareil est détenu par un ménage (et non par un professionnel) et a été acheté en France</b></li>
                        <li><b>l’appareil dispose d’une plaque signalétique ou d’un numéro d’identification (ou IMEI)</b></li>
                        <li><b>l’appareil respecte la réglementation (il doit être conforme aux normes)</b></li>
                    </ul>
                    <span style={{marginTop: '25px', fontSize: '0.8em'}}>Ne sont donc pas éligibles au bonus réparation les appareils :</span>
                    <ul style={{fontSize: '0.8em'}}>
                        <li>toujours sous garanties légales ou commerciales</li>
                        <li>couverts par une assurance</li>
                        <li>dénués d’une plaque signalétique ou d’un numéro d’identification</li>
                        <li>ne respectant pas la réglementation (contrefaçon, recel, non-conformité aux normes…)</li>
                        <li>présentant des dommages d’ordre non fonctionnels (des rayures ou un bris d’écran, par exemple)</li>
                        <li>présentant des dommages relevant des garanties légales des vices cachés</li>
                        <li>affichant un problème résultant d’un usage non-conforme aux prescriptions du constructeur</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Bonus