import { useRef } from "react"
import homeInter from '../assets/images/reparation-a-domicile.svg'
import homeRemote from '../assets/images/diagnostic-a-distance.svg'
import homeWorkshop from '../assets/images/reparation-en-atelier.svg'
import { Link } from "react-router-dom"

const Services = () => {
    const parallax = useRef()

    return (
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height:'100%', alignItems: 'center', backgroundColor: '#3f2564'}}>
            <div className="page4TitleContainer">
                <span style={{color: '#fff'}}>Des offres </span><span style={{textIndent: '8px', color: '#7dcb97'}}> pour tous</span>
            </div>
            <div className="page4InfosContainer">
                <div className="infoColumnWhiteBG">
                    <div className="infosColumnWhiteBGTitle">
                        <img style={{height: '75px'}} src={homeInter} alt="homeSavoir"/>
                        <div className="infosColumnWhiteBGTitletext">
                            <span style={{fontWeight: 'bold'}}>Intervention à domicile</span>
                        </div>
                    </div>
                    <span style={{fontSize: '0.7em'}}>
                        Par appareil & hors pièces détachées
                    </span>
                    <ul style={{fontSize: '0.6em', marginLeft: '0px', paddingLeft: '20px'}}>
                        <li>Déplacement et intervention à domicile* d’un expert 909</li>
                        <li>Prestation garantie 3 mois</li>
                    </ul>
                    <span style={{fontSize: '0.5em', marginTop: '5px'}}>*Dans la limite des zones d’intervention proposées par 909 pour des prestations ateliers ou à domicile. Pour plus de détails, cliquez 
                        <Link to='/agencies'
                        style={{paddingLeft: '10px'}}
                        >ici</Link>
                    </span>
                </div>
                <div className="infoColumnWhiteBG">
                <div className="infosColumnWhiteBGTitle">
                    <img style={{height: '75px'}} src={homeRemote} alt="homeConfiance"/>
                        <div className="infosColumnWhiteBGTitletext">
                            <span style={{fontWeight: 'bold'}}>Expertise à distance</span>
                            <span style={{fontWeight: 'bold',  color: '#7dcb97'}}>35 €</span>
                        </div>
                    </div>
                    <span style={{fontSize: '0.7em'}}>
                        Par appareil & hors pièces détachées
                    </span>
                    <ul style={{fontSize: '0.6em', marginLeft: '0px', paddingLeft: '20px'}}>
                        <li>Appel avec un expert 909</li>
                        <li>Si possible, on vous solutionne immédiatement !</li>
                        <li>Sinon, 909 vous établi un devis pour une réparation*</li>
                    </ul>
                    <span style={{fontSize: '0.5em', marginTop: '5px'}}>*Dans la limite des zones d’intervention proposées par 909 pour des prestations ateliers ou à domicile. Pour plus de détails, cliquez 
                        <Link to='/agencies'
                        style={{paddingLeft: '10px'}}
                        >ici</Link>
                    </span>
                </div>
                <div className="infoColumnWhiteBG">
                    <div className="infosColumnWhiteBGTitle">
                        <img style={{height: '75px'}} src={homeWorkshop} alt="homeSimplicity"/>
                        <div className="infosColumnWhiteBGTitletext">
                            <span style={{fontWeight: 'bold'}}>Réparation en atelier</span>
                            <span style={{fontWeight: 'bold', color: '#7dcb97'}}>sur devis</span>
                        </div>
                    </div>
                    <span style={{fontSize: '0.7em'}}>
                        Par appareil & hors pièces détachées
                    </span>
                    <ul style={{fontSize: '0.6em', marginLeft: '0px', paddingLeft: '20px'}}>
                        <li>Diagnostic et réparation en atelier</li>
                        <li>Dépôt par vos soins dans nos ateliers</li>
                        <li>Réparation garantie 3 mois</li>
                    </ul>
                    <span style={{fontSize: '0.5em', marginTop: '5px'}}>*Dans la limite des zones d’intervention proposées par 909 pour des prestations ateliers ou à domicile. Pour plus de détails, cliquez 
                        <Link to='/agencies'
                        style={{paddingLeft: '10px'}}
                        >ici</Link>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Services