import React, { useState } from 'react';
import Modal from 'react-modal';
import '../css/infoModal.css';

import CloseCross from '../assets/images/close_cross.png'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setInfosSeen } from '../features/cookiesSlice';

Modal.setAppElement('#root');

const InfoModal = () => {
    const dispatch = useDispatch()
    const { infosSeen } = useSelector((store) => store.cookies)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [counter, setCounter] = useState(5);

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        if(counter == 0){
            if(!infosSeen){
                setIsOpen(true)
            }
        }
    }, [counter, infosSeen]);

    const closeModal = () => {
        setIsOpen(false);
        dispatch(setInfosSeen(true))
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            // className="infoModalContainer"
        >
            
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <img style={{width:30, marginBottom:'25px', cursor:'pointer', float:'right'}}
                            src={CloseCross}
                            alt="909"
                            onClick={closeModal}
                        />
            </div>
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'space-around', alignItems: 'center'}}>
                <span className="infoModalText" style={{fontFamily:'Dancing Script'}}>
                    Saviez-vous que 909 s’engage à vous faire bénéficier du Bonus réparation !
                </span>
                <span className="infoModalSubtext">N’hésitez plus, réparez !</span>
                <img className="qualiReparLogo" src={require('../../src/assets/images/QualiRepar-logo.jpg')} />
                <Link className="repairLink" style={{width: '120px'}} to='/bonus'>
                    En savoir plus
                </Link>
            </div>
        </Modal>
    )
}

const customStyles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width:'50vw',
    height:'40vh',
    color: '#444444',
    borderRadius: '12px'
  },
  overlay: {
    position: 'fixed',
    zIndex: 50,
    background: 'rgba(115, 115, 115, 0.8)'
  },
  article: {
    fontSize: '1.1em', 
    display: 'flex', 
    justifyContent: 'space-around', 
    flexDirection: 'column',
    marginTop:'2vh', 
    height:'90%'
  }
};

export default InfoModal